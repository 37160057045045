import React from "react";
import { connect } from "react-redux";
import { List, ListItem } from "material-ui/List";
import { AppBar, Dialog, FlatButton, Drawer, TextField } from "material-ui";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import FlowchartForm from "./FlowchartForm";
import FolderForm from "./FolderForm";
import RemoteSubmitButton from "../RemoteSubmitButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import classnames from "classnames";
import { FlowchartTree } from "../Flowchart";
import {
  logout,
  dbSelectors,
  menuSelectors,
  flowchartActions,
  flowchartSelectors,
  menuActions,
} from "../reducers";
import HeaderBar from "../Header";
import { FindFlowchartTooltip } from "../utils/Tooltip";
import "../App.css";
import logo from "../logo_munvo_picto-h_inverse.svg";
import { munvoBlue } from "../utils/globalStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { theme } from "./styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import FlowchartList from "./FlowchartList";
import { withStyles } from "@material-ui/core/styles";
import { toggleExpandedForAll } from "react-sortable-tree";
import { Link } from "react-router-dom";
import { invalidateFlowchartKeywords } from "../reducers/Keywords";
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFlowchart: this.props.currentFlowchart,
      nextFlowchart: null, // state used to track which flowchart we click on if we move from on flowchart to another when the flowchart is unsaved. Used to create a dialog box to confirm navigation and go there if you want to.
      flowchartFormOpen: false,
      folderFormOpen: false,
      searchString: "",
      menuEdit: this.props.menuEdit,
      treeData: this.props.treeData,
      openUnsaved: false,
      flowchartJSONSubmitError: false, //used while importing flowcharts
      flowchartJSONWebhooks: [],
    };
    this.renderCallBack = this.renderCallBack.bind(this);
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
    this.handleTreeDataChange = this.handleTreeDataChange.bind(this);
    this.handleMenuEdit = this.handleMenuEdit.bind(this);
    this.handleFlowchartSubmit = this.handleFlowchartSubmit.bind(this);
    this.handleFlowchartClick = this.handleFlowchartClick.bind(this);
    this.handleSaveMenuTree = this.handleSaveMenuTree.bind(this);
    this.handleFlowchartDialogOpen = this.handleFlowchartDialogOpen.bind(this);
    this.handleFlowchartDialogClose = this.handleFlowchartDialogClose.bind(
      this
    );
    this.handleFolderSubmit = this.handleFolderSubmit.bind(this);
    this.handleFolderDelete = this.handleFolderDelete.bind(this);
    this.handleFolderDialogOpen = this.handleFolderDialogOpen.bind(this);
    this.handleFolderDialogClose = this.handleFolderDialogClose.bind(this);
    this.filterMenuItems = this.filterMenuItems.bind(this);
    this.canDropFunc = this.canDropFunc.bind(this);
    this.canDragFunc = this.canDragFunc.bind(this);
  }

  async componentWillMount() {
    await this.props.fetchMenuItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentFlowchart !== this.props.currentFlowchart) {
      this.setState({ currentFlowchart: this.props.currentFlowchart });
    }

    if (prevProps.treeData !== this.props.treeData) {
      //console.log('in props: ' + JSON.stringify(this.props))
      //console.log('treeData in nextProps: ')
      //console.log(JSON.stringify(this.props.treeData))
      this.setState({ treeData: this.props.treeData });
    }

    if (prevProps.menuEdit !== this.props.menuEdit) {
      //if(this.props.menuEdit) console.log('menuEdit on in nextProps')
      //if(!this.props.menuEdit) console.log('menuEdit off in nextProps')
      this.setState({ menuEdit: this.props.menuEdit });
    }
  }

  renderCallBack = () => {
    this.forceUpdate();
  };

  handleCloseWarning = () => {
    this.setState({ openUnsaved: false });
  };

  handleTreeDataChange = (newTreeData) => {
    //('handle tree data: ' + JSON.stringify(newTreeData));
    this.props.updateMenuTreeData(newTreeData);
  };

  handleMenuEdit = () => {
    this.props.toggleMenuEdit();
  };

  handleFlowchartSubmit = (form) => {
    try {
      console.log(form)
      if (
        form.json &&
        (typeof form.json === "string" || form.json instanceof String)
      ) {
        let formJsonParsed = JSON.parse(form.json);
        let webhooksIdentified = [];
        let identifyWebhooks = (obj) => {
          for (let prop in obj) {
            if (prop === "url" && obj[prop] != "N/A") {
              webhooksIdentified.push(obj.url);
            } else if (prop === "webhookUrl") {
              webhooksIdentified.push(obj.webhookUrl);
            } else if (typeof obj[prop] === "object")
              identifyWebhooks(obj[prop]);
          };
        };
        identifyWebhooks(formJsonParsed);
        this.setState({ flowchartJSONWebhooks: webhooksIdentified });

        let replaceWebhookNameWithId = (obj) => {
          for (let prop in obj) {
            if (prop === "webhookUrl") {
              if (obj.webhookEnabled != undefined) {
                obj.webhookEnabled = 1;
                obj.webhookUrlId = form[obj.webhookUrl.replace(/\./g, "_")];
              }
              else {
                obj.webhookId = form[obj.webhookUrl.replace(/\./g, "_")];
              }
              delete obj.webhookUrl;
              delete obj.webhookContentType;
            } else if (typeof obj[prop] === "object")
              replaceWebhookNameWithId(obj[prop]);
          };
        };
        let allWebhooksReplaced = true;
        for (let i = 0; i < webhooksIdentified.length; i++) {
          if (form[webhooksIdentified[i].replace(/\./g, "_")] == undefined) {
            allWebhooksReplaced = false;
          }
        }
        if (allWebhooksReplaced) {
          console.log(formJsonParsed);
          replaceWebhookNameWithId(formJsonParsed);
          console.log(formJsonParsed);
          form.json = formJsonParsed;
          this.props.newFlowchart(form);
          this.handleFlowchartDialogClose();
        }
      } else {
        this.props.newFlowchart(form);
        this.handleFlowchartDialogClose();
      }
    } catch (error) {
      console.log(error)
      this.setState({
        flowchartFormOpen: false,
        flowchartJSONSubmitError: true,
      });
    }
  };

  handleFlowchartClick = (flowchart) => {
    if (flowchart.id != this.state.currentFlowchart.id) {
      if (this.props.saved) {
        this.props.invalidateFlowchartKeywords()
        this.props.loadFlowchart(flowchart)
      } else {
        this.setState({ nextFlowchart: flowchart });
        this.setState({ openUnsaved: true });
      }
    }
  };

  handleSaveMenuTree = () => {
    this.props.saveMenuTree(this.state.treeData);
  };

  handleFlowchartDialogOpen = () => this.setState({ flowchartFormOpen: true, flowchartJSONWebhooks: [] });

  handleFlowchartDialogClose = () =>
    this.setState({ flowchartFormOpen: false, flowchartJSONWebhooks: [] });

  handleFolderSubmit = (form) => {
    this.props.newMenuFolder(form.name, this.state.treeData);
    this.handleFolderDialogClose();
  };

  handleFolderDelete = (rowInfo) => {
    this.props.deleteMenuFolder(rowInfo);
  };

  handleFolderDialogOpen = () => this.setState({ folderFormOpen: true });

  handleFolderDialogClose = () => this.setState({ folderFormOpen: false });

  filterMenuItems = (event) => {
    this.setState({ searchString: event.target.value });
  };

  canDropFunc = ({ nextParent }) => {
    if (nextParent && nextParent.flowchart != null) return false;

    return true;
  };

  canDragFunc = () => this.state.menuEdit;

  render() {
    const title = "SMS Gateway - Flowcharts";

    const UnsavedFlowchartDialog = () => {
      return (
        <Grid item xs={12}>
          <Dialog
            title="You have an unsaved flowchart"
            actions={[
              <Button variant="outlined" onClick={this.handleCloseWarning}>
                Cancel
              </Button>,
              <MuiThemeProvider theme={theme}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    this.props.invalidateFlowchartKeywords();
                    this.props.loadFlowchart(this.state.nextFlowchart);
                    this.handleCloseWarning();
                  }}
                >
                  Continue Without Saving
                </Button>
              </MuiThemeProvider>,
            ]}
            open={this.state.openUnsaved}
            onRequestClose={this.handleCloseWarning}
            autoScrollBodyContent={true}
            contentStyle={{
              position: "absolute",
              left: "50%",
              top: "50%",
              height: "80%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Your current flowchart is unsaved. If you leave this page before
            saving you will lose any unsaved progress.
          </Dialog>
        </Grid>
      );
    };

    const flowchartMenuItems = [
      <ListItem
        primaryText="New flowchart"
        leftIcon={<AddIcon />}
        onClick={this.handleFlowchartDialogOpen}
      />,
      <ListItem
        primaryText="New folder"
        leftIcon={<CreateNewFolderIcon />}
        onClick={this.handleFolderDialogOpen}
      />,
    ];

    if (this.state.menuEdit) {
      flowchartMenuItems.push(
        <ListItem
          primaryText="Edit flowcharts list"
          leftIcon={<EditIcon />}
          style={{ backgroundColor: "rgba(38, 86, 141, 0.39)" }}
          onClick={this.handleMenuEdit}
        />,
        <ListItem
          primaryText="Save flowcharts list"
          leftIcon={<SaveIcon />}
          style={{ backgroundColor: "rgba(38, 86, 141, 0.39)" }}
          onClick={this.handleSaveMenuTree}
        />
      );
    } else {
      flowchartMenuItems.push(
        <ListItem
          primaryText="Edit flowcharts list"
          leftIcon={<EditIcon />}
          onClick={this.handleMenuEdit}
        />
      );
    }

    const filteredTreeData =
      this.state.searchString.length > 0
        ? toggleExpandedForAll({
            treeData: this.state.treeData,
            expanded: true,
          })
        : this.state.treeData;

    const BorderLinearProgress = withStyles({
      root: {
        height: 15,
      },
    })(LinearProgress);

    return (
      <div className="App">
        <HeaderBar pageTitle={title} />
        <Drawer docked={true} open={true}>
          <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
            <AppBar
              showMenuIconButton={false}
              style={{ background: munvoBlue }}
              title={
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "95%" }} />
                </Link>
              }
            />
            <TextField
              data-tip
              data-for="search-flowchart-tt"
              style={{ textIndent: 30 }}
              hintText="Find a Flowchart"
              underlineFocusStyle={{ borderColor: munvoBlue }}
              value={this.state.searchString}
              onChange={this.filterMenuItems}
            />
            <FindFlowchartTooltip />
            <List>{flowchartMenuItems}</List>
            <hr />
            <FlowchartList
              treeData={filteredTreeData}
              onChange={this.handleTreeDataChange}
              canDrag={this.canDragFunc}
              canDrop={this.canDropFunc}
              menuEdit={this.state.menuEdit}
              handleFolderDelete={this.handleFolderDelete}
              handleFlowchartClick={this.handleFlowchartClick}
              searchString={this.state.searchString}
              editEnabled={this.state.menuEdit}
            />

            <Dialog
              open={this.state.flowchartFormOpen}
              title="Create a new flowchart"
              onRequestClose={this.handleFlowchartDialogClose}
              actions={[
                <FlatButton
                  label="Cancel"
                  labelStyle={{ color: munvoBlue }}
                  primary={true}
                  onClick={this.handleFlowchartDialogClose}
                />,
                <RemoteSubmitButton
                  component={FlatButton}
                  form="flowchartform"
                  label="Submit"
                  primary={true}
                  style={{ color: munvoBlue }}
                />,
              ]}
            >
              <FlowchartForm submitCallback={this.handleFlowchartSubmit} flowchartJSONWebhooks={this.state.flowchartJSONWebhooks} existingWebhooks={this.props.urlList.urlList} />
            </Dialog>

            {/*Dialog which pops up when the submitted flowchart json has errors
            (either with webhooks or formatting)} */}
            <Dialog
              actions={[
                <Button
                  variant="outlined"
                  onClick={() =>
                    this.setState({
                      flowchartJSONSubmitError: false,
                    })
                  }
                >
                  Ok
                </Button>,
              ]}
              open={this.state.flowchartJSONSubmitError}
            >
              Error while uploading flowchart.
            </Dialog>
            <Dialog
              open={this.state.folderFormOpen}
              title="Create a new folder"
              onRequestClose={this.handleFolderDialogClose}
              actions={[
                <FlatButton
                  label="Cancel"
                  labelStyle={{ color: munvoBlue }}
                  primary={true}
                  onClick={this.handleFolderDialogClose}
                />,
                <RemoteSubmitButton
                  component={FlatButton}
                  form="folderform"
                  label="Submit"
                  primary={true}
                  style={{ color: munvoBlue }}
                />,
              ]}
            >
              <FolderForm submitCallback={this.handleFolderSubmit} />
            </Dialog>
          </div>
        </Drawer>
        <div className={classnames("app-content", { expanded: true })}>
          {this.props.isLoading || this.props.urlListIsLoading ? (
            <BorderLinearProgress />
          ) : null}
          <FlowchartTree submitCallback={this.renderCallBack} />
        </div>
        <UnsavedFlowchartDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentFlowchart: flowchartSelectors.getMetadata(state),
    treeData: menuSelectors.getParsedMenuTree(state),
    flowcharts: dbSelectors.getFlowchartList(state),
    isLoading: state.db.loading,
    saved: state.flowcharts.saved,
    menuEdit: menuSelectors.getMenuEdit(state),
    urlList: state.urlList,
  };
};

const mapDispatchToProps = { ...menuActions, ...flowchartActions, logout,invalidateFlowchartKeywords };

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
