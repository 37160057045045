import React from "react";
import PropTypes from "prop-types";
import swaggerUIConstructor from "swagger-ui";
import { getJwtToken } from "../utils/generalUtils";
import { connect } from "react-redux";
import "swagger-ui-react/swagger-ui.css";
import { API_VERSION } from "../utils/Constants";

let yamlObject = {};

class SwaggerUI extends React.Component {
  constructor(props) {
    super(props);

    this.SwaggerUIComponent = null;
    this.system = null;

    this.dispatchFetchRequest();
  }

  async dispatchFetchRequest() {
    yamlObject = await this.props.dispatch(fetchApi());
    this.componentDidUpdate(this.props);
    this.generateYamlUI();
    this.forceUpdate();
    return;
  }

  generateYamlUI() {
    const ui = swaggerUIConstructor({
      spec: yamlObject,

      requestInterceptor: this.requestInterceptor,
      responseInterceptor: this.responseInterceptor,
      onComplete: this.onComplete,
      docExpansion: this.props.docExpansion
    });
    this.system = ui;
    this.SwaggerUIComponent = ui.getComponent("App", "root");
  }

  componentDidUpdate(prevProps) {
    if (this.props.spec !== prevProps.spec && this.props.spec) {
      if (typeof this.props.spec === "object") {
        this.system.specActions.updateSpec(JSON.stringify(this.props.spec));
      } else {
        this.system.specActions.updateSpec(this.props.spec);
      }
    }
  }

  render() {
    return this.SwaggerUIComponent ? <this.SwaggerUIComponent /> : null;
  }
}

SwaggerUI.propTypes = {
  spec: PropTypes.oneOf([PropTypes.string, PropTypes.object])
};

const fetchApi = () => async dispatch => {
  let response;
  response = await fetch(API_VERSION + "swagger", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  }).catch(e => {});
  const json = await response.json();
  return json;
};

export default connect()(SwaggerUI);
