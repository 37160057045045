import React, { useEffect, useState } from "react";
import { Dialog } from "material-ui";
import TextField from "@material-ui/core/TextField";
import { ClickAwayListener, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import copy from "clipboard-copy";

let FlowchartDownloadDialog = (props) => {
  const [
    openFlowchartDownloadTooltip,
    setOpenFlowchartDownloadTooltip,
  ] = useState(false);

  const [
    openFlowchartDownloadDialog,
    setOpenFlowchartDownloadDialog,
  ] = useState(props.openFlowchartDownload);

  useEffect(() => {
    setOpenFlowchartDownloadDialog(props.openFlowchartDownload);
  }, [props.openFlowchartDownload]);

  if (props.FlowchartJSON != "null") {
    return (
      <Dialog
        actions={[
          <div>
            <ClickAwayListener
              onClickAway={() => setOpenFlowchartDownloadTooltip(false)}
            >
              <Tooltip
                title="Copied to clipboard!"
                open={openFlowchartDownloadTooltip}
                onClose={() => setOpenFlowchartDownloadTooltip(false)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenFlowchartDownloadTooltip(true);
                    copy(props.FlowchartJSON);
                    setTimeout(
                      () => setOpenFlowchartDownloadTooltip(false),
                      3000
                    );
                  }}
                  className={props.classes.button}
                >
                  Copy to clipboard
                </Button>
              </Tooltip>
            </ClickAwayListener>
            <Button
              variant="outlined"
              onClick={() => props.onClose()}
              className={props.classes.button}
            >
              Cancel
            </Button>
          </div>,
        ]}
        open={openFlowchartDownloadDialog}
        onRequestClose={() => props.onClose()}
      >
        Paste this JSON while creating a new flowchart!
        <br></br>
        <TextField
          id="standard-read-only-input"
          defaultValue={props.FlowchartJSON}
          multiline
          fullWidth
          minRows={10}
          maxRows={10}
          InputProps={{
            readOnly: true,
          }}
        />
      </Dialog>
    );
  } else
    return (
      <Dialog
        actions={[
          <Button
            variant="outlined"
            onClick={() => props.onClose()}
            className={props.classes.button}
          >
            Ok
          </Button>,
        ]}
        open={openFlowchartDownloadDialog}
        onRequestClose={() => props.onClose()}
      >
        Error while downloading flowchart. Did you delete any webhooks used in
        this flowchart?
      </Dialog>
    );
};

export default FlowchartDownloadDialog;
