import * as Types from './types'

export const userReducer = (state = { users: [] } , action) => {
  switch(action.type){
    case Types.USER_REQUEST:
      return{
        loading: true
      }
    case Types.USER_SUCCESS:
      return{
        loading: false,
        users: action.payload
      }
    case Types.USER_FAILURE:
      return{
        loading: false
      }
    default:
      return state
  }
}