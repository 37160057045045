import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Field, FieldArray, reduxForm } from "redux-form";
import { TextField } from "redux-form-material-ui";
import { isCourierConstants } from "./utils";
import LinkedCustomField from "./LinkedCustomField";
import { ActionDelete, ContentAddCircle } from "material-ui/svg-icons";
import { IconButton } from "material-ui";
import { munvoBlue } from "../utils/globalStyles";

class EditNodeTemplateForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="text"
              floatingLabelText="Node Template Name"
              name="nodetemplatename"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="text"
              floatingLabelText="Customer Response"
              name="customerResponse"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldArray component={FieldArrInput} type="text" name="replies" />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              type="number"
              floatingLabelText="Priority"
              name="priority"
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const FieldArrInput = props => {
  return (
    <ul className="linkedRepliesList">
      <LinkedCustomField label="Reply" name="firstReply" />
      <IconButton
        onClick={() => {
          props.fields.push();
        }}
      >
        <ContentAddCircle />
      </IconButton>
      {props.fields.map((replyLinked, replyIndex) => (
        <li key={replyIndex}>
          <LinkedCustomField
            label={"Linked Reply #" + (replyIndex + 1)}
            name={`${replyLinked}.reply`}
          />
          <IconButton
            onClick={e => {
              props.fields.remove(replyIndex);
            }}
          >
            <ActionDelete />
          </IconButton>
        </li>
      ))}
    </ul>
  );
};

EditNodeTemplateForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  nodeTemplateList: PropTypes.array.isRequired
};

const validate = (values, props) => {
  const errors = {};

  if (isCourierConstants(values)) {
    errors.customerResponse =
      "Cannot add a reserved courier keyword to a node template. Please set these values in the reserved keywords panel on the flowchart page.";
  }

  if (!values.nodeTemplateName) {
    errors.nodeTemplateName = "Required";
  } else {
    let doesNodeTemplateNameExist = props.nodeTemplateList.find(
      nodeTemplate => {
        return (
          nodeTemplate.nodeTemplateName.toLowerCase() ===
            values.nodeTemplateName.toLowerCase() &&
          nodeTemplate.nodeTemplateName.toLowerCase() !==
            props.initialValues.nodeTemplateName.toLowerCase()
        );
      }
    );

    if (doesNodeTemplateNameExist) {
      errors.nodeTemplateName = "Node Template already exists";
    }
  }

  if (!values.firstReply) {
    errors.firstReply = "Required";
  }

  if (values.replies) {
    const repliesArrayErrors = [];
    values.replies.forEach((replyObject, replyIndex) => {
      const replyErrors = {};
      if (!replyObject || !replyObject.reply) {
        replyErrors.reply = "Required";
        repliesArrayErrors[replyIndex] = replyErrors;
      }
    });

    if (repliesArrayErrors.length) {
      errors.replies = repliesArrayErrors;
    }
  }

  if (!values.priority) {
    errors.priority = "Required";
  }

  return errors;
};

export default reduxForm({
  form: "editNodeTemplateForm",
  validate,
  fields: ["id", "nodeTemplateName", "customerResponse", "priority"],
  onSubmit: (values, dispatch, props) => {
    props.submitCallback(values);
  }
})(EditNodeTemplateForm);
