import * as Types from "./types";

export const profileReducer = (
  state = { profile: { forcePassReset: 0 } },
  action
) => {
  switch (action.type) {
    case Types.PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        success: 0
      };
    case Types.PROFILE_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
        success: 0
      };
    case Types.PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        profile: action.payload,
        success: 1
      };
    case Types.PROFILE_FAILURE:
      return {
        loading: false,
        profile: action.payload,
        success: -1
      };
    default:
      return state;
  }
};
