import * as Types from './types'



export const KeywordsReducer = (state = { keywords: [] }, action) => {
    switch(action.type){
          case Types.KEYWORDS_GET_REQUEST:
            return { ...state, loading: true }
          case Types.KEYWORDS_GET_SUCCESS:
            return { loading: false, keywords: action.payload, needsRefresh: false }
          case Types.KEYWORDS_GET_FAILURE:
            return { ...state, loading: false, needsRefresh: false }
          case Types.KEYWORDS_POST_REQUEST:
            return {...state,loading:true}
          case Types.KEYWORDS_POST_SUCCESS:
            return {...state,keywords: [],loading:false, needsRefresh: true}
          case Types.KEYWORDS_POST_FAILURE:
            return { ...state, loading: false }
          case Types.KEYWORDS_DELETE_REQUEST:
            return{...state,loading:true}
          case Types.KEYWORDS_DELETE_SUCCESS:
            return {...state,keywords: [],loading:false, needsRefresh: true}
          case Types.KEYWORDS_DELETE_FAILURE:
            return {...state,loading:false}
          case Types.KEYWORDS_PUT_REQUEST:
            return{...state,loading:true}
          case Types.KEYWORDS_PUT_SUCCESS:
            return {...state,keywords: [],loading:false, needsRefresh: true}
          case Types.KEYWORDS_PUT_FAILURE:
            return {...state,loading:false}
          case Types.KEYWORDS_INVALIDATE_REQUEST:
            return {...state,needsRefresh: true}
          default:
            return state
    }
}