export const createTableData = (data) => {
  let rows = []

  data.map(d => {
    rows.push({ 
      id: d.id, 
      username: d.username,
      password: d.password,
      tsCreated: d.tsCreated,
      isActive: d.isActive,
      deleted: d.deleted,
      role: d.role,
      rolename: d.role.rolename,
      forcePassReset: d.forcePassReset
    })
  })

  return rows
}

export const checkForSpecialUser = (selectedUser) => {
  let isSpecialUser = false
  
  if(selectedUser.username === 'admin' || selectedUser.username === 'ac-admin'){
    isSpecialUser = true
  }

  return isSpecialUser
}
