import * as Types from "./types";
import "cross-fetch/polyfill";
import * as Cookies from "js-cookie";
import { createAction } from "redux-actions";
import { API_VERSION } from "../../utils/Constants";
import { confirmAlert } from "react-confirm-alert";

const loginFailure = createAction(Types.LOGIN_FAILURE);
const logoutSuccess = createAction(Types.LOGOUT);
const logoutFailure = createAction(Types.LOGOUT_FAILURE);
const loginRequest = createAction(Types.LOGIN_REQUEST);

export const login = (form, rememberMe) => async (dispatch) => {
  dispatch(loginRequest());
  const response = await fetch(API_VERSION + "session", {
    method: "POST",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  });

  const body = await response.json();
  if (response.status !== 200) {
    let message = body.message
    dispatch(loginFailure({ message }));
    if (body.loggedIn === 2) {
      confirmAlert({
        title: "Too many failed login attempts.",
        message: "Please try again in 20 minutes.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  } else {
    if (body.loggedIn === 1) {
      if (rememberMe) {
        localStorage.setItem("username", form.username);
        localStorage.setItem("role", body.rolename);
        localStorage.setItem("jwt-token", body.jwtToken);
        localStorage.setItem("timeout", body.timeout);
        localStorage.setItem("refreshToken", body.refreshToken);
      } else {
        sessionStorage.setItem("username", form.username);
        sessionStorage.setItem("role", body.rolename);
        sessionStorage.setItem("jwt-token", body.jwtToken);
        sessionStorage.setItem("timeout", body.timeout);
        sessionStorage.setItem("refreshToken", body.refreshToken);
      }
      dispatch(loginSuccess(form.username, body.rolename, rememberMe, body.timeout));
    } else {
      dispatch(loginFailure());
    }
  }
};

export const logout = (username) => async (dispatch) => {
  try {
    const response = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
    }).catch((e) => {
      console.log(e);
    });

    const body = await response.json();

    if (body.ok) {
      dispatch(logoutSuccess());
      Cookies.remove("JSESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    } else {
      window.location.reload(true);

      dispatch(logoutFailure(username));
    }
  } catch (e) {
    window.location.reload(true);
  }
};

const loginSuccess = (username, rolename, rememberMe, timeout) => {
  return {
    type: Types.LOGIN_SUCCESS,
    loggedIn: 1,
    username: username,
    role: rolename,
    rememberMe: rememberMe,
    timeout: timeout,
  };
};
