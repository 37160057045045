export const LOAD_TREE = 'LOAD_TREE'
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'
export const SEARCH_INCREMENT = 'SEARCH_INCREMENT'
export const SEARCH_DECREMENT = 'SEARCH_DECREMENT'
export const UPDATE_TREE = 'UPDATE_TREE'
export const SEARCH_CALLBACK = 'SEARCH_CALLBACK'
export const NEW_FLOWCHART = 'NEW_FLOWCHART'
export const MODIFY_FLOWCHART = "MODIFY_FLOWCHART"
export const SAVE_FLOWCHART = "SAVE_FLOWCHART"
export const SESSION_RESET_IDLE = "SESSION_RESET_IDLE"
export const SESSION_RESET_FAILURE = "SESSION_RESET_FAILURE"
export const SESSION_RESET_SUCCESS = "SESSION_RESET_SUCCESS"
export const SESSION_PATH_REQUEST = "SESSION_PATH_REQUEST"
export const SESSION_PATH_FAILURE = "SESSION_PATH_FAILURE"
export const SESSION_PATH_SUCCESS = "SESSION_PATH_SUCCESS"
export const LOGOUT = 'USER_LOGOUT'
export const DUPLICATE_FLOWCHART_SUCCESS = "DUPLICATE_FLOWCHART_SUCCESS"
export const DUPLICATE_FLOWCHART_FAILURE = "DUPLICATE_FLOWCHART_FAILURE"