import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from '../picto_blue.svg'
import '../App.css'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'

class CircularIndeterminate extends Component{

  render(){
    const{ isLoading } = this.props

    return(
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
        open={isLoading}
      >
        <DialogTitle><img src={logo} className='App-logo' alt='logo' /></DialogTitle>
      </Dialog>
    )
  }
}

CircularIndeterminate.propTypes = {
  isLoading: PropTypes.bool.isRequired
}

export default CircularIndeterminate