import React from "react";
import { connect } from "react-redux";
import { fetchWebhookErrorLogsByDates } from "../reducers";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CircularIndeterminate from "../CircularLoading";
import DateFnsUtils from "@date-io/date-fns";
import { validateTimezone, toRequestTimestamp, apiToLocalTimezone } from "./TablesUtils"
import { MuiPickersUtilsProvider, DateTimePicker } from "material-ui-pickers";
import Tooltip from "@material-ui/core/Tooltip";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  },
  tableCell80: {
    overflow: "auto",
    width: "90px",
    display: "block"
  },
  tableCell120: {
    overflow: "auto",
    width: "120px",
    display: "block"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: munvoOrange
      }
    }
  }
});

function createData(
  webhookErrorLogId,
  status,
  msg,
  errorMsg,
  retryCount,
  time,
  webHookId,
  transactionId
) {
  return {
    webhookErrorLogId,
    status,
    msg,
    errorMsg,
    retryCount,
    time,
    webHookId,
    transactionId
  };
}

let rows = [];
let sortedRows = [];
let columnsQueries = [];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  sortedRows = stabilizedThis.map(el => el[0]);
  return sortedRows;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: "webhookErrorLogId", label: "ID", width: "100px" },
  { id: "status", label: "status", width: "100px" },
  { id: "msg", label: "Message", width: "100px" },
  { id: "errorMsg", label: "Error Message", width: "130px" },
  { id: "retryCount", label: "Retry Count", width: "100px" },
  { id: "time", label: "Time", width: "100px" },
  { id: "webHookId", label: "Webhook Id", width: "100px" },
  { id: "transactionId", label: "Transaction Id", width: "120px" }
];

function WebhookErrorLogTableHead(props) {
  const { order, orderBy, onRequestSort, callbackFilter } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const handleColumnQueryChange = rowId => event => {
    if (columnsQueries.find(x => x.column === rowId) !== undefined) {
      columnsQueries.find(x => x.column === rowId).query = event.target.value;
    } else {
      columnsQueries.push({ column: rowId, query: event.target.value });
    }
    callbackFilter();
  };
  return (
    <TableHead padding="5px">
      {headRows.map(row => (
        <TableCell
          key={row.id}
          align={"center"}
          sortDirection={orderBy === row.id ? order : false}
        />
      ))}
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={"center"}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <TextField
                style={{ marginLeft: "20px" }}
                label={row.label}
                placeholder="Filter"
                value={
                  columnsQueries.find(x => x.column === row.id) !== undefined
                    ? columnsQueries.find(x => x.column === row.id).query
                    : ""
                }
                onChange={handleColumnQueryChange(row.id)}
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
WebhookErrorLogTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const WebhookErrorLogTableToolbar = props => {
  return (
    <Toolbar>
      <Typography variant="h5" id="tableTitle">
        <h5>Webhook Error Log History</h5>
      </Typography>
    </Toolbar>
  );
};

const WebhookErrorLogTable = props => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("webookErrorLogId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [numberOfWebhookErrorLogs, setnumberOfWebhookErrorLogs] = React.useState(25);
  const [dateFrom, handleFromDateChange] = React.useState(
    setdefaultDate("from")
  );
  const [dateTo, handleToDateChange] = React.useState(setdefaultDate("to"));
  const [timezone, setTimezone] = React.useState("America/Toronto");
  const [isValidTimezone, setIsValidTimezone] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  //set the default date filter to today
  function setdefaultDate(type) {
    let temp = new Date();
    if (type === "from") {
      temp.setHours(0, 0, 0);
    } else if (type === "to") {
      temp.setHours(23, 59, 59);
    }
    return temp;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  //if the expression is undefined return an empty string
  //else return the expression
  function notUndefined(expression) {
    if (expression !== undefined) {
      return expression;
    }
    return "";
  }

  //if x contains one of the queries {column: "", query:""} return true
  //constraint on columns are joined by and OR
  const filterFunction = x => {
    for (let i = 0; i < columnsQueries.length; i++) {
      if (
        x[columnsQueries[i].column] !== undefined &&
        columnsQueries[i].query !== "" &&
        !x[columnsQueries[i].column]
          .toString()
          .toLowerCase()
          .includes(columnsQueries[i].query.toLowerCase())
      ) {
        return false;
      }
    }
    return true;
  };

  //update rows based on the store
  function updateRows() {
    rows = [];
    let currentWebhookErrorLogList = props.WebhookErrorLogList;

    if (currentWebhookErrorLogList !== undefined) {
      for (let i = 0; i < currentWebhookErrorLogList.length; i++) {
        const currentWebhookErrorLog = currentWebhookErrorLogList[i];
        rows.push(
          createData(
            notUndefined(currentWebhookErrorLog.id),
            notUndefined(currentWebhookErrorLog.httpStatusCode),
            notUndefined(currentWebhookErrorLog.httpStatusMessage),
            notUndefined(currentWebhookErrorLog.httpResponseErrorMessage),
            notUndefined(currentWebhookErrorLog.retryCount),
            notUndefined(apiToLocalTimezone(currentWebhookErrorLog.requestTimestamp, timezone)),
            notUndefined(currentWebhookErrorLog.webhookUrl.id),
            notUndefined(currentWebhookErrorLog.transaction.id)
          )
        );
      }
    }
  }

  function handleNumberOfWebhookErrorLogsChange(e) {
    setnumberOfWebhookErrorLogs(e.target.value);
  }

  function callbackFilter() {
    setToggle(!toggle);
  }

  async function dispatchFetchRequest() {
    if (!validateTimezone(timezone)) { return; }
    setIsLoading(true);
    await props.dispatch(
      fetchWebhookErrorLogsByDates(
        toRequestTimestamp(dateFrom, timezone),
        toRequestTimestamp(dateTo, timezone),
        numberOfWebhookErrorLogs
      )
    );
    setIsLoading(false);
  }

  // Ensure scrollbar appears/table is scrollable
  const refPaperTable = React.useRef(null);
  React.useEffect(() => {
    document.body.style.overflow = "auto";
    refPaperTable.current.style.maxHeight = "calc(100vh - 196px)";
    return () => {
      document.body.style.overflow = "";
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <CircularIndeterminate isLoading={isLoading} />
      <div>
        <Paper style={{ overflow: "auto" }} ref={refPaperTable}>
          <div align="center">
            <Grid container style={{ marginTop: "20px" }} spacing={24}>
              <Grid item xs={13}>
                <WebhookErrorLogTableToolbar />
              </Grid>
              <Grid item xs={13}>
                <Tooltip
                  title="Large number may take longer to query"
                  placement="top-start"
                >
                  <TextField
                    select
                    style={{
                      width: "120px",
                      margin: "10px",
                      marginRight: "30px"
                    }}
                    label="Number of Rows"
                    value={numberOfWebhookErrorLogs}
                    onFocus={updateRows()}
                    onChange={handleNumberOfWebhookErrorLogsChange}
                  >
                    {[
                      {
                        value: "5",
                        label: "5"
                      },
                      {
                        value: "10",
                        label: "10"
                      },
                      {
                        value: "25",
                        label: "25"
                      },
                      {
                        value: "50",
                        label: "50"
                      },
                      {
                        value: "100",
                        label: "100"
                      },
                      {
                        value: "500",
                        label: "500"
                      }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    style={{ padding: "10px", width: 180 }}
                    id="From"
                    label="From"
                    allowKeyboardControl={true}
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateFrom}
                    onChange={handleFromDateChange}
                    showTodayButton
                  />
                  <DateTimePicker
                    style={{ padding: "10px", width: 180 }}
                    id="To"
                    label="To"
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateTo}
                    onChange={handleToDateChange}
                    showTodayButton
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  id="timezone"
                  label="Timezone (IANA)"
                  variant="filled"
                  helperText="Please use IANA format such as 'America/Toronto'"
                  defaultValue="America/Toronto"
                  value={timezone}
                  onChange={event => { setIsValidTimezone(validateTimezone(event.target.value)); setTimezone(event.target.value); }}
                  error={!isValidTimezone}
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    padding: "5px",
                    background: munvoOrange,
                    marginLeft: "20px"
                  }}
                  onClick={dispatchFetchRequest}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Table aria-labelledby="tableTitle" width="100%" stickyHeader>
              <WebhookErrorLogTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                callbackFilter={callbackFilter}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .filter(filterFunction)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow hover key={row.name}>
                        <TableCell align="right" key="webhookErrorLogId">
                          <span style={theme.tableCell80}>
                            {row.webHookErrorLogId}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="status">
                          <span style={theme.tableCell80}>
                            {row.status}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="msg">
                          <span style={theme.tableCell120}>
                            {row.msg}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="errorMsg">
                          <span style={theme.tableCell120}>
                            {row.errorMsg}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="retryCount">
                          <span style={theme.tableCell120}>
                            {row.retryCount}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="timeCreated">
                          <span align="right" style={theme.tableCell120}>
                            {row.time}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="webhookId">
                          <span align="right" style={theme.tableCell80}>
                            {row.webHookId}
                          </span>
                        </TableCell>
                        <TableCell align="right" key="transactionId">
                          <span align="right" style={theme.tableCell120}>
                            {row.transactionId}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </MuiThemeProvider>
  );
};
const mapStateToProps = state => {
  return {
    WebhookErrorLogList: state.tables.webhookErrorLogList
  };
};
export default connect(mapStateToProps)(WebhookErrorLogTable);
