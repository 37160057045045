import * as Types from './types'

export const templateReducer = (state = { templates: [] }, action) => {
  switch(action.type){
    case Types.TEMPLATE_REQUEST:
      return { ...state, loading: true }
    case Types.TEMPLATE_SUCCESS:
      return { loading: false, templates: action.payload }
    case Types.TEMPLATE_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}