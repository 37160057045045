import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import { API_VERSION } from "../../utils/Constants";

const roleRequest = createAction(Types.ROLE_REQUEST);
const roleFailure = createAction(Types.ROLE_FAILURE);
const roleSuccess = createAction(Types.ROLE_SUCCESS, roles => roles);

export const getRoles = () => async dispatch => {
  dispatch(roleRequest());
  const response = await fetch(API_VERSION + "role", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status !== 200) {
    dispatch(roleFailure());
  } else {
    const body = await response.json();
    dispatch(roleSuccess(body.roleList));
  }
};
