import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { postDefaultKeywords,deleteKeywords,getDefaultKeywords,modifyKeywords } from "../reducers";
import { Dialog } from "material-ui";
import DefaultKeywordsEditForm from "./DefaultKeywordsEditForm";
import { configurationStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { munvoOrange,munvoBlue } from '../utils/globalStyles'
import ReactTooltip from "react-tooltip";
import HelpIcon from '@material-ui/icons/Help';
import { fetchUrlsWithRedux } from '../reducers';
import WrapperTable from "../WrapperTable";

class DefaultKeywords extends Component {
  constructor(props) {
    super(props);

    this.state = { openEdit: false, openAdd: false ,selectedDefaultKeywords: null,data :[],urlList: [] };

    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleOpenAdd = this.handleOpenAdd.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
    this.handleDeleteDefaultKeywords = this.handleDeleteDefaultKeywords.bind(this);

  }

  componentWillMount() {
    this.props.dispatch(getDefaultKeywords());

    this.props.dispatch(fetchUrlsWithRedux());

  }
  componentDidUpdate()
  {
    if (this.props.keywordsNeedsRefresh === true)
    {
      this.props.dispatch(getDefaultKeywords())
    }
  }
  
  handleOpenAdd(){
    this.setState({ openAdd: true, selectedDefaultKeywords: null});

  }
  handleCloseAdd() {
    this.setState({ openAdd: false });
  }

  handleSubmit(form) {
    //add default values for default Keywords
    form.flowchartId = null
    form.isDefault = true
    if(this.state.openEdit){
      this.props.dispatch(modifyKeywords(form,form.id));
    }
    else{
      this.props.dispatch(postDefaultKeywords(form));
    }
    this.setState({ openAdd: false,openEdit: false  });
  }
    

  handleOpenEdit(e) {
    this.setState({ openAdd: true,openEdit: true, selectedDefaultKeywords: e.rowData, });

  }

  handleCloseEdit() {
    this.setState({  openAdd: false,openEdit: false });
  }

  handleDeleteDefaultKeywords(id) {
    this.props.dispatch(deleteKeywords(id));
    this.setState({ openAdd: false,openEdit: false });
  }

  render() {

    var { data, classes } = this.props;


    return (

      <div style={{ paddingRight: "15%" }}>
      <div style={{textAlign: 'left', color: 'red'}} >
        WARNING: Reserved keywords are required to follow certain rules, make sure you know those rules before applying changes here. 
        <br/> If you are unsure about the change you want to make please contact your admin.
          <HelpIcon data-tip data-for="DefaultKeywords-warning-tt" style={{ color: munvoBlue }} />
          <ReactTooltip id='DefaultKeywords-warning-tt' place='right' type='info' effect='solid'>
            <span>Reserved keywords are subject to country specific regulations, make sure to plan accordingly</span>
          </ReactTooltip>
      </div>
      
      <div 
        style={{
        display: 'flex',
        padding: '10px'
        }}
      >
          <Button 
            style={{ background: munvoOrange }}
            data-tip 
            id='add-button' 
            variant='contained' 
            color='primary'
            onClick={() =>this.handleOpenAdd()}>Add keywords
          </Button>         
          <HelpIcon data-tip data-for="add-DefaultKeywords-warning-tt" style={{ color: munvoBlue }} />
          <ReactTooltip id='add-DefaultKeywords-warning-tt' place='right' type='info' effect='solid'>
            <span>You can only have one record with the same (language,role) pair except for the "global" role </span>
          </ReactTooltip>
          <Dialog
              title="Add Default Keywords"
              actions={[
                <Button
                  variant="outlined"
                  onClick={()=>this.handleDeleteDefaultKeywords(this.state.selectedDefaultKeywords.id)}
                  backgroundColor="red"
                  className={classes.button}
                >
                Delete
                </Button>,
                <Button
                  variant="outlined"
                  onClick={this.handleCloseAdd}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="DefaultKeywordsEditForm"
                  label="Submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openAdd}
              onRequestClose={this.handleCloseAdd}
              >
              <DefaultKeywordsEditForm
                parentComponent="DefaultKeywords"
                webhookList={this.props.urlList.urlList}
                submitCallback={this.handleSubmit}
                initialValues={this.state.selectedDefaultKeywords}
              />
            </Dialog>
      </div>
        <Grid container>
        <Grid item xs={12}>
            <Paper style={{ height: 600, width: "100%" }}>
              <WrapperTable
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                onRowDoubleClick={e => this.handleOpenEdit(e)}
                rowHeight={56}
                headerHeight={56}
                columns={[
                  {
                    width: 100,
                    flexGrow: 1.0,
                    label: "Keywords (comma separated)",
                    dataKey: "keywords"
                  },
                  {
                    width: 100,
                    flexGrow: 1.0,
                    label: "Reply",
                    dataKey: "response"
                  },
                  {
                    width: 100,
                    flexGrow: 1.0,
                    label: "Language",
                    dataKey: "language"
                  },
                  {
                    width: 100,
                    flexGrow: 1.0,
                    label: "Role",
                    dataKey: "role"
                  }
                ]}
              />
            </Paper>
          </Grid>
         
        </Grid>
      </div>
    );
  }
}

DefaultKeywords.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    urlList: state.urlList,
    data: state.Keywords.keywords,
    keywordsNeedsRefresh: state.Keywords.needsRefresh
  };
};

export default connect(mapStateToProps)(
  withStyles(configurationStyles)(DefaultKeywords)
);
