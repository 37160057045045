import * as Types from "./types";
import "cross-fetch/polyfill";
import { getJwtToken } from "../../utils/generalUtils";
import { createAction } from "redux-actions";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const postUrlRequest = createAction(Types.POST_URL_REQUEST);
const postUrlSuccess = createAction(Types.POST_URL_SUCCESS);
const postUrlError = createAction(Types.POST_URL_FAILURE);
const deleteUrlRequest = createAction(Types.DELETE_URL_REQUEST);
const deleteUrlSuccess = createAction(Types.DELETE_URL_SUCCESS);
const deleteUrlError = createAction(Types.DELETE_URL_FAILURE);
const fetchUrlsRequest = createAction(Types.FETCH_URLS_REQUEST);
const fetchUrlsSuccess = createAction(
  Types.FETCH_URLS_SUCCESS,
  payload => payload
);
const newPingSuccess = createAction(Types.NEW_PING_SUCCESS, payload => payload);
const testPingSuccess = createAction(Types.TEST_PING_SUCCESS);
const testPingFailure = createAction(Types.TEST_PING_FAILURE);
const newPingNotFound = createAction(
  Types.NEW_PING_NOT_FOUND,
  payload => payload
);
const fetchUrlsError = createAction(Types.FETCH_URLS_FAILURE);
const fetchTwilioCredentialsRequest = createAction(
  Types.FETCH_TWILIO_CREDENTIALS_REQUEST
);
const fetchTwilioCredentialsSuccess = createAction(
  Types.FETCH_TWILIO_CREDENTIALS_SUCCESS,
  payload => payload
);
const fetchTwilioCredentialsFailure = createAction(
  Types.FETCH_TWILIO_CREDENTIALS_FAILURE
);

const fetchAwsCredentialsRequest = createAction(
  Types.FETCH_AWS_CREDENTIALS_REQUEST
);
const fetchAwsCredentialsSuccess = createAction(
  Types.FETCH_AWS_CREDENTIALS_SUCCESS,
  payload => payload
);
const fetchAwsCredentialsFailure = createAction(
  Types.FETCH_AWS_CREDENTIALS_FAILURE
);

const fetchSinchCredentialsRequest = createAction(
  Types.FETCH_SINCH_CREDENTIALS_REQUEST
);
const fetchSinchCredentialsSuccess = createAction(
  Types.FETCH_SINCH_CREDENTIALS_SUCCESS,
  payload => payload
);
const fetchSinchCredentialsFailure = createAction(
  Types.FETCH_SINCH_CREDENTIALS_FAILURE
);

const fetchTelusCredentialsRequest = createAction(
  Types.FETCH_TELUS_CREDENTIALS_REQUEST
);
const fetchTelusCredentialsSuccess = createAction(
  Types.FETCH_TELUS_CREDENTIALS_SUCCESS,
  payload => payload
);
const fetchTelusCredentialsFailure = createAction(
  Types.FETCH_TELUS_CREDENTIALS_FAILURE
);

const testTwilioCredentialsSuccess = createAction(
  Types.TEST_TWILIO_CREDENTIALS_SUCCESS
);
const testTwilioCredentialsFailure = createAction(
  Types.TEST_TWILIO_CREDENTIALS_FAILURE
);

const testAwsCredentialsSuccess = createAction(
  Types.TEST_AWS_CREDENTIALS_SUCCESS
);
const testAwsCredentialsFailure = createAction(
  Types.TEST_AWS_CREDENTIALS_FAILURE
);

const testSinchCredentialsSuccess = createAction(
  Types.TEST_SINCH_CREDENTIALS_SUCCESS
);
const testSinchCredentialsFailure = createAction(
  Types.TEST_SINCH_CREDENTIALS_FAILURE
);

const testTelusCredentialsSuccess = createAction(
  Types.TEST_TELUS_CREDENTIALS_SUCCESS
);
const testTelusCredentialsFailure = createAction(
  Types.TEST_TELUS_CREDENTIALS_FAILURE
);

const forceLogout = createAction(Types.LOGOUT);
const adblockDetected = createAction(Types.ADBLOCK_DETECTED);

export const pingNewUrl = url => async dispatch => {
  if (validUrl(url)) {
    const response = await fetch(
      API_VERSION +
      "ping/async/" +
      encodeURIComponent(url).replace(/%2F/g, "%252F"),
      {
        method: "GET",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" }
      }
    ).catch(e => {
      dispatch(adblockDetected());
    });

    if (response && response.status === 200) {
      dispatch(newPingSuccess("Responded!"));
    } else {
      dispatch(newPingNotFound("Not found..."));
    }
  } else {
    dispatch(newPingNotFound("Not found..."));
  }
};

export const addUrl = url => async dispatch => {
  dispatch(postUrlRequest());
  const response = await fetch(API_VERSION + "webhookurl", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(url)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(postUrlError());
  } else {
    await response.json();
    dispatch(postUrlSuccess());
  }
  dispatch(fetchUrlsWithRedux(true));
};

export const deleteUrl = id => async dispatch => {
  dispatch(deleteUrlRequest());
  const response = await fetch(API_VERSION + "webhookurl/" + id, {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(deleteUrlError());
  } else {
    await response.json();
    dispatch(deleteUrlSuccess());
  }
  dispatch(fetchUrlsWithRedux(true));
};

export const testUrl = url => async dispatch => {
  const response = await fetch(
    API_VERSION + "ping/" + encodeURIComponent(url).replace(/%2F/g, "%252F"),
    {
      method: "GET",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    }
  ).catch(e => {
    console.log("Error pinging url. Please disable any ad blocker.");
  });

  if (response.status === 200) {
    dispatch(testPingSuccess());
  } else {
    dispatch(testPingFailure());
  }
};

export const fetchUrlsWithRedux = checkStatus => {
  return dispatch => {
    dispatch(fetchUrlsRequest());
    return fetchUrls().then(([response, json]) => {
      if (response.status === 200) {
        dispatch(fetchUrlsSuccess(json));
        if (Array.isArray(json)) {
          var promises = [];
          if (checkStatus) {
            json.forEach(function (url) {
              promises.push(
                fetch(
                  API_VERSION +
                  "ping/" +
                  encodeURIComponent(url.url).replace(/%2F/g, "%252F"),
                  {
                    method: "GET",
                    credentials: "same-origin",
                    headers: { "Content-Type": "application/json" }
                  }
                )
                  .then(function (request) {
                    if (request.status > 199 && request.status < 300) {
                      url.status = "Responded";
                    } else {
                      url.status = "Not found";
                    }
                  })
                  .catch(e => {
                    url.status = "Not found";
                    dispatch(adblockDetected());
                  })
              );
            });
          }
        }
        Promise.all(promises).then(function () {
          dispatch(fetchUrlsSuccess(json));
        });
      } else {
        console.log("fetching urls failed");
        dispatch(fetchUrlsError());
      }
    });
  };
};

export const fetchTwilioCredentials = () => async dispatch => {
  dispatch(fetchTwilioCredentialsRequest());

  const response = await fetch(API_VERSION + "credentials/twilio", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status !== 200) {
    dispatch(fetchTwilioCredentialsFailure());
  } else {
    const body = await response.json();
    dispatch(fetchTwilioCredentialsSuccess(body));
    dispatch(testTwilioCredentials(body.twilio_username, body.twilio_token));
  }
};

export const testTwilioCredentials = (username, password) => async dispatch => {
  const response = await fetch(
    API_VERSION +
    "ping/" +
    encodeURIComponent(
      "https://" +
      username +
      ":" +
      password +
      "@api.twilio.com/2010-04-01/Accounts"
    ).replace(/%2F/g, "%252F"),
    {
      method: "GET",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    }
  ).catch(e => {
    dispatch(adblockDetected());
  });

  if (response && response.status === 200) {
    dispatch(testTwilioCredentialsSuccess());
  } else {
    dispatch(testTwilioCredentialsFailure());
  }
};

export const fetchAwsCredentials = () => async dispatch => {
  dispatch(fetchAwsCredentialsRequest());

  const response = await fetch(API_VERSION + "credentials/aws", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status !== 200) {
    dispatch(fetchAwsCredentialsFailure());
  } else {
    const body = await response.json();
    dispatch(fetchAwsCredentialsSuccess(body));
    dispatch(testAwsCredentials(body.credentialsStatus));
  }
};

export const testAwsCredentials = credentialsStatus => async dispatch => {
  if (credentialsStatus === "200") {
    dispatch(testAwsCredentialsSuccess());
  } else {
    dispatch(testAwsCredentialsFailure());
  }
};

export const fetchSinchCredentials = () => async dispatch => {
  dispatch(fetchSinchCredentialsRequest());

  const response = await fetch(API_VERSION + "credentials/sinch", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status !== 200) {
    dispatch(fetchSinchCredentialsFailure());
    dispatch(testSinchCredentialsFailure());
  } else {
    const body = await response.json();
    dispatch(fetchSinchCredentialsSuccess(body));
    dispatch(testSinchCredentials(body.status));
  }
};

export const testSinchCredentials = status => async dispatch => {
  if (status === undefined) {
    dispatch(testSinchCredentialsFailure());
  } else {
    dispatch(testSinchCredentialsSuccess());
  }
};

export const fetchTelusCredentials = () => async dispatch => {
  dispatch(fetchTelusCredentialsRequest());

  const response = await fetch(API_VERSION + "credentials/telus", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status !== 200) {
    dispatch(fetchTelusCredentialsFailure());
    dispatch(testTelusCredentialsFailure());
  } else {
    const body = await response.json();
    dispatch(fetchTelusCredentialsSuccess(body));
    dispatch(testTelusCredentials(body.credentialsStatus));
  }
};

export const testTelusCredentials = credentialsStatus => async dispatch => {
  if (credentialsStatus === "valid") {
    dispatch(testTelusCredentialsSuccess());
  } else {
    dispatch(testTelusCredentialsFailure());
  }
};

const fetchUrls = () => {
  return fetch(API_VERSION + "webhookurl", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  }).then(response =>
    response ? Promise.all([response, response.json()]) : null
  );
};

const validUrl = url => {
  const containsHttp = url.includes("http://") || url.includes("https://");
  const containsWww = url.includes("www.");

  if (containsHttp && containsWww) {
    if (url.includes(".", 13) && url.lastIndexOf(".") !== url.length - 1)
      return true;
    return false;
  } else if (containsHttp) {
    if (url.includes(".", 10) && url.lastIndexOf(".") !== url.length - 1)
      return true;
    return false;
  } else if (containsWww) {
    if (url.includes(".", 5) && url.lastIndexOf(".") !== url.length - 1)
      return true;
    return false;
  } else {
    if (url.includes(".") && url.lastIndexOf(".") !== url.length - 1)
      return true;
  }

  return false;
};

const urlActions = {
  fetchUrlsWithRedux,
  fetchUrlsSuccess,
  fetchUrlsError,
  fetchUrlsRequest
};

export { urlActions };
