export const LOAD_TREE = 'LOAD_TREE'
export const GET_REQUEST = 'GET_FLOWCHARTS_REQUEST'
export const GET_SUCCESS = 'GET_FLOWCHARTS_SUCCESS'
export const GET_FAILURE = 'GET_FLOWCHARTS_FAILURE'
export const CREATE_REQUEST = 'CREATE_FLOWCHARTS_REQUEST'
export const CREATE_SUCCESS = 'CREATE_FLOWCHARTS_SUCCESS'
export const CREATE_FAILURE = 'CREATE_FLOWCHARTS_FAILURE'
export const UPDATE_REQUEST = 'UPDATE_FLOWCHARTS_REQUEST'
export const UPDATE_SUCCESS = 'UPDATE_FLOWCHARTS_SUCCESS'
export const UPDATE_FAILURE = 'UPDATE_FLOWCHARTS_FAILURE'
export const DELETE_REQUEST = 'DELETE_FLOWCHARTS_REQUEST'
export const DELETE_SUCCESS = 'DELETE_FLOWCHARTS_SUCCESS'
export const DELETE_FAILURE = 'DELETE_FLOWCHARTS_FAILURE'
export const SET_FLOWCHART = 'SET_FLOWCHART'
export const LOGOUT = 'USER_LOGOUT'