import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { munvoBlue } from "../utils/globalStyles";
import Divider from "material-ui/Divider";

function validateDigits(campaignID) {
  var nonDigitChar;

  for (var i = 0; i < campaignID.length; i++) {
    if (isNaN(campaignID.charAt(i)) || campaignID.charAt(i) === " ") {
      nonDigitChar = campaignID.charAt(i);
      break;
    }
  }

  return nonDigitChar;
}

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

let FlowchartForm = (props) => {
  const { handleSubmit, flowchartJSONWebhooks, existingWebhooks } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Field
            component={renderTextField}
            label="Name"
            name="name"
            placeholder="New Flowchart"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={renderTextField}
            label="From Number"
            name="campaignID"
            placeholder="12222"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={renderTextField}
            label="JSON(optional)"
            name="json"
            placeholder="Paste the JSON here if you have an existing flowchart to import!"
            minRows={5}
            maxRows={5}
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {flowchartJSONWebhooks.length > 0 ? "Select the webhook replacement" : ""}
        </Grid>
        <Grid item xs={12}>
          {flowchartJSONWebhooks.map((item) => {
            const [selectedWebhookId, setSelectedWebhookId] = useState(null);
            return (
              <Grid item xs={12}>
                {item}
                < Field
                  name={item.replace(/\./g, "_")}
                  component="select"
                  value={selectedWebhookId ? selectedWebhookId : null}
                  onChange={(event) => { setSelectedWebhookId(event.target.value); }}
                >
                  <option value={null} label="" />
                  <option value={-1} label="None" />
                  {
                    existingWebhooks.map(option => (
                      <option key={option.id} value={option.id} label={option.url + " (" + option.contentType.toUpperCase() + ")"} />
                    ))
                  }
                </Field>
              </Grid>);
          }
          )

          }
        </Grid>
      </Grid>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.campaignID) {
    errors.campaignID = "Required";
  } else {
    var nonDigitChar = validateDigits(values.campaignID);

    if (nonDigitChar) {
      errors.campaignID = "Invalid character '" + nonDigitChar + "' found";
    }
  }

  return errors;
};

FlowchartForm = reduxForm({
  form: "flowchartform", // a unique identifier for this form,
  validate,
  onSubmit: (values, dispatch, props) => {
    props.submitCallback(values);
  },
})(FlowchartForm);

export default FlowchartForm;
