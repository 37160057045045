import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import { API_VERSION } from "../../utils/Constants";

import * as Cookies from "js-cookie";

const templateRequest = createAction(Types.TEMPLATE_REQUEST);
const templateSuccess = createAction(
  Types.TEMPLATE_SUCCESS,
  payload => payload
);
const templateFailure = createAction(Types.TEMPLATE_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const getTemplates = () => async dispatch => {
  dispatch(templateRequest());
  const response = await fetch(API_VERSION + "template", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(templateFailure());
  } else {
    const body = await response.json();
    dispatch(templateSuccess(body));
  }
};

export const postTemplate = newTemplate => async dispatch => {
  dispatch(templateRequest());
  const response = await fetch(API_VERSION + "template", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(newTemplate)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(templateFailure());
  } else {
    const body = await response.json();
    dispatch(templateSuccess(body));
  }
};

export const putTemplate = editTemplate => async dispatch => {
  dispatch(templateRequest());
  const response = await fetch(API_VERSION + "template", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(editTemplate)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(templateFailure());
  } else {
    const body = await response.json();
    dispatch(templateSuccess(body));
  }
};

export const deleteTemplate = id => async dispatch => {
  dispatch(templateRequest());
  const response = await fetch(API_VERSION + "template/" + id, {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(templateFailure());
  } else {
    const body = await response.json();
    dispatch(templateSuccess(body.templates));
  }
};
