import { munvoOrange } from '../utils/globalStyles'

export const loginFormStyles = (theme) => ({
  root:{
    paddingTop: '150px'
  },
  card:{
    margin: '0 auto',
    width: '25%'
  },
  textCenter:{
    textAlign: 'center',
    paddingTop: '10px'
  },
  button:{
    margin: theme.spacing()
  },
  footerText:{
    color: 'red',
    fontWeight: 'bold',
    float: 'right'
  },
  buttonLocation:{
    display: 'block',
    textAlign: 'center'
  },
  checkbox:{
    color: munvoOrange,
    '&$checked': {
      color: munvoOrange
    }
  },
  checked: {}
})