import React from "react";
import { connect } from "react-redux";
import { fetchTransactionsByDates, fetchTransactionErrorByTransactionId, fetchNodeDetailsByNodeId } from "../reducers";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import CircularIndeterminate from "../CircularLoading";
import DateFnsUtils from "@date-io/date-fns";
import { validateTimezone, toRequestTimestamp, apiToLocalTimezone } from "./TablesUtils"
import { MuiPickersUtilsProvider, DateTimePicker } from "material-ui-pickers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  },
  tableCell90: {
    overflow: "auto",
    width: "90px",
    display: "block"
  },
  tableCell120: {
    overflow: "auto",
    width: "120px",
    display: "block"
  },
  expandedRow: {
    maxWidth: "calc(95vw - 46px)",
    paddingLeft: "46px",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: munvoOrange
      }
    }
  }
});

function createData(
  transactionID,
  phoneNumber,
  customerResponse,
  serverResponse,
  time,
  lastModified,
  messageSid,
  broadLogId,
  campaignId,
  status,
  nodeName,
  nodeID,
  batchID,
  sessionID
) {
  return {
    transactionID,
    phoneNumber,
    customerResponse,
    serverResponse,
    time,
    lastModified,
    messageSid,
    broadLogId,
    campaignId,
    status,
    nodeName,
    nodeID,
    batchID,
    sessionID
  };
}

let rows = [];
let sortedRows = [];
let columnsQueries = [];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  sortedRows = stabilizedThis.map(el => el[0]);
  return sortedRows;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: "", label: "", width: "0px" }, // Button to expand/collapse row
  { id: "transactionID", label: "ID", width: "100px" },
  { id: "phoneNumber", label: "Phone", width: "100px" },
  { id: "customerResponse", label: "CustomerReply", width: "100px" },
  { id: "serverResponse", label: "ServerReply", width: "100px" },
  { id: "time", label: "TimeCreated", width: "100px" },
  { id: "lastModified", label: "LastModified", width: "100px" },
  { id: "messageSid", label: "MsgSid", width: "100px" },
  { id: "broadLogId", label: "broadlogid", width: "100px" },
  { id: "campaignId", label: "campaignId", width: "100px" },
  { id: "status", label: "Status", width: "100px" },
  { id: "nodeName", label: "NodeName", width: "100px" },
  { id: "nodeID", label: "NodeID", width: "100px" },
  { id: "batchID", label: "batchID", width: "100px" },
  { id: "sessionID", label: "SessionID", width: "100px" }

];

function TransactionTableHead(props) {
  const { order, orderBy, onRequestSort, callbackFilter } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const handleColumnQueryChange = rowId => event => {
    if (columnsQueries.find(x => x.column === rowId) !== undefined) {
      columnsQueries.find(x => x.column === rowId).query = event.target.value;
    } else {
      columnsQueries.push({ column: rowId, query: event.target.value });
    }
    callbackFilter();
  };
  return (
    <TableHead padding="5px">
      {headRows.map(row => (
        <TableCell
          key={row.id}
          align={"center"}
          sortDirection={orderBy === row.id ? order : false}
        />
      ))}
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={"center"}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <TextField
                style={{ width: row.width, marginLeft: "20px" }}
                label={row.label}
                placeholder="Filter"
                value={
                  columnsQueries.find(x => x.column === row.id) !== undefined
                    ? columnsQueries.find(x => x.column === row.id).query
                    : ""
                }
                onChange={handleColumnQueryChange(row.id)}
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
TransactionTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const TransactionTableToolbar = props => {
  return (
    <Toolbar>
      <Typography variant="h5" id="tableTitle">
        <h5>Transaction History</h5>
      </Typography>
    </Toolbar>
  );
};

const TransactionTable = props => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("NodeID");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [numberOfTransactions, setnumberOfTransactions] = React.useState(25);
  const [dateFrom, handleFromDateChange] = React.useState(
    setdefaultDate("from")
  );
  const [dateTo, handleToDateChange] = React.useState(setdefaultDate("to"));
  const [timezone, setTimezone] = React.useState("America/Toronto");
  const [broadLogId, setBroadLogId] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [campaign, setCampaign] = React.useState(null);
  const [isValidTimezone, setIsValidTimezone] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  const [expandedRows, setExpandedRows] = React.useState({});
  const [transactionErrorIsLoading, setTransactionErrorIsLoading] = React.useState({});
  const [transactionErrorRowsData, setTransactionErrorRowsData] = React.useState({});
  const [nodeDetailsIsLoading, setNodeDetailsIsLoading] = React.useState({});
  const [nodeDetailsRowsData, setNodeDetailsRowsData] = React.useState({});

  //set the default date filter to today
  function setdefaultDate(type) {
    let temp = new Date();
    if (type === "from") {
      temp.setHours(0, 0, 0);
    } else if (type === "to") {
      temp.setHours(23, 59, 59);
    }
    return temp;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  //if the expression is undefined return an empty string
  //else return the expression
  function notUndefined(expression) {
    if (expression !== undefined) {
      return expression;
    }
    return "";
  }

  //if x contains one of the queries {column: "", query:""} return true
  //constraint on columns are joined by and OR
  const filterFunction = x => {
    for (let i = 0; i < columnsQueries.length; i++) {
      if (
        x[columnsQueries[i].column] !== undefined &&
        columnsQueries[i].query !== "" &&
        !x[columnsQueries[i].column]
          .toString()
          .toLowerCase()
          .includes(columnsQueries[i].query.toLowerCase())
      ) {
        return false;
      }
    }
    return true;
  };

  // Update rows based on the store
  function updateRows() {
    rows = [];
    let currentTransactionList = props.transactionList;
    if (currentTransactionList !== undefined) {
      for (let i = 0; i < currentTransactionList.length; i++) {
        const currentTransaction = currentTransactionList[i];
        const currentTransactionId = currentTransaction.id;
        rows.push(
          createData(
            notUndefined(currentTransactionId),
            notUndefined(currentTransaction.phone),
            notUndefined(currentTransaction.userMsg),
            notUndefined(currentTransaction.systemResponse),
            notUndefined(apiToLocalTimezone(currentTransaction.createdTimestamp, timezone)),
            notUndefined(apiToLocalTimezone(currentTransaction.lastModified, timezone)),
            notUndefined(currentTransaction.messageSid),
            notUndefined(currentTransaction.broadlogID),
            notUndefined(parseInt(currentTransaction.campaignID, 10)),
            notUndefined(currentTransaction.status),
            notUndefined(currentTransaction.nodeName),
            notUndefined(currentTransaction.nodeId),
            notUndefined(notUndefined(currentTransaction.batchId).id),
            notUndefined(currentTransaction.sessionId).id
          )
        );

        if (expandedRows[currentTransactionId]) {
          // If the row is expanded

          const lastFetchedTransactionError = props.transactionError;
          if (!transactionErrorRowsData[currentTransactionId] && lastFetchedTransactionError && lastFetchedTransactionError.transaction && lastFetchedTransactionError.transaction.id == currentTransactionId) {
            if (Object.keys(lastFetchedTransactionError).length > 0) {
              // Fetched transaction error from backend exists
              transactionErrorRowsData[currentTransactionId] = {
                id: Number.isInteger(lastFetchedTransactionError.id) ? "" + lastFetchedTransactionError.id : "—",
                errorcode: Number.isInteger(lastFetchedTransactionError.errorcode) ? "" + lastFetchedTransactionError.errorcode : "—",
                errormessage: lastFetchedTransactionError.errormessage || "—",
                httpcode: Number.isInteger(lastFetchedTransactionError.httpcode) ? "" + lastFetchedTransactionError.httpcode : "—",
                ecreated: lastFetchedTransactionError.created || "—",
                // transaction: lastFetchedTransactionError.transaction || "—",
              };
            } else {
              // Fetched transaction error does not exist/returned empty
              transactionErrorRowsData[currentTransactionId] = {}; // = lastFetchedTransactionError
            }
          }

          const lastFetchedNodeDetails = props.nodeDetails;
          if (!nodeDetailsRowsData[currentTransactionId] && lastFetchedNodeDetails) {
            if (Object.keys(lastFetchedNodeDetails).length > 0) {
              // Fetched node from backend successfully
              nodeDetailsRowsData[currentTransactionId] = {
                // id: Number.isInteger(lastFetchedNodeDetails.id) ? "" + lastFetchedNodeDetails.id : "—",
                reply: lastFetchedNodeDetails.reply || "—",
                // parent: lastFetchedNodeDetails.parent || "—",
                customerResponse: lastFetchedNodeDetails.customerResponse || "—",
                priority: Number.isInteger(lastFetchedNodeDetails.priority) ? "" + lastFetchedNodeDetails.priority : "—",
                updatedTimestamp: lastFetchedNodeDetails.updatedTimestamp || "—",
                name: lastFetchedNodeDetails.name || "—",
                nodeDetails: lastFetchedNodeDetails.nodeDetails || "—",
                webhookEnabled: Number.isInteger(lastFetchedNodeDetails.webhookEnabled) ? "" + lastFetchedNodeDetails.webhookEnabled : "—",
                webhookUrlId: Number.isInteger(lastFetchedNodeDetails.webhookUrlId) ? "" + lastFetchedNodeDetails.webhookUrlId : "—",
                portalMode: lastFetchedNodeDetails.portalMode || "—",
                targetNode: lastFetchedNodeDetails.targetNode || "—",
                useRegex: lastFetchedNodeDetails.hasOwnProperty("useRegex") ? lastFetchedNodeDetails.useRegex.toString() : "—"
              };
            } else {
              // No node fetched/returned empty (this should only happen if the node ID is negative)
              nodeDetailsRowsData[currentTransactionId] = {}; // = lastFetchedNodeDetails
            }
          }

        } else {
          // If the row is collapsed (false) or uninitialized (undefined | null)
          expandedRows[currentTransactionId] = false;
        }

      }
    }
  }

  function handleNumberOfTransactionsChange(e) {
    setnumberOfTransactions(e.target.value);
  }

  function callbackFilter() {
    setToggle(!toggle);
  }

  async function dispatchFetchRequest() {
    if (!validateTimezone(timezone)) { return; }
    setIsLoading(true);
    await props.dispatch(
      fetchTransactionsByDates(
        toRequestTimestamp(dateFrom, timezone),
        toRequestTimestamp(dateTo, timezone),
        numberOfTransactions,
        broadLogId,
        phone,
        campaign
      )
    );
    setIsLoading(false);
  }

  async function dispatchFetchRequestTransactionError(tid) {
    setTransactionErrorIsLoading(prevState => ({...prevState, [tid]: true}));
    await props.dispatch(
      fetchTransactionErrorByTransactionId(
        tid
      )
    );
    setTransactionErrorIsLoading(prevState => ({...prevState, [tid]: false}));
  }

  async function dispatchFetchRequestNodeDetails(tid, nid) {
    setNodeDetailsIsLoading(prevState => ({...prevState, [tid]: true}));
    await props.dispatch(
      fetchNodeDetailsByNodeId(
        nid
      )
    );
    setNodeDetailsIsLoading(prevState => ({...prevState, [tid]: false}));
  }

  function handleRowExpansionAndCollapse(tid, nid) {
    setExpandedRows(prevState => ({...prevState, [tid]: !prevState[tid]}));
  
    if (!transactionErrorRowsData[tid]) {
      // Data has not been cached or fetched from backend before
      dispatchFetchRequestTransactionError(tid);
      setTransactionErrorRowsData(prevState => ({...prevState}));
    }

    if (!nodeDetailsRowsData[tid]) {
      // Data has not been cached or fetched from backend before
      if (nid) {
        dispatchFetchRequestNodeDetails(tid, nid);
        setNodeDetailsRowsData(prevState => ({...prevState}));
      } else {
        // No node associated with transaction
        setNodeDetailsRowsData(prevState => ({...prevState, [tid]: {}}));
      }
    }
  }

  // Ensure scrollbar appears/table is scrollable
  const refPaperTable = React.useRef(null);
  React.useEffect(() => {
    document.body.style.overflow = "auto";
    refPaperTable.current.style.maxHeight = "calc(100vh - 196px)";
    return () => {
      document.body.style.overflow = "";
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <CircularIndeterminate isLoading={isLoading} />
      <div>
        <Paper style={{ overflow: "auto" }} ref={refPaperTable}>
          <div align="center">
            <Grid container style={{ marginTop: "20px" }} spacing={24}>
              <Grid item xs={13}>
                <TransactionTableToolbar />
              </Grid>
              <Grid container spacing={2}>

                <Grid item xs={13}>
                  <Tooltip
                    title="*Large number may take longer to query"
                    placement="top-start"
                  >
                    <TextField
                      select
                      style={{
                        width: "120px",
                        margin: "10px",
                        marginRight: "30px"
                      }}
                      label="Number of Rows"
                      value={numberOfTransactions}
                      onFocus={updateRows()}
                      onChange={handleNumberOfTransactionsChange}
                    >
                      {[
                        {
                          value: "5",
                          label: "5"
                        },
                        {
                          value: "10",
                          label: "10"
                        },
                        {
                          value: "25",
                          label: "25"
                        },
                        {
                          value: "50",
                          label: "50"
                        },
                        {
                          value: "100",
                          label: "100"
                        },
                        {
                          value: "500",
                          label: "500"
                        }
                      ].map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      style={{ padding: "10px", width: 180 }}
                      id="From"
                      label="From"
                      allowKeyboardControl={true}
                      ampm={false}
                      format="yyyy-MM-dd   HH:mm"
                      value={dateFrom}
                      onChange={handleFromDateChange}
                      showTodayButton
                    />
                    <DateTimePicker
                      style={{ padding: "10px", width: 180 }}
                      id="To"
                      label="To"
                      ampm={false}
                      format="yyyy-MM-dd   HH:mm"
                      value={dateTo}
                      onChange={handleToDateChange}
                      showTodayButton
                    />
                  </MuiPickersUtilsProvider>
                  <TextField id="broadlog_id"
                    label="Broadlog ID"
                    variant="standard"
                    value={broadLogId}
                    style={{
                      width: "160px",
                      margin: "10px",
                      marginRight: "30px"
                    }}
                    onChange={event => { setBroadLogId(event.target.value); }}
                  />
                  <TextField id="phone"
                    label="Phone"
                    variant="standard"
                    value={phone}
                    style={{
                      width: "160px",
                      margin: "10px",
                      marginRight: "30px"
                    }}
                    onChange={event => { setPhone(event.target.value); }}
                  />
                  <TextField id="campaign"
                    label="Campaign"
                    variant="standard"
                    value={campaign}
                    style={{
                      width: "160px",
                      margin: "10px",
                      marginRight: "30px"
                    }}
                    onChange={event => { setCampaign(event.target.value); }}
                  />
                </Grid>

                <Grid item xs={13}>
                  <TextField id="timezone"
                    label="Timezone (IANA)"
                    variant="filled"
                    helperText="Please use IANA format such as 'America/Toronto'"
                    defaultValue="America/Toronto"
                    value={timezone}
                    onChange={event => { setIsValidTimezone(validateTimezone(event.target.value)); setTimezone(event.target.value); }}
                    error={!isValidTimezone}
                  />
                </Grid>
                <Grid item xs={13}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      padding: "5px",
                      background: munvoOrange,
                      marginLeft: "20px"
                    }}
                    onClick={dispatchFetchRequest}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            <Table aria-labelledby="tableTitle" width="100%" stickyHeader>
              <TransactionTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                callbackFilter={callbackFilter}
              />

              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .filter(filterFunction)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const rowTid = row.transactionID;
                    return (
                      <React.Fragment>
                        <TableRow hover key={row.name}>
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => handleRowExpansionAndCollapse(rowTid, row.nodeID)}>
                              {expandedRows[rowTid] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell align="center" key="id">
                            <span style={theme.tableCell90}>
                              {rowTid}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="phoneNumber">
                            <span style={theme.tableCell90}>
                              {row.phoneNumber}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="customerResponse">
                            <span style={theme.tableCell90}>
                              {row.customerResponse}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="serverResponse">
                            <span style={theme.tableCell120}>
                              {row.serverResponse}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="timeCreated">
                            <span style={theme.tableCell90}>
                              {row.time}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="lastModified">
                            <span style={theme.tableCell90}>
                              {row.lastModified}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="messageSid">
                            <span style={theme.tableCell90}>
                              {row.messageSid}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="broadlogId">
                            <span style={theme.tableCell90}>
                              {row.broadLogId}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="campaignId">
                            <span style={theme.tableCell90}>
                              {row.campaignId}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="status">
                            <span style={theme.tableCell90}>
                              {row.status}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="nodeName">
                            <span style={theme.tableCell90}>
                              {row.nodeName}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="nodeId">
                            <span style={theme.tableCell90}>
                              {row.nodeID}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="batchId">
                            <span style={theme.tableCell90}>
                              {row.batchID}
                            </span>
                          </TableCell>
                          <TableCell align="center" key="sessionId">
                            <span style={theme.tableCell90}>
                              {row.sessionID}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow id="transaction-details">
                          <TableCell colSpan={15} style={{ padding: 0 }}>
                            <Collapse in={expandedRows[rowTid]} timeout="auto" unmountOnExit>
                              <Box margin={1} marginTop={3} marginBottom={3} style={theme.expandedRow}>
                                <Typography variant="h6" gutterBottom component="div">
                                  Transaction Error
                                </Typography>
                                {
                                  transactionErrorIsLoading[rowTid]
                                  ?
                                  <CircularProgress />
                                  :
                                  transactionErrorRowsData[rowTid] && Object.keys(transactionErrorRowsData[rowTid]).length > 0
                                  ?
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow key="transaction-error-header">
                                        <TableCell align="center">Error ID</TableCell>
                                        <TableCell align="center">Error Code</TableCell>
                                        <TableCell align="center">HTTP Code</TableCell>
                                        <TableCell align="center">Time Created</TableCell>
                                        <TableCell align="center">Error Message</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key="transaction-error-row-values">
                                        <TableCell align="center" component="th" scope="row">{transactionErrorRowsData[rowTid].id}</TableCell>
                                        <TableCell align="center">{transactionErrorRowsData[rowTid].errorcode}</TableCell>
                                        <TableCell align="center">{transactionErrorRowsData[rowTid].httpcode}</TableCell>
                                        <TableCell align="center">{transactionErrorRowsData[rowTid].ecreated}</TableCell>
                                        <TableCell align="center">{transactionErrorRowsData[rowTid].errormessage}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  :
                                  <Typography variant="body2" style={{ padding: "0.75em" }} gutterBottom component="div">
                                    No transaction error found.
                                  </Typography>
                                }
                              </Box>
                              <Box margin={1} marginTop={3} marginBottom={3} style={theme.expandedRow}>
                                <Typography variant="h6" gutterBottom component="div">
                                  Node Details
                                </Typography>
                                {
                                  nodeDetailsIsLoading[rowTid]
                                  ?
                                  <CircularProgress />
                                  :
                                  nodeDetailsRowsData[rowTid] && Object.keys(nodeDetailsRowsData[rowTid]).length > 0
                                  ?
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow key="node-details-header">
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Reply</TableCell>
                                        <TableCell align="center">Customer Response</TableCell>
                                        <TableCell align="center">Priority</TableCell>
                                        <TableCell align="center">Last Updated Time</TableCell>
                                        <TableCell align="center">Node Details</TableCell>
                                        <TableCell align="center">Webhook Enabled</TableCell>
                                        <TableCell align="center">Webhook URL ID</TableCell>
                                        <TableCell align="center">Portal Mode</TableCell>
                                        <TableCell align="center">Target Node</TableCell>
                                        <TableCell align="center">Uses Regex</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key="node-details-row-values">
                                        <TableCell align="center" component="th" scope="row">{nodeDetailsRowsData[rowTid].name}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].reply}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].customerResponse}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].priority}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].updatedTimestamp}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].nodeDetails}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].webhookEnabled}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].webhookUrlId}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].portalMode}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].targetNode}</TableCell>
                                        <TableCell align="center">{nodeDetailsRowsData[rowTid].useRegex}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  :
                                  <Typography variant="body2" style={{ padding: "0.75em" }} gutterBottom component="div">
                                    No associated node found.
                                  </Typography>
                                }
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </MuiThemeProvider>
  );
};
const mapStateToProps = state => {
  return {
    transactionList: state.tables.transactionList,
    transactionError: state.tables.transactionError,
    nodeDetails: state.tables.nodeDetails,
  };
};
export default connect(mapStateToProps)(TransactionTable);
