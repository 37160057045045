import React, { Component } from 'react'
import { TextField } from 'redux-form-material-ui'
import { Field } from 'redux-form'
import { munvoBlue } from '../utils/globalStyles'

class LinkedCustomField extends Component {
  constructor(props){
    super(props)

    this.state = {
      length: 0,
      initialized: false,
      reinitialize: false,
      charLimit: 160
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.reinitialize){
      this.setState({ initialized: false, reinitialize: false })
    }
  }

  handleChange(e){
    this.setState({ length: e.target.value.length })
  }

  render(){
    const { label, name } = this.props
    return(
      <Field
        component={TextField}
        onChange={this.handleChange}
        className='linkedRepliesTextarea'
        floatingLabelText={label + ' - Characters: ' + this.state.length + '/' + this.state.charLimit}
        name={name}
        hintText='Reply with YYZ for more offers!'
        multiLine={true}
        rows={1}
        rowsMax={10}
        underlineStyle={{ borderColor: munvoBlue }}
        underlineFocusStyle={{ borderColor: munvoBlue }}
        floatingLabelFocusStyle={{ color: munvoBlue }}
      />
    )
  }
}

export default LinkedCustomField