import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const userRequest = createAction(Types.USER_REQUEST);
const userFailure = createAction(Types.USER_FAILURE);
const userSuccess = createAction(Types.USER_SUCCESS, users => users);
const forceLogout = createAction(Types.LOGOUT);

export const getUsers = () => async dispatch => {
  dispatch(userRequest());
  const response = await fetch(API_VERSION + "user/list", {
    method: "GET",
    credentails: "same-orgin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(userFailure());
  } else {
    const body = await response.json();
    dispatch(userSuccess(body.userList));
  }
};

export const postUser = newUser => async dispatch => {
  dispatch(userRequest());
  const response = await fetch(API_VERSION + "user/newuser", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(newUser)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(userFailure());
  } else {
    const body = await response.json();
    dispatch(userSuccess(body.userList));
  }
};

export const putUser = editUser => async dispatch => {
  dispatch(userRequest());
  const response = await fetch(API_VERSION + "user/update", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(editUser)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(userFailure());
  } else {
    const body = await response.json();
    dispatch(userSuccess(body.userList));
  }
};

export const deleteUser = queryParams => async dispatch => {
  dispatch(userRequest());
  const response = await fetch(
    API_VERSION + "user/delete?".concat(queryParams),
    {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + getJwtToken()
      }
    }
  );

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(userFailure());
  } else {
    const body = await response.json();
    dispatch(userSuccess(body.userList));
  }
};
