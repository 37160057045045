import React, { Component } from "react";
import { connect } from "react-redux";
import { List, ListItem } from "material-ui/List";
import {
  Dialog,
  AppBar,
  Drawer,
  TextField as TxtField,
  FloatingActionButton
} from "material-ui";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CommunicationCallSplit from "material-ui/svg-icons/communication/call-split";
import NavigationArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import {
  ActionDelete,
  ContentSave,
  EditorModeEdit
} from "material-ui/svg-icons";
import ContentAdd from "material-ui/svg-icons/content/add";
import classnames from "classnames";
import HeaderBar from "../Header";
import {
  getTemplates,
  postTemplate,
  deleteTemplate,
  putTemplate
} from "../reducers";
import { FindTemplateTooltip } from "../utils/Tooltip";
import "../App.css";
import { munvoBlue, munvoGray } from "../utils/globalStyles";
import logo from "../logo_munvo_picto-h_inverse.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";

function templateNameIsValid(templates, name, id) {
  if (name === undefined && id === undefined) return true; //skip the first renders when there are no loaded templates

  for (let i = 0; i < templates.length; i++) {
    if (name === templates[i].name && id !== templates[i].id) {
      return false;
    }
  }

  if (name === null || name.trim() === "") {
    return false;
  }

  return true;
}

class TemplateMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      loadedTemplate: "",
      loadedTemplateOriginalName: "",
      loadedTemplateOriginalBody: "",
      loadedPayload: "",
      loadedExample: "",
      openDialogDel: false,
      copied: false,
      emptyNameError: "",
      emptyBodyError: false,
      edit: false
    };

    this.loadTemplate = this.loadTemplate.bind(this);
    this.loadEmptyTemplate = this.loadEmptyTemplate.bind(this);
    this.handleOpenDialogDel = this.handleOpenDialogDel.bind(this);
    this.handleCloseDialogDel = this.handleCloseDialogDel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePayloadOnBlur = this.handlePayloadOnBlur.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getTemplates());
  }

  filterTemplates(event) {
    this.setState({ searchString: event.target.value });
  }

  loadTemplate(template) {

    //if the template name of the current loaded template is invalid, revert it back to its original valid name
    if (!templateNameIsValid(this.props.templates, this.state.loadedTemplate.name, this.state.loadedTemplate.id)) {
      var templateWithPreviousName = this.state.loadedTemplate;
      templateWithPreviousName.name = this.state.loadedTemplateOriginalName;

      this.setState({ loadedTemplate: templateWithPreviousName });
    }

    //same for the body
    const body = this.state.loadedTemplate.body;

    if (body !== undefined && (body === null || body.trim() === "")) {
      var templateWithPreviousBody = this.state.loadedTemplate;
      templateWithPreviousBody.body = this.state.loadedTemplateOriginalBody;

      this.setState({ loadedTemplate: templateWithPreviousBody });
    }

    //then load the template the user wants to load
    var payload = this.generateXMLPayload(template);

    this.setState({
      loadedTemplate: template,
      loadedTemplateOriginalName: template.name,
      loadedTemplateOriginalBody: template.body,
      edit: false,
      loadedPayload: payload,
      emptyNameError: "",
      emptyBodyError: false,
      loadedExample: this.generateSMSExample(payload, template.body)
    });
  }

  loadEmptyTemplate = () => {
    const emptyTemplate = {};
    emptyTemplate.id = "";
    emptyTemplate.name = "";
    emptyTemplate.body = "";

    this.setState({ loadedTemplate: emptyTemplate });
    this.setState({ edit: true });
    this.setState({ loadedPayload: " " });
    this.setState({ loadedExample: " " });
  };

  handleOpenDialogDel = () => {
    this.setState({ openDialogDel: true });
  };

  handleCloseDialogDel = () => {
    this.setState({ openDialogDel: false });
  };

  handleDelete = () => {
    this.props.dispatch(deleteTemplate(this.state.loadedTemplate.id));
    this.handleCloseDialogDel();
    this.loadEmptyTemplate();
  };

  handleEdit = () => this.setState({ edit: true });

  handleSubmit = () => {
    const name = this.state.loadedTemplate.name;
    const id = this.state.loadedTemplate.id;
    const body = this.state.loadedTemplate.body;

    if (!templateNameIsValid(this.props.templates, name, id)) {
      this.setState({ emptyNameError: "Invalid template name - must be unique and non-empty" });
      return;
    }

    if (body === null || body.trim() === "") {
      this.setState({ emptyBodyError: true });
    }
    else {
      if (
        !(
          this.state.loadedTemplate.id === undefined ||

          this.state.loadedTemplate.id === ""
        )
      ) {
        const payload = this.generateXMLPayload(this.state.loadedTemplate);

        this.props.dispatch(putTemplate(this.state.loadedTemplate));

        this.setState({ loadedPayload: payload });
        this.setState({
          loadedExample: this.generateSMSExample(
            payload,
            this.state.loadedTemplate.body
          )
        });
      } else {
        const newTemplate = {};
        newTemplate.name = this.state.loadedTemplate.name;
        newTemplate.body = this.state.loadedTemplate.body;
        newTemplate.deleted = false;
        this.props.dispatch(postTemplate(newTemplate));

        const payload = this.generateXMLPayload(newTemplate);

        this.setState({ loadedPayload: payload });
        this.setState({
          loadedExample: this.generateSMSExample(payload, newTemplate.body)
        });

        this.loadEmptyTemplate();
      }

      this.setState({ edit: false });
    }
  };

  handleCopy() {
    this.setState({ copied: true });
  }

  handlePayloadOnBlur() {
    this.setState({ copied: false });
  }

  generateXMLPayload(template) {
    if (template === null || template.name === "") return " ";

    const smsBody = template.body;
    const regex = /<%=\w+%>/g;
    var matches;

    var payload =
      "<message>" +
      "\n\t<phoneTo>value</phoneTo>" +
      "\n\t<phoneFrom>value</phoneFrom>";

    var counter = 1;

    while ((matches = regex.exec(smsBody)) !== null) {
      payload +=
        "\n\t<attributes>" +
        "\n\t\t<key>" +
        matches[0].replace(/<%=|%>/g, "") +
        "</key>" +
        "\n\t\t<value>value" +
        counter++ +
        "</value>" +
        "\n\t</attributes>";
    }

    payload += "\n</message>";

    return payload;
  }

  generateSMSExample(payload, body) {
    if (payload === null || payload === "") return " ";

    const parser = new DOMParser();
    const xmlObj = parser.parseFromString(payload, "text/xml");
    const values = xmlObj.getElementsByTagName("value");
    var counter = 0;

    var smsBody = body;
    const regex = /<%=\w+%>/g;
    var matches;

    while ((matches = regex.exec(body)) !== null) {
      matches[0].replace(/<%=|%>/g, "")

      if (
        values[counter] !== undefined &&
        values[counter].childNodes[0] !== undefined &&
        values[counter].childNodes[0].nodeValue !== undefined
      ) {
        smsBody = smsBody.replace(
          matches[0],
          values[counter].childNodes[0].nodeValue
        );
        counter++;
      }
    }

    return smsBody;
  }

  changeText = e => {
    const newTemplate = this.state.loadedTemplate;
    var newPayload = this.state.loadedPayload;

    if (e.target.name === "name") {
      newTemplate.name = e.target.value;
      this.setState({ emptyNameError: "" });
    } else if (e.target.name === "body") {
      newTemplate.body = e.target.value;
      this.setState({ emptyBodyError: false });
    } else if (e.target.name === "payload") {
      newPayload = e.target.value;

      this.setState({ loadedPayload: newPayload });
      this.setState({
        loadedExample: this.generateSMSExample(newPayload, newTemplate.body)
      });
    }

    this.setState({ loadedTemplate: newTemplate });
  };

  render() {
    const title = "SMS Gateway - Templates";

    const { templates } = this.props;

    if (this.state.loadedTemplate === "" && templates.length > 0)
      this.loadTemplate(templates[0]);

    let filteredTemplates = templates.filter(template => {
      return template.name.includes(this.state.searchString);
    });

    const templateMenuItems = filteredTemplates.map((template, key) => (
      <ListItem
        key={key}
        primaryText={template.name}
        leftIcon={<NavigationArrowForward />}
        onClick={() => this.loadTemplate(template)}
      />
    ));

    templateMenuItems.push(
      <ListItem
        key={filteredTemplates.length}
        primaryText="New template"
        leftIcon={<ContentAdd />}
        onClick={() => this.loadEmptyTemplate()}
      />
    );

    const DeleteTemplateDialog = () => (
      <Dialog
        actions={[
          <Button
            style={{ margin: "5px" }}
            variant="outlined"
            onClick={this.handleCloseDialogDel}
          >
            Cancel
          </Button>,
          <Button
            style={{ margin: "5px" }}
            variant="outlined"
            onClick={this.handleDelete}
          >
            Submit
          </Button>
        ]}
        open={this.state.openDialogDel}
        onRequestClose={this.handleCloseDialogDel}
      >
        Delete this template?
      </Dialog>
    );

    return (
      <div className="App">
        <HeaderBar pageTitle={title} />
        <Drawer docked={true} open={true}>
          <div>
            <AppBar
              showMenuIconButton={false}
              style={{ background: munvoBlue }}
              title={
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "95%" }} />
                </Link>
              }
            />
            <TxtField
              data-tip
              data-for="search-template-tt"
              style={{ textIndent: 30 }}
              hintText="Find a Template"
              value={this.state.searchString}
              onChange={this.filterTemplates.bind(this)}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
            <FindTemplateTooltip />
            <List>
              <ListItem
                primaryText="Templates"
                leftIcon={<CommunicationCallSplit />}
                initiallyOpen={true}
                primaryTogglesNestedList={true}
                nestedItems={templateMenuItems}
              />
            </List>
          </div>
        </Drawer>
        <div
          className={classnames("app-content", { "expanded-template": true })}
        >
          <div style={{ textAlign: "left", paddingLeft: "300px" }}>
            <br />
            <br />

            <Grid container spacing={3}>
              <Grid item xs={8}>
                <TxtField
                  value={this.state.loadedTemplate.id}
                  floatingLabelText="Template id"
                  margin="normal"
                  underlineStyle={{ borderColor: munvoBlue }}
                  underlineFocusStyle={{ borderColor: munvoBlue }}
                  floatingLabelFocusStyle={{ color: munvoBlue }}
                  readOnly={true}
                  disabled={true}
                />

                <br />

                <TxtField
                  error={this.state.emptyNameError ? "true" : "false"}
                  errorText={this.state.emptyNameError ? this.state.emptyNameError : ""}
                  value={this.state.loadedTemplate.name}
                  required={true}
                  onChange={this.changeText}
                  name="name"
                  floatingLabelText="Template name"
                  margin="normal"
                  underlineStyle={{ borderColor: munvoBlue }}
                  underlineFocusStyle={{ borderColor: munvoBlue }}
                  floatingLabelFocusStyle={{ color: munvoBlue }}
                  readOnly={this.state.edit ? false : true}
                  disabled={this.state.edit ? false : true}
                />

                <br />

                <TxtField
                  error={this.state.emptyBodyError ? "true" : "false"}
                  errorText={this.state.emptyBodyError ? "Required" : ""}
                  style={{ width: 700 }}
                  value={this.state.loadedTemplate.body}
                  required={true}
                  onChange={this.changeText}
                  name="body"
                  multiLine
                  rowsMax={5}
                  hintText={
                    "Hello <%=firstName%>, your flight has been relocated to gate <%=gateNumber%>. Thank you !"
                  }
                  floatingLabelText="SMS body"
                  margin="normal"
                  underlineStyle={{ borderColor: munvoBlue }}
                  underlineFocusStyle={{ borderColor: munvoBlue }}
                  floatingLabelFocusStyle={{ color: munvoBlue }}
                  readOnly={this.state.edit ? false : true}
                  disabled={this.state.edit ? false : true}
                />

                <br />
                <br />
                <br />

                <TextField
                  label="Final SMS example"
                  id="component-outlined"
                  name="example"
                  multiline={true}
                  value={this.state.loadedExample}
                  rowsMax={5}
                  labelWidth={150}
                  notched={true}
                  style={{ width: 700 }}
                  underlineStyle={{ borderColor: munvoBlue }}
                  underlineFocusStyle={{ borderColor: munvoBlue }}
                  floatingLabelFocusStyle={{ color: munvoBlue }}
                  readOnly={true}
                  variant="outlined"
                />

                <br />
                <br />
                <br />

                <section>
                  <CopyToClipboard
                    onCopy={this.handleCopy}
                    text={this.state.loadedPayload}
                  >
                    <TextField
                      label="XML as payload"
                      id="component-outlined"
                      onChange={this.changeText}
                      name="payload"
                      multiline={true}
                      value={this.state.loadedPayload}
                      rowsMax={18}
                      style={{ width: 700 }}
                      variant="outlined"
                      onBlur={this.handlePayloadOnBlur}
                      helperText={
                        this.state.copied ? "Copied to clipboard" : null
                      }
                    />
                  </CopyToClipboard>
                </section>
              </Grid>
              <Grid item xs={4}>
                <FloatingActionButton
                  data-tip
                  backgroundColor={munvoBlue}
                  data-for="delete-tt"
                  onClick={() => this.handleOpenDialogDel()}
                >
                  <ActionDelete />
                </FloatingActionButton>
                &nbsp;&nbsp;&nbsp;
                <FloatingActionButton
                  data-tip
                  backgroundColor={munvoBlue}
                  data-for="edit-tt"
                  onClick={() => this.handleEdit()}
                >
                  <EditorModeEdit />
                </FloatingActionButton>
                &nbsp;&nbsp;&nbsp;
                <FloatingActionButton
                  data-tip
                  backgroundColor={munvoBlue}
                  data-for="save-tt"
                  onClick={() => this.handleSubmit()}
                >
                  <ContentSave />
                </FloatingActionButton>
                <br />
                <Card variant="outlined" style={{ marginTop: 150, padding: 15 }}>
                  <div className="h5" style={{ fontSize: 13, color: munvoGray }}>
                    Note: Certain symbols are not allowed in the XML payload and must be escaped
                  </div>
                  The following symbols must be replaced as followed:
                  <div style={{ fontSize: 15 }}>◦ <strong>&</strong> with <strong>&amp;amp;</strong></div>
                  <div style={{ fontSize: 15 }}>◦ <strong>&lt;</strong> with <strong>&amp;lt;</strong></div>
                  <div style={{ fontSize: 15 }}>◦ <strong>&gt;</strong> with <strong>&amp;gt;</strong></div>
                  <div style={{ fontSize: 15 }}>◦ <strong>"</strong> with <strong>&amp;quot;</strong></div>
                  <div style={{ fontSize: 15 }}>◦ <strong>'</strong> with <strong>&amp;apos;</strong></div>
                </Card>
              </Grid>
            </Grid>
            <DeleteTemplateDialog />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.template.templates,
  isLoading: state.db.loading,
  urlListIsLoading: state.urlList.loading
});

export default connect(mapStateToProps)(TemplateMenu);
