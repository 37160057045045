import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextField } from 'redux-form-material-ui'
import { loginFormStyles } from './styles'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { munvoBlue } from '../utils/globalStyles'

class LoginForm extends Component{
  constructor(props){
    super(props)

    this.handleKeyEnter = this.handleKeyEnter.bind(this)
  }

  handleKeyEnter(e, handleSubmit){
    if(e.key === 'Enter' && e.shiftKey === false){
      handleSubmit()
    }
  }

  render(){
    const { classes, handleSubmit } = this.props

    return(
      <form className={classes.textCenter} onSubmit={handleSubmit} onKeyDown={e => this.handleKeyEnter(e, handleSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='text' 
              floatingLabelText='Username' 
              name='username'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='password' 
              floatingLabelText='Password' 
              name='password'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
        </Grid>
      </form>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  
  if(!values.username){
    errors.username = 'Required'
  }
  if(!values.password){
    errors.password = 'Required'
  }

  return errors
}


LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitCallback: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'loginForm',
  validate,
  onSubmit: (values, dispatch, props) => { props.submitCallback(values) }
})(withStyles(loginFormStyles)(LoginForm))
