import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { TextField, Checkbox, SelectField } from 'redux-form-material-ui'
import MenuItem from 'material-ui/MenuItem'
import { munvoBlue, munvoOrange } from '../utils/globalStyles'
import * as Constants from "../utils/Constants";
import { isValidPassword } from "../utils/generalUtils";

class EditUserForm extends Component{

  render(){
    const{ handleSubmit, roleList, initialValues } = this.props
    return(
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              floatingLabelText='Username' 
              name='username' 
              readOnly={initialValues.username === 'admin' || initialValues.username === 'ac-admin'}
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='password' 
              floatingLabelText='Password' 
              name='password'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='password' 
              floatingLabelText='Confirm Password' 
              name='confirmPassword'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field component={SelectField} floatingLabelText='Role' name='rolename' disabled={initialValues.username === 'admin' || initialValues.username === 'ac-admin'}>
              <MenuItem value='' primaryText=''/>
              {roleList.map((role) => (
                <MenuItem key={role.id} value={role.rolename} primaryText={role.rolename}/>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={Checkbox} 
              label='Active' 
              name='isActive' 
              disabled={initialValues.username === 'admin' || initialValues.username === 'ac-admin'}
              iconStyle={{ fill: munvoOrange }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={Checkbox} 
              label='Force Password Reset' 
              name='forcePassReset' 
              disabled={initialValues.username === 'admin' || initialValues.username === 'ac-admin'} 
              iconStyle={{ fill: munvoOrange }}
              />
          </Grid>
        </Grid>
      </form>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
    //check if pwd contains a number a letter and is of size greater then pwdlength
    
      if(!isValidPassword(values.password)){
        errors.password =
        "Password should be longer than " +
        Constants.PASSWORDLENGTH.toString() +
        " character long and contain both a letter and a number";
      }
    
  
  if(!values.username){
    errors.username = 'Required'
  }else{
    let doesUsernameExist = props.userList.find(user => {
      return user.username.toLowerCase() === values.username.toLowerCase() && user.username.toLowerCase() !== props.initialValues.username.toLowerCase()
    })

    if(doesUsernameExist){
      errors.username = 'Username already exists'
    }
  }

  if(values.password !== values.confirmPassword){
    errors.confirmPassword = 'Password mismatched'
  }

  if(values.rolename === undefined || values.rolename === ''){
    errors.rolename = 'Required'
  }

  return errors
}

EditUserForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  roleList: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired
}

export default reduxForm({ 
  form: 'editUserForm', 
  validate,
  fields: ['id', 'deleted', 'username', 'rolename', 'isActive'],
  onSubmit: (values, dispatch, props) => { props.submitCallback(values) }
})(EditUserForm)