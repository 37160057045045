import * as Types from './types'

export const configurationReducer = (state = { configurations: [] }, action) => {
  switch(action.type){
    case Types.CONFIGURATION_REQUEST:
      return { ...state, loading: true }
    case Types.CONFIGURATION_SUCCESS:
      return { loading: false, configurations: action.payload }
    case Types.CONFIGURATION_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}