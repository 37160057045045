import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getConfiguration } from "../reducers";
import WrapperTable from "../WrapperTable";
import Paper from "@material-ui/core/Paper";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { putConfiguration,deleteConfiguration } from "../reducers";
import { Dialog } from "material-ui";
import ConfigurationEditForm from "./ConfigurationEditForm";
import { configurationStyles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { confirmAlert } from "react-confirm-alert";
import { munvoOrange,munvoBlue } from '../utils/globalStyles'
import ReactTooltip from "react-tooltip";
import HelpIcon from '@material-ui/icons/Help';



class Configuration extends Component {
  constructor(props) {
    super(props);

    this.state = { openEdit: false, openAdd: false ,selectedConfiguration: null,configs : null };

    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleOpenAdd = this.handleOpenAdd.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
    this.handleDeleteConfiguration = this.handleDeleteConfiguration.bind(this);

    this.filterConfigs = this.filterConfigs.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getConfiguration());
   
  }
  
  filterConfigs(data){

    var newData =[] ; 
    for(var i = 0 ; i<data.length ; i++){
      var temp = data[i].key
      //dont show sqs related configurations
      if(temp.includes("sqs|") ||temp.includes("courier|password")||temp.includes("blacklist|control")){
        console.log(temp);

        continue;
      }else{
        newData.push(data[i]);
      }
    }

  return newData;

  }


  handleOpenAdd(){
    this.setState({ openAdd: true, selectedConfiguration: null});

  }
  handleCloseAdd() {
    this.setState({ openAdd: false });
  }

  handleAddSubmit(form) {
    this.props.dispatch(putConfiguration(form));
    this.setState({ openAdd: false });
  }

  handleOpenEdit(e) {
    if(e == "add-button"){
      this.setState({ openAdd: true });

    }else if (e.rowData.key != "courier|provider") {
      this.setState({ openAdd: true, selectedConfiguration: e.rowData });
    } else {
      confirmAlert({
        title: "",
        message: "You cannot change provider",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
    }
  }

  handleCloseEdit() {
    this.setState({ openAdd: false });
  }

  handleSubmit(form) {
    this.props.dispatch(putConfiguration(form));
    this.setState({ openAdd: false });
  }

  handleDeleteConfiguration(form) {
    this.props.dispatch(deleteConfiguration(form));
    this.setState({ openAdd: false });
  }

  render() {

    var { data, classes } = this.props;

    data = this.filterConfigs(data);

    return (

      <div style={{ paddingRight: "15%" }}>
      <div style={{textAlign: 'left', color: 'red'}} >
        WARNING: Changing configuration may compromise the SMSGateway's functionalities.
        <br/> If you are unsure about the change you want to make please contact your admin.
          <HelpIcon data-tip data-for="configuration-warning-tt" style={{ color: munvoBlue }} />
          <ReactTooltip id='configuration-warning-tt' place='right' type='info' effect='solid'>
            <span>Reserved keywords are subject to country specific regulations, make sure to plan accordingly</span>
          </ReactTooltip>
      </div>
      
      <div 
        style={{
        display: 'flex',
        padding: '10px'
        }}
      >
          <Button 
          style={{ background: munvoOrange }}
          data-tip 
          id='add-button' 
          variant='contained' 
          color='primary'
          onClick={() =>this.handleOpenAdd("add-button")}>Add Configuration
          <Dialog
              title="Add Configuration"
              actions={[
                <Button
                  variant="outlined"
                  onClick={this.handleDeleteConfiguration}
                  backgroundColor="red"
                  className={classes.button}
                >
                Delete
                </Button>,
                <Button
                  variant="outlined"
                  onClick={this.handleCloseAdd}
                  className={classes.button}
                >
                  Cancel
                </Button>,
                <RemoteSubmitButton
                  component={Button}
                  form="configurationEditForm"
                  label="Submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                />
              ]}
              open={this.state.openAdd}
              onRequestClose={this.handleCloseAdd}
              >
              <ConfigurationEditForm
                submitCallback={this.handleAddSubmit}
                initialValues={this.state.selectedConfiguration}
              />
            </Dialog>
        </Button>
      </div>
        <Grid container>
          <Grid item xs={12}>
            <Paper style={{ height: 600, width: "100%" }}>
              <WrapperTable
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                onRowDoubleClick={e => this.handleOpenEdit(e)}
                rowHeight={56}
                headerHeight={56}
                columns={[
                  {
                    width: 500,
                    flexGrow: 1.0,
                    label: "Configuration Name",
                    dataKey: "key"
                  },
                  {
                    width: 500,
                    flexGrow: 1.0,
                    label: "Configuration Value",
                    dataKey: "value"
                  }
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
           </Grid>
        </Grid>
      </div>
    );
  }
}

Configuration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    data: state.configuration.configurations
  };
};

export default connect(mapStateToProps)(
  withStyles(configurationStyles)(Configuration)
);
