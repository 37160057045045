import React, { Component } from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core";//styles";
import { theme } from "./styles";
import TransactionTable from "./TransactionTable";
import SessionTable from "./SessionTable";
import TransactionErrorTable from "./TransactionErrorTable";
import BlacklistTable from "./BlacklistTable";
import WebhookErrorLogTable from "./WebHookErrorLogTable";
import WebhookLogTable from "./WebhookLogTable";
import SessionTransactionTable from "./SessionTransactionTable";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import HeaderBar from "../Header";
import { munvoOrange } from "../utils/globalStyles";
import { abilities } from "../utils/Ability";

class Tables extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <HeaderBar pageTitle={"SMS Gateway - Tables"} />
        <br />
        <br />
        <br />
        <div>
          <SimpleTabs role={abilities[this.props.role]} />
        </div>
      </MuiThemeProvider>
    );
  }
}

const TabContainer = props => {
  return (
    <Typography component="div" style={{ padding: 12 }}>
      {props.children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const SimpleTabs = props => {
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <AppBar position="static">
          <Tabs
            backgroundColor={munvoOrange}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Transactions" />
            <Tab label="Transaction Error" />
            <Tab label="Session" />
            <Tab label="Blacklist" />
            {props.role.rules[0].actions === "read" || (
              <Tab label="Webhook Error Log" />
            )}
            <Tab label="Webhook Log" />
            <Tab label="Session Transaction" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <TransactionTable />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <TransactionErrorTable />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <SessionTable />
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            <BlacklistTable />
          </TabContainer>
        )}
        {value === 4 && (
          <TabContainer>
            <WebhookErrorLogTable />
          </TabContainer>
        )}
        {value === 5 && (
          <TabContainer>
            <WebhookLogTable />
          </TabContainer>
        )}
        {value === 6 && (
          <TabContainer>
            <SessionTransactionTable />
          </TabContainer>
        )}
      </MuiThemeProvider>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    role: state.authentication.role
  };
};

export default connect(mapStateToProps)(Tables);
