// export const DEFAULT_POST_KEYWORDS_SUCCESS = "DEFAULT_POST_KEYWORDS_SUCCESS"

export const KEYWORDS_GET_REQUEST = "KEYWORDS_GET_REQUEST"
export const KEYWORDS_GET_SUCCESS = "KEYWORDS_GET_SUCCESS"
export const KEYWORDS_GET_FAILURE = "KEYWORDS_GET_FAILURE"

export const KEYWORDS_POST_REQUEST = "KEYWORDS_POST_REQUEST"
export const KEYWORDS_POST_SUCCESS = "KEYWORDS_POST_SUCCESS"
export const KEYWORDS_POST_FAILURE = "KEYWORDS_POST_FAILURE"

export const KEYWORDS_DELETE_REQUEST = "KEYWORDS_DELETE_REQUEST"
export const KEYWORDS_DELETE_SUCCESS = "KEYWORDS_DELETE_SUCCESS"
export const KEYWORDS_DELETE_FAILURE = "KEYWORDS_DELETE_FAILURE"

export const KEYWORDS_PUT_REQUEST = "KEYWORDS_PUT_REQUEST"
export const KEYWORDS_PUT_SUCCESS = "KEYWORDS_PUT_SUCCESS"
export const KEYWORDS_PUT_FAILURE = "KEYWORDS_PUT_FAILURE"

export const KEYWORDS_INVALIDATE_REQUEST = "KEYWORDS_INVALIDATE_REQUEST"