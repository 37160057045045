import React from "react";
import { confirmAlert } from "react-confirm-alert";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import { PHONE_PATTERN, DELIMITER } from "./Constants";
import * as Constants from "./Constants";

export const checkForNodeName = (name, currentNode) => {
  if (currentNode.name !== undefined && name.toLowerCase() === currentNode.name.toLowerCase()) {
    return true;
  } else if (currentNode.children === undefined) {
    return false;
  } else {
    for (let i = 0; i < currentNode.children.length; i++) {
      let currentChild = currentNode.children[i];
      let result = checkForNodeName(name, currentChild);

      if (result) {
        return true;
      }
    }
    return false;
  }
};

//todo check if useless ?
export const setWebhookIdToNode = (currentNode, webhookUrlId) => {
  currentNode.webhookUrlId = webhookUrlId;

  if (currentNode.children !== undefined) {
    currentNode.children = currentNode.children.map((child) => {
      return setWebhookIdToNode(child, webhookUrlId);
    });
  }

  return currentNode;
};

export const wipeWebhookFlags = (currentNode) => {
  //if webhook isnt enabled wipe the webhookUrlId
  if (currentNode.webhookEnabled === 0) {
    currentNode.webhookUrlId = null;
  }

  if (currentNode.children !== undefined) {
    currentNode.children = currentNode.children.map((child) => {
      return wipeWebhookFlags(child);
    });
  }

  return currentNode;
};

export const createNotification = (title, message) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Ok",
      },
    ],
  });
};

export const getJwtToken = () => {
  return localStorage.getItem("jwt-token") ? localStorage.getItem("jwt-token") : sessionStorage.getItem("jwt-token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : sessionStorage.getItem("refreshToken");
};

export const getLoggedInUser = () => {
  return localStorage.getItem("username") ? localStorage.getItem("username") : sessionStorage.getItem("username");
};

export const revertTree = (tree, metadata, campaignID, flowchartOptions, root = { priority: 1 }) => {
  delete metadata.flowchartOptions;
  const fullTree = root;
  fullTree.children = tree;
  return {
    ...metadata,
    flowchartOptions,
    campaignID,
    root: revertNode(fullTree),
  };
};

export const parseNode = (node, depth = 0) => {
  let newDepth = depth + 1;

  if (depth === 0 && _.isEmpty(node)) {
    return parseNode({
      priority: 1,
      children: [{ priority: 1 }],
    });
  }

  //reply is the value stored in db that may contain the DELIMITER
  //replies is an array containing all the linked replies, excluding the first reply
  const {
    name,
    nodeDetails,
    children,
    customerResponse,
    reply,
    firstReply,
    replies,
    webhookEnabled,
    webhookUrlId,
    targetNode,
    portalMode,
    webhookErrorResponse
  } = node;
  var linkedReplies = "";
  var isWebhookEnabled = webhookEnabled;
  var tempWebhookErrorResponse = webhookErrorResponse;
  var fullReply = firstReply && firstReply.length > 0 ? firstReply : reply;

  //if replies is populated, it means we are creating a new moveable node
  if (replies && replies.length > 0) {
    linkedReplies = replies.map((linkedReply) => (linkedReplies = linkedReplies + DELIMITER + linkedReply.reply));
    fullReply = firstReply + linkedReplies[linkedReplies.length - 1];
  }

  if (!webhookEnabled) {
    isWebhookEnabled = 0;
  }

  const linkedRepliesHTML =
    fullReply && fullReply.length > 0
      ? fullReply.split(DELIMITER).map((reply, replyIndex) => <li key={replyIndex}>{"Reply: " + (reply || "")}</li>)
      : undefined;

  const newNode = {
    ...node,
    name: name,
    nodeDetails: nodeDetails,

    reply: fullReply,
    title: !customerResponse ? (
      <Grid item xs={12}>
        {customerResponse === "" ? "Accept Any Response" : "Not directly reachable by Customer SMS"}
      </Grid>
    ) : (
      <Grid item xs={12}>
        {"Customer Response: " + (customerResponse || "")}
      </Grid>
    ),
    subtitle:
      fullReply && fullReply.includes(DELIMITER) ? (
        <div>
          {" "}
          <Grid className="node-name" item xs={12}>
            {name ? "Name: " + (name || "") : ""}
          </Grid>{" "}
          <Grid item xs={12}>
            <ol className="linkedRepliesNode">{linkedRepliesHTML}</ol>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid className="node-name" item xs={12}>
            {name ? "Name: " + (name || "") : ""}
          </Grid>{" "}
          <Grid item xs={12}>
            {"Reply: " + (fullReply || "")}
          </Grid>
        </div>
      ),
    webhookEnabled: isWebhookEnabled,
    webhookUrlId: webhookUrlId,
    webhookErrorResponse: tempWebhookErrorResponse,
    targetNode: targetNode === undefined ? JSON.parse("{\"name\":\"\",\"id\":-1}") : targetNode,   // reference to the target name for redirection
    canNodeHaveChildren: (targetNode === undefined || targetNode.name === "") ? true : false, // if node  has a target node it cant have a child
    portalMode: portalMode ? portalMode : "",
    expanded: true,
    children: children && children.length > 0 ? children.map((node) => parseNode(node, newDepth)) : undefined,
  };
  return newNode;
};

const sortTree = (tree) => {
  let sortedTree = tree.sort((a, b) => a.id - b.id);
  for (let i = 0; i < sortedTree.length; i++) {
    if (sortedTree[i].children) {
      sortTree(sortedTree[i].children);
    }
  }
  return sortedTree;
};

export const removeIds = (tree) => {
  if (!tree) {
    return null;
  }
  for (let i = 0; i < tree.length; i++) {
    tree[i].id = null;
    //remove portal id
    if (tree[i].targetNode != null) {
      tree[i].targetNode.id = null;
    }
    if (tree[i].children) {
      removeIds(tree[i].children);
    }
  }
  return tree;
};

export const removeFlowchartOptionsIds = (flowchartOptions) => {
  for (var i = 0; i < flowchartOptions.length; i++) {
    flowchartOptions[i].id = null;
  }

  return flowchartOptions;
};

export const parseTree = (tree) => {
  if (!tree) return tree;
  const { root, ...metadata } = tree;
  const parsedTree = [parseNode(root)];
  const newTree = sortTree(parsedTree[0].children);
  const { children, ...rootNode } = parsedTree[0];
  const availableTarget = getAvailableTarget(parsedTree, true);

  return {
    newTree,
    metadata,
    root: rootNode,
    availableTarget,
  };
};

//input a parsed tree (ie parseNode(root)
//return a list of all nodes with a non empty node name and no targetnode and doesnt have a child
export const getAvailableTarget = (node, root) => {
  var availableTarget = [];

  if (root) {
    node = node[0];
  }

  //base case

  if (node.children === undefined || node.children.length === 0) {
    const currentTarget = node.targetNode;
    const currentName = node.name;

    //check if node isnt a portal node
    if (currentTarget == undefined || currentTarget.name === "") {
      //check if node has a node name
      if (currentName != undefined && currentName !== "") {
        availableTarget.push(node);
        return availableTarget;
      }
    }
    return availableTarget;
  }

  //if the current node has children call recursively on all children
  //push what they return along with current node
  //recursion case

  for (var i = 0; i < node.children.length; i++) {
    const currentChildren = node.children[i];

    var childList = getAvailableTarget(currentChildren, false);

    for (var j = 0; j < childList.length; j++) {
      availableTarget.push(childList[j]);
    }
  }

  //check for current
  const currentTarget = node.targetNode;
  const currentName = node.name;

  //check if node isnt a portal node
  if (currentTarget == undefined || currentTarget.name === "") {
    //check if node has a node name
    if (currentName != undefined && currentName !== "") {
      availableTarget.push(node);
    }
  }

  return availableTarget;
};

//given a node and a function will apply the function to all the nodes, if node is a list of node isRoot needs to be false
//can act as filter if the function given return what should be filtered
export const mapOnTree = (node, isRoot, func) => {
  if (node === undefined) {
    return;
  }
  var filteredList = [];

  if (isRoot) {
    node = node[0];
  }

  //base case

  if ((node.children === undefined || node.children.length === 0) && node[0] === undefined) {
    var list = func(node);
    if (list && (list[0] !== undefined || list.name !== undefined)) {
      filteredList.push(list);
    }
    return filteredList;
  }

  var nodelist = [];

  if (node[0] !== undefined) {
    nodelist = node;
  } else {
    nodelist = node.children;
  }

  //if the current node has children call recursively on all children
  //push what they return along with current node
  //recursion case

  for (var i = 0; i < nodelist.length; i++) {
    const currentChildren = nodelist[i];

    var childList = mapOnTree(currentChildren, false, func);

    for (var j = 0; j < childList.length; j++) {
      var list = func(childList[j]);
      if (list && (list[0] !== undefined || list.name !== undefined)) {
        filteredList.push(list);
      }
    }
  }

  return filteredList;
};

export const revertNode = ({ title, subtitle, expanded, children, ...node }) => {
  const json = { ...node };
  if (children) {
    json.children = children.map((child) => revertNode(child));
  }

  return json;
};

export const getReactElementText = (parent) => {
  if (parent === null) {
    return "";
  }
  if (typeof parent === "string") {
    return parent;
  }
  if (
    (typeof parent !== "object" && parent) ||
    !parent.props ||
    (typeof parent.props.children !== "string" && !parent.props.children) || typeof parent.props.children !== "object"
  ) {
    return "";
  }
  if (typeof parent.props.children === "string") {
    return parent.props.children;
  }
  return parent.props.children.map((child) => getReactElementText(child)).join("");
};

export const isPhoneNumber = (str) => PHONE_PATTERN.test(str);

export const isValidPassword = (pwd) => {
  //check pwd has at least one letter, one bnumber and is big enough
  if (
    Constants.AT_LEAST_ONE_LETTER.test(pwd) &&
    Constants.AT_LEAST_ONE_NUMBER.test(pwd) &&
    pwd.length >= Constants.PASSWORDLENGTH
  ) {
    return true;
  }
  return false;
};

export const stringSearch = (key, searchQuery, node, path, treeIndex) => {
  if (typeof node[key] === "function") {
    // Search within text after calling its function to generate the text
    return (
      String(node[key]({ node, path, treeIndex }))
        .toUpperCase()
        .indexOf(searchQuery) > -1
    );
  } else if (typeof node[key] === "object") {
    // Search within text inside react elements
    return (
      getReactElementText(node[key])
        .toUpperCase()
        .indexOf(searchQuery) > -1
    );
  }
  // Search within string
  return (
    node[key] &&
    String(node[key])
      .toUpperCase()
      .indexOf(searchQuery) > -1
  );
};

export const customSearch = ({ node, path, treeIndex, searchQuery }) => {
  const capsSearchQuery = searchQuery ? searchQuery.toUpperCase() : null;
  return (
    (stringSearch("customerResponse", capsSearchQuery, node, path, treeIndex) ||
      stringSearch("reply", capsSearchQuery, node, path, treeIndex)) &&
    searchQuery.length !== 0
  );
};

export const logMove = ({ node, treeIndex, path }) =>
  console.log("node:", node, "\ntreeIndex:", treeIndex, "\npath:", path);

//from front-end to back-end
export const generateMenuTreePayload = (menuTreeData) => {
  var menuPayload = [];

  for (var i = 0; i < menuTreeData.length; i++) {
    var branchPayload = convertTreeDataToMenuItemFormat(menuTreeData[i]);
    branchPayload.orderNumber = i + 1;
    menuPayload.push(branchPayload);
  }

  return menuPayload;
};

const convertTreeDataToMenuItemFormat = (treeItem) => {
  var branchItem = {};
  branchItem.expanded = treeItem.expanded;
  branchItem.id = treeItem.menuItemId;
  branchItem.createdTimestamp = treeItem.createdTimestamp;

  if (treeItem.flowchart != null) {
    branchItem.flowchart = treeItem.flowchart;
  } else if (treeItem.isDirectory) {
    branchItem.folder = {};
    branchItem.folder.id = treeItem.folderId;
    branchItem.folder.name = treeItem.title;
    branchItem.folder.createdTimestamp = treeItem.folderCreatedTimestamp;

    branchItem.children = [];

    var childrenAmount = treeItem.children ? treeItem.children.length : 0;

    for (var i = 0; i < childrenAmount; i++) {
      var branchItemChild = convertTreeDataToMenuItemFormat(treeItem.children[i]);
      branchItemChild.orderNumber = i + 1;

      branchItem.children.push(branchItemChild);
    }
  }

  return branchItem;
};

//from back-end to front-end
export const generateMenuTreeData = (menuPayload) => {
  var treeData = [];
  for (var i = 0; i < menuPayload.menuItems.length; i++) {
    var branchData = convertMenuItemToTreeDataFormat(menuPayload.menuItems[i]);
    treeData.push(branchData);
  }

  return treeData;
};

const convertMenuItemToTreeDataFormat = (menuItem) => {
  var branchData = {};
  branchData.expanded = menuItem.expanded;
  branchData.menuItemId = menuItem.id;
  branchData.createdTimestamp = menuItem.createdTimestamp;

  if (menuItem.flowchart != null) {
    branchData.title = menuItem.flowchart.name;
    branchData.flowchart = menuItem.flowchart;
    branchData.isDirectory = false;
  } else if (menuItem.folder != null) {
    branchData.title = menuItem.folder.name;
    branchData.isDirectory = true;
    branchData.folderId = menuItem.folder.id;
    branchData.folderCreatedTimestamp = menuItem.folder.createdTimestamp;
    branchData.children = [];

    var childrenAmount = menuItem.children ? menuItem.children.length : 0;

    for (var i = 0; i < childrenAmount; i++) {
      branchData.children.push(convertMenuItemToTreeDataFormat(menuItem.children[i]));
    }
  }

  return branchData;
};
