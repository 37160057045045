import { createMuiTheme } from "@material-ui/core/styles";
import { munvoBlue } from "../utils/globalStyles";

export const headerStyles = theme => ({
  button: {
    margin: theme.spacing()
  }
});

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  }
});
