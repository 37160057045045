import * as Types from './types'

export const roleReducer = (state = { roles: [], loading: false }, action) => {
  switch(action.type){
    case Types.ROLE_REQUEST:
      return{
        loading: true
      }
    case Types.ROLE_SUCCESS:
      return{
        loading: false,
        roles: action.payload
      }
    case Types.ROLE_FAILURE:
      return{
        loading: false
      }
    default:
      return state
  }
}