import * as Types from './types'

export const reportsReducer = (state = { reportData: {} }, action) => {
  switch(action.type){
    case Types.REPORT_REQUEST:
      return { ...state, loading: true }
    case Types.REPORT_SUCCESS:
      return { loading: false, reportData: action.payload }
    case Types.REPORT_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}