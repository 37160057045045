import * as Constants from "../utils/Constants";

export const isCourierConstants = values => {
  return [
    Constants.COURIER_ARRET,
    Constants.COURIER_OPT_OUT,
    Constants.COURIER_OPTOUT,
    Constants.COURIER_REMOVE,
    Constants.COURIER_TD,
    Constants.COURIER_STOP,
    Constants.COURIER_STOPALL,
    Constants.COURIER_UNSUBSCRIBE,
    Constants.COURIER_CANCEL,
    Constants.COURIER_END,
    Constants.COURIER_QUIT,
    Constants.COURIER_START,
    Constants.COURIER_YES,
    Constants.COURIER_UNSTOP,
    Constants.COURIER_HELP,
    Constants.COURIER_INFO
  ].includes(
    values.customerResponse ? values.customerResponse.toUpperCase() : null
  );
};

export const parseLinkedReplies = form => {
  let reply = "";
  let linkedReplies = "";
  const { firstReply, replies } = form;

  if (replies && replies.length > 0) {
    linkedReplies = replies.map(linkedReply => {
      linkedReplies = linkedReplies + Constants.DELIMITER + linkedReply.reply;
      return linkedReplies;
    });
    reply = firstReply + linkedReplies[linkedReplies.length - 1];
  } else {
    reply = firstReply;
  }

  delete form.firstReply;
  delete form.replies;

  form.reply = reply;

  return form;
};
