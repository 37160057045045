import { munvoBlue } from '../utils/globalStyles'

export const tabStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  block: {
    backgroundColor: munvoBlue,
    height: '64px'
  },
  tabContainerPadding: {
    paddingTop: '20px',
    paddingLeft: '15%'
  },
  subtitle:{
    paddingTop: '10px'
  },
  currentTypeButtons:{
    marginRight: theme.spacing()
  }
})