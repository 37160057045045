import React from "react";
import SortableTree from "react-sortable-tree";
import FolderIcon from "@material-ui/icons/Folder";
import { IconButton } from "material-ui";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import NavigationArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";

let FlowchartList = props => {
    const { treeData, onChange, canDrag, canDrop, menuEdit, handleFolderDelete, handleFlowchartClick, searchString,editEnabled } = props;
    if (treeData) {
        return (
            <div style={{ maxHeight: 'calc(100vh - 305px)', height: '100%', overflow: 'auto', paddingBottom: editEnabled? '50px':'0px'}}>
                <SortableTree
                    theme={FileExplorerTheme}
                    treeData={treeData}
                    onChange={onChange}
                    canDrag={canDrag}
                    canDrop={canDrop}
                    rowHeight={50}
                    generateNodeProps={rowInfo => {

                        var folderIcon = <FolderIcon style={{ width: 48 }} />;

                        if (menuEdit) {
                            folderIcon = <IconButton onClick={() => handleFolderDelete(rowInfo)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        } else {
                            if (rowInfo.node.expanded) folderIcon = <FolderOpenIcon style={{ width: 48 }} />;
                        }

                        const style = rowInfo.node.isDirectory ? { height: 50, overflowWrap: 'anywhere' } : { height: 50, overflowWrap: 'anywhere', cursor: 'pointer' }
                        return {
                            onClick: () => { if (!rowInfo.node.isDirectory) handleFlowchartClick(rowInfo.node.flowchart) },
                            icons: rowInfo.node.isDirectory ? [folderIcon] : [<NavigationArrowForward style={{ width: 48 }} />],
                            style: style,
                            className: searchString !== '' && (rowInfo.node.title.includes(searchString) || (rowInfo.node.flowchart && rowInfo.node.flowchart.campaignID.includes(searchString))) ? 'menuItemSearchResult' : null
                        };
                    }}
                />
            </div>
        );
    }


    else {


        return (

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <CircularProgress />
            </Box>

        );
        ;

    }

}

export default FlowchartList;
