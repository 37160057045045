import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken, getRefreshToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";

import { API_VERSION } from "../../utils/Constants";

const sessionRefreshSuccess = createAction(Types.SESSION_REFRESH_SUCCESS);
const sessionRefreshFailure = createAction(Types.SESSION_REFRESH_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const refreshSession = (username, rememberMe) => async (dispatch) => {
  const response = await fetch(API_VERSION + "session/refresh", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "text/plain",
    },
    body: getRefreshToken(),
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(sessionRefreshFailure());
  } else {
    const body = await response.json();

    if (rememberMe) {
      localStorage.setItem("jwt-token", body.jwtToken);
    } else {
      sessionStorage.setItem("jwt-token", body.jwtToken);
    }

    dispatch(sessionRefreshSuccess());
  }
};
