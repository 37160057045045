import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { flowchartReducer } from "./flowcharts";
import { menuReducer } from "./menuItems";
import { dbReducer } from "./db";
import { authenticationReducer } from "./login";
import {
  urlListReducer,
  twilioCredentialsReducer,
  awsCredentialsReducer,
  sinchCredentialsReducer,
  telusCredentialsReducer
} from "./settings";
import { userReducer } from "./users";
import { roleReducer } from "./roles";
import { nodeTemplateReducer } from "./nodeTemplates";
import { sessionRefreshReducer } from "./sessionRefresh";
import { configurationReducer } from "./configuration";
import { KeywordsReducer } from "./Keywords";
import { profileReducer } from "./profile";
import { reportsReducer } from "./reports";
import { templateReducer } from "./templates";
import { tablesReducer } from "./tables";
import { monitoringReducer } from "./monitoring";


const rootReducer = combineReducers({
  flowcharts: flowchartReducer,
  menu: menuReducer,
  form: formReducer,
  db: dbReducer,
  authentication: authenticationReducer,
  urlList: urlListReducer,
  twilioCredentials: twilioCredentialsReducer,
  awsCredentials: awsCredentialsReducer,
  sinchCredentials: sinchCredentialsReducer,
  telusCredentials: telusCredentialsReducer,
  user: userReducer,
  role: roleReducer,
  nodeTemplate: nodeTemplateReducer,
  template: templateReducer,
  sessionRefresh: sessionRefreshReducer,
  configuration: configurationReducer,
  Keywords: KeywordsReducer,
  profile: profileReducer,
  reports: reportsReducer,
  tables: tablesReducer,
  monitoring: monitoringReducer
});

const composeEnhancers =
  process.env.NODE_ENV !== "production"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
