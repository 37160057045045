import { munvoBlue } from "../utils/globalStyles";
import { createMuiTheme } from "@material-ui/core/styles";

export const transactionStyles = theme => ({
  palette: {
    primary: {
      main: munvoBlue
    }
  },

  tableCell10: {
    overflow: "auto",
    width: "10%",
    display: "block"
  },
  tableCell15: {
    overflow: "auto",
    width: "15%",
    display: "block"
  },
  tableCell30: {
    overflow: "auto",
    width: "30%",
    display: "block"
  }
});
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  }
});
