import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { AppBar, ListItem } from "material-ui";
import HelpIcon from "material-ui/svg-icons/action/help";
import Drawer from "@material-ui/core/Drawer";
import { createNotification } from "../utils/generalUtils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ActionDelete,ImageEdit } from "material-ui/svg-icons";
import * as CourierConstants from "../utils/Constants";
import { resetSession, resetIdle } from "../reducers/flowcharts/actions";
import { connect } from "react-redux";
import { ResetSessionTooltip } from '../utils/Tooltip'
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "material-ui/MenuItem";
import { FormHelperText } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import DefaultKeywordsEditForm from "../DefaultKeywordsPage/DefaultKeywordsEditForm";
import { Dialog } from "material-ui";
import RemoteSubmitButton from "../RemoteSubmitButton";
import { getKeywordsByFlowchartId, postDefaultKeywords,deleteKeywords } from "../reducers";
import { modifyKeywords } from "../reducers/Keywords";
import { Field } from 'redux-form'

const styles = theme => ({
  colorSwitchBase: {
    "&$colorChecked": {
      color: munvoOrange,
    },
    "&$colorChecked + $colorTrack": {
      backgroundColor: munvoOrange,
    },
  },
  colorTrack: {},
  colorChecked: {},
});

class GlobalKeywordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      KeywordWebhookMap: props.KeywordWebhookMap,
      reply: props.initValue
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initValue) {
      this.setState({
        reply: nextProps.initValue
      });
    }
  }

  onChange = e => {
    this.setState({
      reply: e.target.value
    });
  };

  render() {
    return (
      <div>
        <TextField
          style={{ width: "83.33%" }}
          value={this.state.reply}
          onChange={this.onChange}
          required
          onBlur={() =>
            this.props.updateGlobalKeyword(this.props.keyword, this.state.reply)
          }
          name={this.props.keyword}
          label={this.props.keyword}
          margin="normal"
          multiline
        />

        <WebhookText
          style={{ width: "83.33%" }}
          margin="normal"
          KeywordWebhookMap={this.state.KeywordWebhookMap}
          keyword={this.props.keyword.substring(this.props.keyword.indexOf("|") + 1, this.props.keyword.length)}
        />

      </div>
    );
  }
}
class WebhookText extends React.Component {
  constructor(props) {
    super(props);

    var jsonWebhookMap = undefined;

    if (typeof props.KeywordWebhookMap.value === 'string' || props.KeywordWebhookMap.value instanceof String) {
      jsonWebhookMap = JSON.parse(props.KeywordWebhookMap.value);
    }
    else {
      jsonWebhookMap = props.KeywordWebhookMap.value;
    }
    var webhookurl = ""
    var webhookErrorResponse  = ""
    for (var i in jsonWebhookMap.keywords) {
      if (jsonWebhookMap.keywords[i].hasOwnProperty(props.keyword) && jsonWebhookMap.keywords[i][props.keyword].url !== undefined) {
        webhookurl = jsonWebhookMap.keywords[i][props.keyword].url;
        webhookErrorResponse  = jsonWebhookMap.keywords[i][props.keyword].webhookErrorResponse ;
        break;
      }
    }

    this.state = { webhookurl: webhookurl, webhookErrorResponse : webhookErrorResponse  }
  }


  render() {
    return (
      <div>
        <TextField
          defaultValue={this.state.webhookurl ? this.state.webhookurl : "N/A"}
          label="Webhook" disabled
          style={{ width: "83.33%" }}
          margin="normal"
        />
        {(this.state.webhookurl && this.state.webhookurl !== "N/A") &&
          <TextField
            defaultValue={this.state.webhookErrorResponse  || "N/A"}
            label="Webhook Custom Error Message" disabled
            style={{ width: "83.33%" }}
            margin="normal"
          />
        }
      </div>
    )
  }
}

class FlowchartOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stop: "",
      start: "",
      help: "",
      open: false,
      code: "",
      expirationTime: 0,
      expireComplete: false,
      expireInactive: false,
      expirationMessage: "",
      linkedRepliesDelayTime: 0,
      linkedRepliesDelayFlag: false,
      catchAllResponse: "",
      catchAllResponseFlag: false,
      webhooksIncludePersonalization: false,
      WebhookOptions: [{ "value": 'value', "label": 'label' }], // List of available webhooks
      sessionNumber: "",
      sessionError: false,
      sessionSuccess: false,

      KeywordWebhookMap: { value: { keywords: [] }, key: "KeywordWebhookMap" }, // webhook sent as a response to keywords json

      stopKeywordWebhook: "",
      startKeywordWebhook: "",
      helpKeywordWebhook: "",

      globalKeywords: [],
      globalKeywordKeys: [],
      globalKeyword: "",
      globalKeywordReply: "",
      globalKeywordWebhook: [], // needed ?? prob not replace by the map
      globalKeywordRegex: false,


      tab: null,
      invalidKeyword: false,
      showDefaultKeywordsEditForm: false,
      defaultKeywordsEditFormMethod: null,
      defaultKeywordsEditFormInitialValues: {isRegex:"false",keywords:"",response:"",role:"global",language:"EN",webhookId:null}
    };
    this.updateGlobalKeyword = this.updateGlobalKeyword.bind(this);
    this.changeSessionNumber = this.changeSessionNumber.bind(this);
    this.changeKeywordWebhook = this.changeKeywordWebhook.bind(this);
    this.changeKeywordWebhookErrorResponse = this.changeKeywordWebhookErrorResponse.bind(this);
    this.changeReservedKeywordWebhook = this.changeReservedKeywordWebhook.bind(this);

    this.resetSession = this.resetSession.bind(this);
  }

  componentWillReceiveProps(nextProps) {


    var KeywordWebhookMap = { key: "KeywordWebhookMap", value: { keywords: [] } };
    var stopKeywordWebhook = JSON.parse('{"url":"N/A"}');
    var helpKeywordWebhook = JSON.parse('{"url":"N/A"}');
    var startKeywordWebhook = JSON.parse('{"url":"N/A"}');

    //get the keyword webhook map from the options
    for (var j in nextProps.flowchartOptions) {
      if (nextProps.flowchartOptions[j]["key"] === "KeywordWebhookMap") {
        KeywordWebhookMap = nextProps.flowchartOptions[j];
        if (typeof KeywordWebhookMap.value === 'string' || KeywordWebhookMap.value instanceof String)
          KeywordWebhookMap.value = JSON.parse(KeywordWebhookMap.value);
        break;
      }
    }

    //initialise the value of reserved keywords webhook

    for (var i in KeywordWebhookMap.value.keywords) {
      if (KeywordWebhookMap.value.keywords[i]["stop"] !== undefined) {
        stopKeywordWebhook = KeywordWebhookMap.value.keywords[i]["stop"];
      }
      else if (KeywordWebhookMap.value.keywords[i]["start"] !== undefined) {
        startKeywordWebhook = KeywordWebhookMap.value.keywords[i]["start"];
      }
      else if (KeywordWebhookMap.value.keywords[i]["help"] !== undefined) {
        helpKeywordWebhook = KeywordWebhookMap.value.keywords[i]["help"];
      }

    }


    this.setState({
      stopKeywordWebhook: stopKeywordWebhook,
      helpKeywordWebhook: helpKeywordWebhook,
      startKeywordWebhook: startKeywordWebhook,
      KeywordWebhookMap: KeywordWebhookMap,
      globalKeywordWebhook: JSON.parse('{"url":"N/A", "webhookErrorResponse ": ""}'),
      WebhookOptions: nextProps.webhookList,
      stop: nextProps.flowchartOptions.find(el => el.key === "stopReply").value,
      help: nextProps.flowchartOptions.find(el => el.key === "helpReply").value,
      start: nextProps.flowchartOptions.find(el => el.key === "startReply")
        .value,
      globalKeywords: nextProps.flowchartOptions.filter(el =>
        el.key.includes("globalKeyword")
      ),
      globalKeywordKeys: nextProps.flowchartOptions.map(el => el.key),
      expirationTime: Number(
        nextProps.flowchartOptions.find(el => el.key === "expirationTime").value
      ),
      expireComplete:
        nextProps.flowchartOptions.find(el => el.key === "expireComplete")
          .value === "true",
      expireInactive:
        nextProps.flowchartOptions.find(el => el.key === "expireInactive")
          .value === "true",
      expirationMessage: nextProps.flowchartOptions.find(
        el => el.key === "expirationMessage"
      ).value,
      linkedRepliesDelayFlag:
        nextProps.flowchartOptions.find(el => el.key === "linkedRepliesDelayFlag") === undefined ? undefined : nextProps.flowchartOptions.find(el => el.key === "linkedRepliesDelayFlag").value === "true",
      linkedRepliesDelayTime:
        nextProps.flowchartOptions.find(el => el.key === "linkedRepliesDelayTime") === undefined ? undefined : nextProps.flowchartOptions.find(el => el.key === "linkedRepliesDelayTime").value,
      catchAllResponseFlag:
        nextProps.flowchartOptions.find(el => el.key === "catchAllResponseFlag")
          .value === "true",
      catchAllResponse: nextProps.flowchartOptions.find(
        el => el.key === "catchAllResponse"
      ).value,
      webhooksIncludePersonalization: nextProps.flowchartOptions.find(
        el => el.key === "webhooksIncludePersonalization"
      ).value === "true",
      open: nextProps.open,
      code: nextProps.code,
      sessionError: nextProps.sessionError,
      sessionSuccess: nextProps.sessionSuccess,
      defaultKeywordsEditFormInitialValues: {isRegex:"false",keywords:"",response:"",role:"global",language:"EN",webhookId:null}
    });
  }


  changeTab = tab => {
    this.setState({
      tab: this.state.tab === tab ? null : tab
    });
  };

  changeSessionNumber = e => {
    this.setState({
      sessionNumber: e.target.value
    }, () => {
      if (!this.props.sessionIdle) this.props.dispatch(resetIdle());
    })
  };

  resetSession = () => {
    if (this.state.sessionNumber) {
      this.props.dispatch(
        resetSession(this.state.sessionNumber, this.props.campaignId)
      );
    }
  };

  changeText = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  changeGlobalKeyword = e => {
    this.setState({
      globalKeyword: e.target.value,
      invalidKeyword:
        this.state.globalKeywordKeys.includes(
          "globalKeyword|" + e.target.value
        ) ||
        this.state.globalKeywordKeys.includes(
          "globalKeywordRegex|" + e.target.value
        ) ||
          [
            CourierConstants.COURIER_ARRET,
            CourierConstants.COURIER_OPT_OUT,
            CourierConstants.COURIER_OPTOUT,
            CourierConstants.COURIER_REMOVE,
            CourierConstants.COURIER_TD,
            CourierConstants.COURIER_STOP,
            CourierConstants.COURIER_STOPALL,
            CourierConstants.COURIER_UNSUBSCRIBE,
            CourierConstants.COURIER_CANCEL,
            CourierConstants.COURIER_END,
            CourierConstants.COURIER_QUIT,
            CourierConstants.COURIER_START,
            CourierConstants.COURIER_YES,
            CourierConstants.COURIER_UNSTOP,
            CourierConstants.COURIER_HELP,
            CourierConstants.COURIER_INFO
          ].includes(e.target.value.toUpperCase())
          ? true
          : false
    });
  };

  changeReservedKeywordWebhook = (e, reservedKeyword) => {

    if (reservedKeyword === "stopKeywordWebhook") {
      this.setState({ stopKeywordWebhook: e.target.value })
    }
    else if (reservedKeyword === "startKeywordWebhook") {
      this.setState({ startKeywordWebhook: e.target.value })
    }
    else if (reservedKeyword === "helpKeywordWebhook") {
      this.setState({ helpKeywordWebhook: e.target.value })
    }

  }

  changeKeywordWebhook = e => {

    this.setState({ globalKeywordWebhook: e.target.value })

  }


  changeKeywordWebhookErrorResponse = e => {
    this.setState({
      globalKeywordWebhook: {
        ...this.state.globalKeywordWebhook,
        webhookErrorResponse : e.target.value
      }
    })
  }

  toggleGlobalKeywordRegex = e => {
    this.setState(
      {
        globalKeywordRegex: e.target.checked
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "globalKeywordRegex",
          this.state.globalKeywordRegex
        )
    );
  };

  toggleExpireInactive = () => {
    this.setState(
      {
        expireInactive: !this.state.expireInactive
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "expireInactive",
          this.state.expireInactive
        )
    );
  };

  toggleExpireComplete = () => {
    this.setState(
      {
        expireComplete: !this.state.expireComplete
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "expireComplete",
          this.state.expireComplete
        )
    );
  };

  toggleLinkedRepliesDelay = () => {
    this.setState(
      {
        linkedRepliesDelayFlag: !this.state.linkedRepliesDelayFlag
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "linkedRepliesDelayFlag",
          this.state.linkedRepliesDelayFlag
        )
    );
  };

  toggleCatchAllResponse = () => {
    this.setState(
      {
        catchAllResponseFlag: !this.state.catchAllResponseFlag
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "catchAllResponseFlag",
          this.state.catchAllResponseFlag
        )
    );
  };

  toggleWebhooksIncludePersonalization = () => {
    this.setState(
      {
        webhooksIncludePersonalization: !this.state.webhooksIncludePersonalization
      },
      () =>
        this.props.onChangeFlowchartOptions(
          "webhooksIncludePersonalization",
          this.state.webhooksIncludePersonalization
        )
    );
  };

  updateGlobalKeyword = (key, value) => {
    this.props.onChangeFlowchartOptions(key, value);
  };

  changeExpirationTime = e => {
    if (e.target.value > 0 && e.target.value < 99999) {
      this.setState(
        {
          expirationTime: e.target.value
        },
        () =>
          this.props.onChangeFlowchartOptions(
            "expirationTime",
            this.state.expirationTime
          )
      );
    }
  };

  changeLinkedRepliesDelayTime = e => {
    if (e.target.value >= 0 && e.target.value < 999999) {
      this.setState(
        {
          linkedRepliesDelayTime: e.target.value
        },
        () =>
          this.props.onChangeFlowchartOptions(
            "linkedRepliesDelayTime",
            this.state.linkedRepliesDelayTime
          )
      );
    }
  };

  defaultKeywordsEditFormSubmitCallback = (form) =>
  {
    form.flowchartId = this.props.flowchartMetadata?this.props.flowchartMetadata.id:undefined
    if(this.state.defaultKeywordsEditFormMethod==="POST")
      this.props.dispatch(postDefaultKeywords(form));
    else if(this.state.defaultKeywordsEditFormMethod==="PUT")
      {
        let id = this.props.keywords.filter(item => item.keywords===this.state.defaultKeywordsEditFormInitialValues.keywords)[0].id
        this.props.dispatch(modifyKeywords(form,id));
      }
    this.setState({ showDefaultKeywordsEditForm: false });
  }
  drawerItemsWidth = "83.33%";
  drawerItemsMargin = "0px";

  componentDidMount()
  {
    this.props.dispatch(getKeywordsByFlowchartId(this.props.flowchartMetadata.id))
  }

  componentDidUpdate()
  {
    if (this.props.keywordsNeedsRefresh === true)
    {
      this.props.dispatch(getKeywordsByFlowchartId(this.props.flowchartMetadata.id))
    }
  }

  render() {
    const { classes } = this.props;
    let disableGlobalKeyword =
      this.state.invalidKeyword ||
      !this.state.globalKeyword ||
      !this.state.globalKeywordReply ||
      !this.state.globalKeyword.replace(/\s/g, "").length;

    const CourierReservedKeywords = (
      <Grid xs={12} item={true}>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.stop}
            onChange={this.changeText}
            onBlur={() =>
              this.props.onChangeFlowchartOptions("stopReply", this.state.stop)
            }
            required
            label="Stop, Unsubscribe, etc."
            margin="normal"
            name="stop"
            multiline
          />
          <InputLabel id="webhook-select-label">Webhook</InputLabel>
          <Select // stop keyword webhook
            labelId="webhook-select-label"
            onChange={(e) => this.changeReservedKeywordWebhook(e, "stopKeywordWebhook")}//todo prob gonna need 3 diff
            style={{ width: this.drawerItemsWidth }}
            // defaultValue={{ key: "stop", value: { url: 'N/A' } }}
            value={this.state.stopKeywordWebhook}
          >
            {/* put webhook value as default if one exist */}
            {
              <MenuItem
                value={this.state.stopKeywordWebhook}>
                {this.state.stopKeywordWebhook.url}
              </MenuItem>

            }

            {this.state.stopKeywordWebhook.url == 'N/A' ? null : <MenuItem value={{ url: 'N/A' }}>N/A</MenuItem>}



            {this.state.WebhookOptions.filter(WebhookOption => WebhookOption.url != this.state.stopKeywordWebhook.url).map((WebhookOption, index) => (
              //webhook option ie => {deleted: false, id: 1, contentType: 'json', url: 'test.ca'}
              <MenuItem key={index} value={WebhookOption}>
                {WebhookOption.url}
              </MenuItem>))}

          </Select>
        </Grid>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.start}
            onChange={this.changeText}
            name="start"

            onBlur={() =>
              this.props.onChangeFlowchartOptions("startReply", this.state.start)
            }
            required
            label="Start, Yes, Unstop"
            margin="normal"
            multiline
          />
          <InputLabel id="webhook-select-label">Webhook</InputLabel>
          <Select // start keyword webhook
            labelId="webhook-select-label"
            onChange={(e) => this.changeReservedKeywordWebhook(e, "startKeywordWebhook")}
            style={{ width: this.drawerItemsWidth }}
            // defaultValue={{ key: "start", value: { url: 'N/A' } }}
            value={this.state.startKeywordWebhook}
          >
            {/* put webhook value as default if one exist */}
            {


              <MenuItem
                value={this.state.startKeywordWebhook}>
                {this.state.startKeywordWebhook.url}
              </MenuItem>

            }

            {this.state.startKeywordWebhook.url == 'N/A' ? null : <MenuItem value={{ url: 'N/A' }}>N/A</MenuItem>}



            {this.state.WebhookOptions.filter(WebhookOption => WebhookOption.url != this.state.startKeywordWebhook.url).map((WebhookOption, index) => (
              //webhook option ie => {deleted: false, id: 1, contentType: 'json', url: 'test.ca'}
              <MenuItem key={index} value={WebhookOption}>
                {WebhookOption.url}
              </MenuItem>))}

          </Select>
        </Grid>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.help}
            onChange={this.changeText}
            onBlur={() =>
              this.props.onChangeFlowchartOptions("helpReply", this.state.help)
            }
            required
            label="Help, Info"
            margin="normal"
            name="help"
            multiline

          />

          <InputLabel id="webhook-select-label">Webhook</InputLabel>
          <Select // help keyword webhook
            labelId="webhook-select-label"
            onChange={(e) => this.changeReservedKeywordWebhook(e, "helpKeywordWebhook")}
            style={{ width: this.drawerItemsWidth }}
            // defaultValue={{ key: "help", value: { url: 'N/A' } }}
            value={this.state.helpKeywordWebhook}
          >
            {/* put webhook value as default if one exist */}
            {
              <MenuItem
                value={this.state.helpKeywordWebhook}>
                {this.state.helpKeywordWebhook.url}
              </MenuItem>

            }

            {this.state.helpKeywordWebhook.url == 'N/A' ? null : <MenuItem value={{ url: 'N/A' }}>N/A</MenuItem>}



            {this.state.WebhookOptions.filter(WebhookOption => WebhookOption.url != this.state.helpKeywordWebhook.url).map((WebhookOption, index) => (
              //webhook option ie => {deleted: false, id: 1, contentType: 'json', url: 'test.ca'}
              <MenuItem key={index} value={WebhookOption}>
                {WebhookOption.url}
              </MenuItem>))}

          </Select>
        </Grid>

        <Button
          color="primary"
          variant="contained"
          style={{
            width: "20.33%",
            background: munvoOrange,
            margin: "10px"
          }}
          onClick={() => {
            this.props.saveReservedKeyword(
              this.state.KeywordWebhookMap,
              this.state.stopKeywordWebhook,
              this.state.startKeywordWebhook,
              this.state.helpKeywordWebhook

            );
          }}
        >
          save
        </Button>

      </Grid>
    );

    // GlobalKeywords tab 
    const GlobalKeywords = (
      <Grid xs={12} item={true}>
        <Grid xs={12} item={true}>
          <TextField
            error={this.state.invalidKeyword}
            helperText={
              this.state.invalidKeyword
                ? "This global keyword is already registered for this flowchart"
                : null
            }
            style={{ width: this.drawerItemsWidth }}
            value={this.state.globalKeyword}
            onChange={this.changeGlobalKeyword}
            name="globalKeyword"
            label="Global Keyword"
            margin="normal"
            multiline
          />
        </Grid>
        <Grid xs={12} item={true}>
          <FormControl component="fieldset">
            <FormGroup>
              <div style={{ display: "inline-flex" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.globalKeywordRegex}
                      value={this.state.globalKeywordRegex}
                      onClick={this.toggleGlobalKeywordRegex}
                      color="primary"
                      classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                        track: classes.colorTrack
                      }}
                    />
                  }
                  label="Use regex for global keyword"
                />
              </div>
            </FormGroup>
            <Collapse in={this.state.globalKeywordRegex}>
              <FormHelperText>Standard Java regex. May heavily impact performance.</FormHelperText>
            </Collapse>
          </FormControl>
        </Grid>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.globalKeywordReply}
            onChange={this.changeText}
            name="globalKeywordReply"
            label="Reply"
            margin="normal"
            multiline
          />
        </Grid>
        <Grid xs={12} item={true}>
          <InputLabel id="webhook-select-label">Webhook</InputLabel>

          <Select
            labelId="webhook-select-label"
            onChange={this.changeKeywordWebhook}
            style={{ width: this.drawerItemsWidth }}
            // defaultValue={{ url: 'N/A' }}
            value={this.state.globalKeywordWebhook}
          >

            {
              <MenuItem
                value={this.state.globalKeywordWebhook}>
                {this.state.globalKeywordWebhook.url}
              </MenuItem>

            }

            {this.state.globalKeywordWebhook.url == 'N/A' ? null : <MenuItem value={{ url: 'N/A' }}>N/A</MenuItem>}

            {this.state.WebhookOptions.filter(WebhookOption => WebhookOption.url != this.state.globalKeywordWebhook.url).map((WebhookOption, index) =>
              //webhook option ie => {deleted: false, id: 1, contentType: 'json', url: 'test.ca'}
              <MenuItem key={index} value={WebhookOption}>
                {WebhookOption.url}
              </MenuItem>
            )}
          </Select>
        </Grid>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.globalKeywordWebhook.webhookErrorResponse }
            onChange={this.changeKeywordWebhookErrorResponse}
            name="webhookErrorResponse "
            label="Webhook Custom Error Response"
            margin="normal"
            multiline
          />
        </Grid>
        <Button
          color="primary"
          variant="contained"
          style={{
            width: "20.33%",
            background: !disableGlobalKeyword ? munvoOrange : "#eeeeee",
            margin: "10px"
          }}
          disabled={disableGlobalKeyword}
          onClick={() => {
            this.props.addGlobalKeyword(
              this.state.globalKeyword.trim(),
              this.state.globalKeywordReply.trim(),
              this.state.KeywordWebhookMap,
              this.state.globalKeywordWebhook,
              this.state.globalKeywordRegex,
            );
            this.setState({
              globalKeyword: "",
              globalKeywordReply: ""
            });
          }}
        >
          Add
        </Button>
        <Grid xs={12} item={true}>
          {
            // Render the global keywords
            this.state.globalKeywords.sort(
              (a, b) =>
                a.value > b.value
                ? 1
                : -1
            ).map(
              keyword => (
                <div key={keyword.key}>
                  <Divider
                    variant="inset"
                    style={{
                      width: '72.5%',
                      borderBottomStyle: 'groove',
                    }}
                  />
                  <ListItem>
                    <div>
                      {keyword.key.split("|")[0]}:
                      <br/>
                      <br/>
                      {keyword.key.substring(keyword.key.indexOf("|") + 1)}
                    </div>
                    <GlobalKeywordField
                      InputLabelProps={{ required: false }}
                      KeywordWebhookMap={this.state.KeywordWebhookMap}
                      initValue={keyword.value}
                      required
                      updateGlobalKeyword={this.updateGlobalKeyword}
                      keyword={"Response"}
                      margin="normal"
                      multiline
                    />
                    <IconButton
                      onClick={() => this.props.removeGlobalKeyword(keyword.key)}
                    >
                      <ActionDelete />
                    </IconButton>
                  </ListItem>
                </div>
              )
            )
          }
        </Grid>
      </Grid >
    );

    const SessionExpiration = (
      <Grid xs={12} item={true}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Expire a customer's session when
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.expireComplete}
                  value={this.state.expireComplete}
                  onClick={this.toggleExpireComplete}
                  color="primary"
                  classes={{
                    switchBase: classes.colorSwitchBase,
                    checked: classes.colorChecked,
                    track: classes.colorTrack
                  }}
                />
              }
              label="Customer reaches a terminal node"
            />
            <div style={{ display: "inline-flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.expireInactive}
                    value={this.state.expireInactive}
                    onClick={this.toggleExpireInactive}
                    color="primary"
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorTrack
                    }}
                  />
                }
                label={
                  <div style={{ display: "inline-flex" }}>
                    <span style={{ alignSelf: "center" }}>
                      Customer is inactive in any node for
                    </span>
                    <div style={{ alignSelf: "center" }}>
                      &nbsp;
                      <input
                        type="number"
                        min="1"
                        max="99999"
                        value={this.state.expirationTime}
                        onChange={this.changeExpirationTime}
                        style={{ width: "50px" }}
                      />
                      &nbsp;
                      <span style={{ alignSelf: "center" }}>hour(s)</span>
                    </div>
                  </div>
                }
              />
            </div>
          </FormGroup>
        </FormControl>
        <TextField
          style={{ width: this.drawerItemsWidth }}
          value={this.state.expirationMessage}
          onChange={this.changeText}
          onBlur={() =>
            this.props.onChangeFlowchartOptions(
              "expirationMessage",
              this.state.expirationMessage
            )
          }
          required
          name="expirationMessage"
          label="Session Expiration Message"
          margin="normal"
          multiline
        />
      </Grid>
    );

    const LinkedRepliesDelay = (
      <Grid xs={12} item={true}>
        <FormControl component="fieldset">
          <FormGroup>
            <div style={{ display: "inline-flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.linkedRepliesDelayFlag}
                    value={this.state.linkedRepliesDelayFlag}
                    onClick={this.toggleLinkedRepliesDelay}
                    color="primary"
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorTrack
                    }}
                  />
                }
                label={
                  <div style={{ display: "inline-flex" }}>
                    <span style={{ alignSelf: "center" }}>
                      Add delay of
                    </span>
                    <div style={{ alignSelf: "center" }}>
                      &nbsp;
                      <input
                        type="number"
                        min="1"
                        max="999999"
                        value={this.state.linkedRepliesDelayTime}
                        onChange={this.changeLinkedRepliesDelayTime}
                        style={{ width: "65px" }}
                      />
                      &nbsp;
                      <span style={{ alignSelf: "center" }}>millisecond(s)</span>
                    </div>
                  </div>
                }
              />
            </div>
          </FormGroup>
        </FormControl>
      </Grid>
    );

    const CatchAllResponse = (
      <Grid xs={12} item={true}>
        <Grid xs={12} item={true}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.catchAllResponseFlag}
                    value={this.state.catchAllResponseFlag}
                    onClick={this.toggleCatchAllResponse}
                    color="primary"
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorTrack
                    }}
                  />
                }
                label="Enable Catch All Response"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid xs={12} item={true}>
          <TextField
            style={{ width: this.drawerItemsWidth }}
            value={this.state.catchAllResponse}
            onChange={this.changeText}
            name="catchAllResponse"
            onBlur={() =>
              this.props.onChangeFlowchartOptions(
                "catchAllResponse",
                this.state.catchAllResponse
              )
            }
            required
            label="Catch All Response Message"
            margin="normal"
            multiline
          />
        </Grid>
      </Grid>
    );

    const WebhooksIncludePersonalization = (
      <Grid xs={12} item={true}>
        <FormControl component="fieldset">
          <FormGroup>
            <div style={{ display: "inline-flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.webhooksIncludePersonalization}
                    value={this.state.webhooksIncludePersonalization}
                    onClick={this.toggleWebhooksIncludePersonalization}
                    color="primary"
                    classes={{
                      switchBase: classes.colorSwitchBase,
                      checked: classes.colorChecked,
                      track: classes.colorTrack
                    }}
                  />
                }
                label="Include personalization"
              />
            </div>
          </FormGroup>
        </FormControl>
      </Grid>
    );

    const SessionReset = (
      <Grid xs={12} item={true}>
        <TextField
          data-tip
          data-for='reset-session-tt'
          error={this.props.sessionIdle != undefined && !this.props.sessionSuccess && !this.props.sessionIdle && this.state.sessionNumber != ""}
          style={{ width: "63%", paddingRight: "30px" }}
          label="Phone Number"
          helperText={
            this.props.sessionIdle === undefined || this.state.sessionNumber === "" ? "" :
              !this.props.sessionSuccess && !this.props.sessionIdle
                ? "We could not find an active session for this phone number."
                : this.props.sessionSuccess && !this.props.sessionIdle
                  ? "Session successfully reset for this phone number."
                  : ""
          }
          onKeyPress={e => {
            if (e.key === "Enter" && e.shiftKey === false) this.resetSession();
          }}
          margin="normal"
          value={this.state.sessionNumber}
          onChange={this.changeSessionNumber}
        />
        <ResetSessionTooltip />
        <Button
          color="primary"
          variant="contained"
          style={{ width: "20.33%", background: munvoOrange }}
          onClick={this.resetSession}
        >
          Reset
        </Button>
      </Grid>
    );
    const Keywords = (
      <Grid xs={12} item>
          <Grid item>
            <Button 
            style={{ background: munvoOrange }}
            data-tip 
            id='add-button' 
            variant='contained' 
            color='primary'
            onClick={() => {this.setState({showDefaultKeywordsEditForm:!this.state.showDefaultKeywordsEditForm,defaultKeywordsEditFormMethod: "POST", defaultKeywordsEditFormInitialValues: {isRegex:"false",keywords:"",response:"",role:"global",language:"EN",webhookId:null}})}}>Add keywords
          <Dialog
                title="Add flowchart Keywords"
                actions={[
                  <Button
                    variant="outlined"
                    onClick={() => {this.setState({showDefaultKeywordsEditForm:!this.state.showDefaultKeywordsEditForm})}}
                    className={classes.button}
                  >
                    Cancel
                  </Button>,
                  <RemoteSubmitButton
                    component={Button}
                    form="DefaultKeywordsEditForm"
                    label="Submit"
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                  />
                ]}
                open={this.state.showDefaultKeywordsEditForm}
                onRequestClose={() => {this.setState({showDefaultKeywordsEditForm:!this.state.showDefaultKeywordsEditForm})}}
                >
        <DefaultKeywordsEditForm
        parentComponent="FlowchartOptions"
        webhookList={this.props.urlList.urlList}
        submitCallback={this.defaultKeywordsEditFormSubmitCallback}
        initialValues={this.state.defaultKeywordsEditFormInitialValues}
        />
        </Dialog>
            </Button>
          </Grid>
{this.props.keywords.map((item)=>(
  <div>
  <Grid item>
          <br/>
          <Divider
                    variant="inset"
                    style={{
                      width: '72.5%',
                      borderBottomStyle: 'groove',
                    }}
                  />
        </Grid>
        <Grid item>
          <br/>
          <TextField
          style={{ width: "83.33%" }}
          value={item.keywords}
          disabled
          required
          label={"keyword"}
          margin="normal"
          multiline
        />
        </Grid>
        <Grid item>
          <br/>
          <TextField
          style={{ width: "83.33%" }}
          value={item.response}
          disabled
          required
          label={"response"}
          margin="normal"
          multiline
        />
        </Grid>
        <Grid item>
          <br/>
          <TextField
          style={{ width: "83.33%" }}
          value={item.role}
          disabled
          required
          label={"role"}
          margin="normal"
        />
        </Grid>
        <Grid item>
          <br/>
          <TextField
          style={{ width: "83.33%" }}
          value={item.language}
          disabled
          required
          label={"language"}
          margin="normal"          
        />
        </Grid>
        <Grid item>
          <br/>
          <TextField
          style={{ width: "83.33%" }}
          value={this.props.urlList.urlList.filter((item2)=>(item2.id==item.webhookId))[0]?this.props.urlList.urlList.filter((item2)=>(item2.id==item.webhookId))[0].url:item.webhookId}
          disabled
          required
          label={"webhook"}
          margin="normal"     
        />
        </Grid>
        <IconButton
          onClick={
             () => 
          {
            this.props.dispatch(deleteKeywords(item.id))
          }
          }
        >
        <ActionDelete />
        </IconButton>
        <IconButton
          onClick={() => {this.setState({showDefaultKeywordsEditForm:!this.state.showDefaultKeywordsEditForm,defaultKeywordsEditFormMethod: "PUT",defaultKeywordsEditFormInitialValues: {isRegex:item.isRegex?"true":"false",keywords:item.keywords,response:item.response,role:item.role,language:item.language,webhookId:item.webhookId}})}}
        >
        <ImageEdit/>
        </IconButton>
        </div>
))}
      </Grid>
    );
    return (
      <Drawer variant="persistent" anchor="right" open={this.state.open}>
        <div style={{ width: "550px" }}>
          <AppBar
            showMenuIconButton={false}
            style={{ background: munvoBlue }}
          />
          <Grid>
            <Grid xs={12} item={true}>
              <br />
              <Button onClick={() => this.changeTab(0)}>
                Courier Reserved Keywords : deprecated
              </Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Courier Reserved Keywords",
                    `Courier, by default, handles standard reply messages such as STOP, UNSTOP, UNSUBSCRIBE or CANCEL for Toll-Free and Long Code messages, 
                    in accordance with industry standards. 
                    For Short Code messages, these standard reply messages may be customized.
                    Behavior may change based on Courier used.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 0 && CourierReservedKeywords /* if current state tab is 0 render corresponding CourierReservedKeywords tab */}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(1)}>Global Keywords : deprecated</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Global Keywords",
                    `Global keywords can be defined for customer response messages that can be received independently from the tree, with it's corresponding response message.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 1 && GlobalKeywords}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(2)}>
                Session Expiration
              </Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Session Expiration",
                    `When customers navigate through a flowchart, sessions are created to keep track of their position.
                      Session expiration can be customized for when a customer reaches a terminal node or if a customer in inactive in any node for a duration of time.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 2 && SessionExpiration}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(3)}>
                Linked Replies Delay
              </Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Linked Replies Delay",
                    `Some messaging providers cannot guarantee that messages are sent out in the order they received them because it is in the hands of the carriers.
                      To lessen the probability of this occurring, you can add a delay here in milliseconds that will be applied after each linked reply.
                      Please note that increasing this value decreases the likelihood of linked replies sent in the incorrect order, but also increases the response time for your customers.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 3 && LinkedRepliesDelay}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(4)}>
                Catch All Response
              </Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Catch All Response",
                    `When a customer response does not match any of the expected response messages, it is possible to send a default response to the customer.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 4 && CatchAllResponse}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(5)}>
                Webhooks
              </Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Webhooks",
                    `Applying this flowchart option will add an additional field to the payload of webhooks sent out from this flowchart.
                    This field, called 'personalization', contains the customer data that was provided to the SMS Gateway, in JSON format.
                    This customer data is associated to the phoneTo field that is already part of the webhook payload.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 5 && WebhooksIncludePersonalization}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(6)}>Session Reset</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Session Reset",
                    `When customers navigate through a flowchart, sessions are created to keep track of their position.
                      Resetting a session will allow a customer to go through the flowchart again starting from the first node.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 6 && SessionReset}
            <Grid xs={12} item={true}>
              <br />
              <Divider />
              <Button onClick={() => this.changeTab(7)}>Keywords</Button>
              &nbsp;
              <HelpIcon
                onClick={() => {
                  createNotification(
                    "Keywords",
                    `Enter keywords.`
                  );
                }}
              />
            </Grid>
            {this.state.tab === 7 && Keywords}
            <Grid
              xs={12}
              item={true}
              onClick={() => {
                this.props.toggleDrawer();
              }}
            >
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    );
  }
}

FlowchartOptions.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    sessionIdle: state.flowcharts.sessionIdle,
    sessionSuccess: state.flowcharts.sessionSuccess,
    urlList: state.urlList,
    keywords: state.Keywords.keywords,
    keywordsNeedsRefresh: state.Keywords.needsRefresh
  };
};

export default connect(mapStateToProps)(withStyles(styles)(FlowchartOptions));
