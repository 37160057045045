import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import { API_VERSION } from "../../utils/Constants";

import * as Cookies from "js-cookie";

const reportRequest = createAction(Types.REPORT_REQUEST);
const reportSuccess = createAction(Types.REPORT_SUCCESS, payload => payload);
const reportFailure = createAction(Types.REPORT_FAILURE);
const forceLogout = createAction(Types.LOGOUT);

export const generateReport = (
  campaignIds,
  startDate,
  endDate
) => async dispatch => {
  dispatch(reportRequest());
  const campaignIdsJson = JSON.stringify({campaignIds: campaignIds});
  console.log(
    API_VERSION +
      "campaign/report/" +
      startDate +
      "/" +
      endDate +
      "\t" +
      campaignIdsJson
  );
  const response = await fetch(
    API_VERSION +
      "campaign/report/" +
      startDate +
      "/" +
      endDate,
    {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getJwtToken()
      },
      body: campaignIdsJson
    }
  );
  console.log("response:" + response.status);
  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();
    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(reportFailure());
  } else {
    const body = await response.json();

    console.log(body);
    dispatch(reportSuccess(body));
  }
};
