import * as Types from './types'

export const nodeTemplateReducer = (state = { nodeTemplates: [] }, action) => {
  switch(action.type){
    case Types.NODE_TEMPLATE_REQUEST:
      return { ...state, loading: true }
    case Types.NODE_TEMPLATE_SUCCESS:
      return { loading: false, nodeTemplates: action.payload }
    case Types.NODE_TEMPLATE_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}