import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { CopyAuthTokenToClipboardTooltip } from '../utils/Tooltip'
import { munvoOrange } from '../utils/globalStyles'

class AuthToken extends Component{
  constructor(props){
    super(props)

    this.state = { authTokenValue: ''}
  }

  async componentDidMount(){
    this.setState({ authTokenValue: localStorage.getItem('jwt-token') ? 
                                    localStorage.getItem('jwt-token') : sessionStorage.getItem('jwt-token')})
  }

  render(){
    return(
      <div>
        <Grid container>
          <Grid item xs={12}>
            Authentication Token
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline={true}
              rows={6}
              value={localStorage.getItem('jwt-token') ? 
                     localStorage.getItem('jwt-token') : sessionStorage.getItem('jwt-token')}
              disabled={true}
              fullWidth={true}
              margin='normal'
              style={{ width: '50%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <CopyToClipboard text={this.state.authTokenValue}>
              <Button 
                data-tip 
                data-for='auth-token-clipboard-tt' 
                variant='contained' 
                color='primary'
                style={{ background: munvoOrange }}
              >
                Copy to Clipboard
              </Button>
            </CopyToClipboard>
            <CopyAuthTokenToClipboardTooltip />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AuthToken