import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { tableStyles } from './styles'
import classNames from 'classnames'
import { AutoSizer, Column, Table } from 'react-virtualized'
import TableCell from '@material-ui/core/TableCell'


class WrapperTable extends Component {
  constructor(props){
    super(props)

    this.getRowClassName = this.getRowClassName.bind(this)
    this.cellRenderer = this.cellRenderer.bind(this)
    this.headerRenderer = this.headerRenderer.bind(this)

  }

  getRowClassName = ({ index }) => {
    const { classes, onRowDoubleClick } = this.props

    return classNames(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowDoubleClick != null
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const { columns, classes, rowHeight, onRowDoubleClick } = this.props
    return (
      <TableCell
        component='div'
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowDoubleClick == null
        })}
        variant='body'
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? 'right'
            : 'left'
        }
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, index }) => {
    const { headerHeight, columns, classes } = this.props

    return (
      <TableCell
        component='div'
        className={classNames(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant='head'
        style={{ height: headerHeight }}
        align={columns[index].numeric || false ? 'right' : 'left'}
      >
        {label}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, ...tableProps } = this.props
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            className={classes.table}
            height={height}
            width={width}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(
              (
                {
                  cellContentRenderer = null,
                  className,
                  dataKey,
                  label,
                  ...other
                },
                index
              ) => {
                let renderer
                if (cellContentRenderer != null) {
                  renderer = cellRendererProps =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index
                    })
                } else {
                  renderer = this.cellRenderer
                }

                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      this.headerRenderer({
                        ...headerProps,
                        label,
                        index
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                )
              }
            )}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

WrapperTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  headerHeight: PropTypes.number.isRequired,
  onRowDoubleClick: PropTypes.func,
  rowHeight: PropTypes.number.isRequired
};

export default withStyles(tableStyles)(WrapperTable)

