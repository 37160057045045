import * as Types from './types'

export const sessionRefreshReducer = (state = {}, action) => {
  switch(action.type){
    case Types.SESSION_REFRESH_SUCCESS:
      return{
        success: true
      }
    case Types.SESSION_REFRESH_FAILURE:
      return{
        success: false
      }
    default:
      return state
  }
}