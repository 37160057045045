import * as Types from "./types";
import "cross-fetch/polyfill";
import { createAction } from "redux-actions";
import { getJwtToken } from "../../utils/generalUtils";
import * as Cookies from "js-cookie";
import { API_VERSION } from "../../utils/Constants";

const profileRequest = createAction(Types.PROFILE_REQUEST);
const profileFailure = createAction(Types.PROFILE_FAILURE, profile => profile);
const profileSuccess = createAction(Types.PROFILE_SUCCESS, profile => profile);
const profileUpdateSuccess = createAction(
  Types.PROFILE_UPDATE_SUCCESS,
  profile => profile
);
const forceLogout = createAction(Types.LOGOUT);

const checkFailure = createAction(Types.CHECK_FAILURE);
const checkSuccess = createAction(Types.CHECK);

export const resetPassword = form => async dispatch => {
  const response = await fetch(API_VERSION + "user/resetpassword", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(form)
  });
  if (response.status !== 200) {
    dispatch(profileFailure(form.username)); //add username to form if needed
  } else {
    const body = await response.json();
    dispatch(profileUpdateSuccess(body));
  }
};

export const getUserProfile = username => async dispatch => {
  dispatch(profileRequest());
  const response = await fetch(API_VERSION + "user/" + username, {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    }
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(profileFailure(username));
  } else {
    const body = await response.json();
    dispatch(profileSuccess(body.user));
  }
};

export const updateUserProfile = updatedUserProfile => async dispatch => {
  dispatch(profileRequest());
  const response = await fetch(API_VERSION + "user/profile", {
    method: "PUT",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getJwtToken()
    },
    body: JSON.stringify(updatedUserProfile)
  });

  if (response.status === 401 || response.status === 403) {
    const responseLogout = await fetch(API_VERSION + "session", {
      method: "DELETE",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" }
    });

    const body = await responseLogout.json();

    if (body.ok) {
      dispatch(forceLogout());
      Cookies.remove("JESSIONID");
      localStorage.clear();
      sessionStorage.clear();
    }
  } else if (response.status !== 200) {
    dispatch(profileFailure(updatedUserProfile));
  } else {
    const body = await response.json();

    if (body.success) {
      dispatch(profileUpdateSuccess(body.user));
    } else {
      dispatch(profileFailure(updatedUserProfile));
    }
  }
};
