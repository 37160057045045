import parse from "date-fns/parse";
import { format, getTimezoneOffset, zonedTimeToUtc } from "date-fns-tz";

const apiTimezone = "America/New_York"; // API timezone

export function validateTimezone(timezone) {
  try {
    return Boolean(getTimezoneOffset(timezone));
  } catch (e) {
    return false;
  }
}

export function toRequestTimestamp(date, targetTimezone) {
  /*        d : Material UI pickers date which assumes local timezone
   * targetTz : the timezone the user assumed they were picking for
   * serverTz : the timezone we want to ultimately convert to for API request
   * 
   * The trick here is that MUI-pickers always assumes local timezone,
   * but the user assumes they are picking `targetTz` timezone.
   * For example:
   * If MUI-pickers assumed "12:00 GMT-4",
   * then for the user it should actually be "12:00 GMTXX", where XX is
   * the user's selected timezone.
   * As such, we must NOT convert 12:00 GMT-4 to another timezone the
   * "correct" way by changing to datetime to, say, 11:00 GMT-5, but
   * rather purely change the offset/timezone to "pretend" that the
   * datetime was actually that timezone all along, because when the
   * user picked that datetime, they assumed they were picking in *that*
   * (`targetTz`) timezone!
   * 
   * Finally, we must convert it into the proper API request datetime
   * string format, but in the timezone that the backend expects to receive.
   * 
   * Since timezone != offset, we use date-fns-tz format() which will
   * hopefully put the correct offset in the string according to the
   * timezone when it is called, and then parse it back again into a
   * Date object before formatting into the string format required for
   * the API fetch request.
   */
  const timestampFormat = "yyyy-MM-dd HH:mm:ss.SSSXX"; // XX is the offset
  const       apiFormat = "yyyy-MM-dd HH:mm:00.000"; // Will set (milli)seconds to 0 and ignores offset
  date = parse(format(date, timestampFormat, { timeZone: targetTimezone }), timestampFormat, date);
  return format(date, apiFormat, { timeZone: apiTimezone }); // timeZone doesn't really do anything here without the XX
}

export function apiToLocalTimezone(timestamp, timezone) {
  if (!validateTimezone(timezone)) return timestamp;
  const timestampFormat = "yyyy-MM-dd HH:mm:ss.SSS";
  let date = parse(timestamp, timestampFormat, new Date());
  date = zonedTimeToUtc(date, apiTimezone);
  return date.toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone: timezone });
}

