import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { logout, refreshSession } from '../reducers'
import SessionTimeout from './SessionTimeout'

class PrivateRoute extends Component{
  constructor(props){
    super(props)

    this.state = { openNotification: false }

    this.idleTimer = null
    this.onAction = this.onAction.bind(this)
    this.onActive = this.onActive.bind(this)
    this.onIdle = this.onIdle.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  onAction(e) {
    const { username, rememberMe, loggedIn } = this.props;
    if (loggedIn === 1) {
      this.props.dispatch(refreshSession(username, rememberMe));
    }
  }

  onActive(e) {
    console.log("user is active", e);
  }

  onIdle(e) {
    this.setState({ openNotification: true })
  }

  handleContinue(e){
    const { username, rememberMe } = this.props
    this.props.dispatch(refreshSession(username, rememberMe))
    this.setState({ openNotification: false })
  }

  handleLogout(e){
    const { username } = this.props
    this.props.dispatch(logout(username))
    this.setState({ openNotification: false })
  }

  render(){
    const { component: Component, path, role, loggedIn, timeout, ...rest } = this.props
    const { openNotification } = this.state

    return(
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={1500} // number of milliseconds until onActive is triggered
          timeout={timeout * 60000} // number of milliseconds until idle logout
        />
        
        {role === "user" && (path === "/settings" || path === '/users') ? "Access Denied" : 
          <Route {...rest} render={props => (loggedIn === 1 ? (<Component {...props}/>) : (<Redirect to='/login' />)
        )} />}

        <SessionTimeout
          openNotification={openNotification}
          handleContinue={this.handleContinue}
          handleLogout={this.handleLogout}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    role: state.authentication.role,
    rememberMe: state.authentication.rememberMe,
    username: state.authentication.username,
    timeout: state.authentication.timeout
  };
};

export default connect(mapStateToProps)(PrivateRoute);
