import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { TextField }from 'redux-form-material-ui'
import { munvoBlue } from '../utils/globalStyles'

class ConfigurationEditForm extends Component {

  render(){
    const { handleSubmit } = this.props
    return(
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='text' 
              floatingLabelText='Configuration Name' 
              name='key' 
              readOnly={false}
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field 
              component={TextField} 
              type='text' 
              floatingLabelText='Configuration Value' 
              name='value'
              underlineStyle={{ borderColor: munvoBlue }}
              underlineFocusStyle={{ borderColor: munvoBlue }}
              floatingLabelFocusStyle={{ color: munvoBlue }}
            />
          </Grid>
        </Grid>
      </form>
    )
  }
}

ConfigurationEditForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  // initialValues: PropTypes.object.isRequired
}

const validate = (values, props) => {
  const errors = {}


  if(!values.key){
    errors.key = 'Required'
  }
  else if(values.key.length <= 0 || values.key.length > 260 ){
    errors.value = 'length should be between 1 and 260 character'
  }

  return errors

}

export default reduxForm({
  form: 'configurationEditForm',
  validate,
  fields: ['id', 'key', 'value'],
  onSubmit: (values, dispatch, props) => { props.submitCallback(values) }
})(ConfigurationEditForm)