import React, { Component } from "react";
import HeaderBar from "../Header";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { getUsers, getRoles, postUser, putUser, deleteUser } from "../reducers";
import { Dialog } from "material-ui";
import RemoteSubmitButton from "../RemoteSubmitButton";
import NewUserForm from "./NewUserForm";
import { AddUserTooltip } from "../utils/Tooltip";
import Button from "@material-ui/core/Button";
import { munvoOrange } from "../utils/globalStyles";
import CircularIndeterminate from "../CircularLoading";
import { createTableData, checkForSpecialUser } from "./utils";
import WrapperTable from "../WrapperTable";
import Paper from "@material-ui/core/Paper";
import EditUserForm from "./EditUserForm";
import "../App.css";
import classnames from "classnames";
import Sidebar from "../Sidebar";
import { userStyles, theme } from "./styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNew: false,
      openEdit: false,
      openDelete: false,
      selectedUser: { username: "" }
    };

    this.handleOpenNew = this.handleOpenNew.bind(this);
    this.handleCloseNew = this.handleCloseNew.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
    this.handleSubmitNew = this.handleSubmitNew.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getUsers());
    this.props.dispatch(getRoles());
  }

  handleOpenNew(e) {
    this.setState({ openNew: true });
  }

  handleCloseNew(e) {
    this.setState({ openNew: false });
  }

  handleOpenEdit(e) {
    e.rowData.confirmPassword = "";
    this.setState({ openEdit: true, selectedUser: e.rowData });
  }

  handleCloseEdit(e) {
    this.setState({ openEdit: false });
  }

  handleOpenDelete(e) {
    this.setState({ openDelete: true });
  }

  handleCloseDelete(e) {
    this.setState({ openDelete: false });
  }

  handleSubmitNew(form) {
    let roleJSON = JSON.parse(form.role);

    let newUserJSON = {
      username: form.username,
      password: form.password,
      role: roleJSON,
      isActive: form.isActive ? 1 : 0,
      forcePassReset: form.forcePassReset ? 1 : 0
    };

    this.props.dispatch(postUser(newUserJSON));

    this.setState({ openNew: false });
  }

  handleSubmitEdit(form) {
    let roleJSON = this.props.role.find(role => {
      return role.rolename === form.rolename;
    });

    let editUserJSON = {
      id: form.id,
      username: form.username,
      password: form.password ? form.password : "",
      deleted: form.deleted,
      isActive: form.isActive ? 1 : 0,
      role: roleJSON,
      forcePassReset: form.forcePassReset ? 1 : 0
    };

    this.props.dispatch(putUser(editUserJSON));

    this.setState({ openEdit: false });
  }

  handleSubmitDelete(e, selectedUser) {
    let queryParams = "";

    queryParams = queryParams.concat("id=").concat(selectedUser.id);

    this.props.dispatch(deleteUser(queryParams));

    this.setState({ openEdit: false, openDelete: false });
  }

  render() {
    const title = "SMS Gateway - Users";
    const { userIsLoading, data, roleIsLoading, role, classes } = this.props;

    const rows = createTableData(data);

    return (
      <div>
        <HeaderBar pageTitle={title} />
        <Sidebar component="div" />
        <CircularIndeterminate isLoading={userIsLoading || roleIsLoading} />
        <div className={classnames("app-content", { expanded: true })}>
          <Grid container>
            <Grid style={{ marginTop: "10px" }} item xs={12}>
              <Button
                data-tip
                data-for="add-user-tt"
                color="primary"
                variant="contained"
                style={{ background: munvoOrange }}
                type="submit"
                onClick={this.handleOpenNew}
              >
                Add new User
              </Button>
              <AddUserTooltip />
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ height: 600, width: "100%", marginTop: "10px" }}>
                <WrapperTable
                  rowCount={rows.length}
                  rowGetter={({ index }) => rows[index]}
                  onRowDoubleClick={e => this.handleOpenEdit(e)}
                  rowHeight={56}
                  headerHeight={56}
                  columns={[
                    {
                      width: 100,
                      flexGrow: 1.0,
                      label: "User Name",
                      dataKey: "username"
                    },
                    {
                      width: 120,
                      label: "Created",
                      dataKey: "tsCreated"
                    },
                    {
                      width: 120,
                      label: "Active",
                      dataKey: "isActive"
                    },
                    {
                      width: 120,
                      label: "Role Name",
                      dataKey: "rolename"
                    }
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Dialog
                title="Create a User"
                actions={[
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseNew}
                    className={classes.button}
                  >
                    Cancel
                  </Button>,
                  <RemoteSubmitButton
                    component={Button}
                    form="createUserForm"
                    label="Submit"
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                  />
                ]}
                open={this.state.openNew}
                onRequestClose={this.handleCloseNew}
                autoScrollBodyContent={true}
                contentStyle={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  height: "80%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <NewUserForm
                  submitCallback={this.handleSubmitNew}
                  userList={data}
                  roleList={role}
                />
              </Dialog>
            </Grid>
            <Grid item xs={12}>
              <Dialog
                title="Edit a User"
                actions={[
                  <Button
                    variant="outlined"
                    onClick={this.handleOpenDelete}
                    className={classes.button}
                    color="secondary"
                    disabled={checkForSpecialUser(this.state.selectedUser)}
                  >
                    Delete
                  </Button>,
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseEdit}
                    className={classes.button}
                  >
                    Cancel
                  </Button>,
                  <RemoteSubmitButton
                    component={Button}
                    form="editUserForm"
                    label="Submit"
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                  />
                ]}
                open={this.state.openEdit}
                onRequestClose={this.handleCloseEdit}
                autoScrollBodyContent={true}
                contentStyle={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  height: "80%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                <EditUserForm
                  submitCallback={this.handleSubmitEdit}
                  initialValues={this.state.selectedUser}
                  roleList={role}
                  userList={data}
                />
              </Dialog>
            </Grid>
            <Grid item xs={12}>
              <Dialog
                title="Delete User(s)"
                actions={[
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseDelete}
                    className={classes.button}
                  >
                    Cancel
                  </Button>,
                  <MuiThemeProvider theme={theme}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={e =>
                        this.handleSubmitDelete(e, this.state.selectedUser)
                      }
                      className={classes.button}
                    >
                      Submit
                    </Button>
                  </MuiThemeProvider>
                ]}
                open={this.state.openDelete}
                onRequestClose={this.handleCloseDelete}
                autoScrollBodyContent={true}
                contentStyle={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  height: "80%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                Confirm Delete User
              </Dialog>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userIsLoading: state.user.loading,
    roleIsLoading: state.role.loading,
    data: state.user.users || [],
    role: state.role.roles
  };
};

export default connect(mapStateToProps)(withStyles(userStyles)(Users));
