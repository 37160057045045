import { munvoBlue } from '../utils/globalStyles'

export const profilePageStyles = (theme) => ({
  block: {
    backgroundColor: munvoBlue,
    height: '64px'
  },
  profile: {
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingTop: '100px'
  },
  card: {
    margin: '0 auto',
    width: '25%'
  },
  actions: {
    display: 'block',
    textAlign: 'center'
  },
  footerTextSuccess:{
    color: 'green',
    fontWeigt: 'bold',
    textAlign: 'center'
  },
  footerTextFailure:{
    color: 'red',
    fontWeigt: 'bold',
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(),
  }
})