import React from "react";
import { connect } from "react-redux";
import { fetchSessionTransactions } from "../reducers";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { munvoBlue, munvoOrange } from "../utils/globalStyles";
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CircularIndeterminate from "../CircularLoading";
import DateFnsUtils from "@date-io/date-fns";
import { validateTimezone, toRequestTimestamp, apiToLocalTimezone } from "./TablesUtils"
import { MuiPickersUtilsProvider, DateTimePicker } from "material-ui-pickers";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: munvoBlue
    }
  },
  tableCell80: {
    overflow: "auto",
    width: "90px",
    display: "block"
  },
  tableCell120: {
    overflow: "auto",
    width: "120px",
    display: "block"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "black",
        backgroundColor: munvoOrange
      }
    }
  }
});

function createData(
  transactionID,
  phoneNumber,
  customerResponse,
  serverResponse,
  time,
  lastModified,
  messageSid,
  broadLogId,
  campaignId,
  status,
  nodeName,
  nodeID,
  batchID,
  sessionID
) {
  return {
    transactionID,
    phoneNumber,
    customerResponse,
    serverResponse,
    time,
    lastModified,
    messageSid,
    broadLogId,
    campaignId,
    status,
    nodeName,
    nodeID,
    batchID,
    sessionID
  };
}

let rows = [];
let sortedRows = [];
let columnsQueries = [];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  sortedRows = stabilizedThis.map(el => el[0]);
  return sortedRows;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: "transactionID", label: "ID", width: "100px" },
  { id: "phoneNumber", label: "Phone", width: "100px" },
  { id: "customerResponse", label: "CustomerReply", width: "100px" },
  { id: "serverResponse", label: "ServerReply", width: "100px" },
  { id: "time", label: "TimeCreated", width: "100px" },
  { id: "lastModified", label: "LastModified", width: "100px" },
  { id: "messageSid", label: "MsgSid", width: "100px" },
  { id: "broadLogId", label: "broadlogid", width: "100px" },
  { id: "campaignId", label: "campaignId", width: "100px" },
  { id: "status", label: "Status", width: "100px" },
  { id: "nodeName", label: "NodeName", width: "100px" },
  { id: "nodeID", label: "NodeID", width: "100px" },
  { id: "batchID", label: "batchID", width: "100px" },
  { id: "sessionID", label: "SessionID", width: "100px" }
];

function SessionTransactionTableHead(props) {
  const { order, orderBy, onRequestSort, callbackFilter } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const handleColumnQueryChange = rowId => event => {
    if (columnsQueries.find(x => x.column === rowId) !== undefined) {
      columnsQueries.find(x => x.column === rowId).query = event.target.value;
    } else {
      columnsQueries.push({ column: rowId, query: event.target.value });
    }
    callbackFilter();
  };
  return (
    <TableHead padding="5px">
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={"center"}
            sortDirection={orderBy === row.id ? order : false}
          />
        ))}
      </TableRow>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            // style={{ width: row.width }}
            align={"center"}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <TextField
                style={{ width: row.width, marginLeft: "20px" }}
                label={row.label}
                placeholder="Filter"
                value={
                  columnsQueries.find(x => x.column === row.id) !== undefined
                    ? columnsQueries.find(x => x.column === row.id).query
                    : ""
                }
                onChange={handleColumnQueryChange(row.id)}
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
SessionTransactionTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const SessionTransactionTableToolbar = props => {
  return (
    <Toolbar>
      <Typography variant="h5" id="tableTitle">
        Session Transaction History
      </Typography>
    </Toolbar>
  );
};

const SessionTransactionTable = props => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("sessionTransactionId");
  
  const [page, setPage] = React.useState(0);
  
  const [selectedSessionIdOrPhoneNumber, setSelectedSessionIdOrPhoneNumber] = React.useState("");
  const [selectedSessionIdOrPhoneNumberType, setSelectedSessionIdOrPhoneNumberType] = React.useState("");
  
  const [selectedCampaignId, setSelectedCampaignId] = React.useState("");
  
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [numberOfSessionTransactions, setnumberOfSessionTransactions] = React.useState(25);
  const [isValidSessionIdOrPhoneNumber, setIsValidSessionIdOrPhoneNumber] = React.useState(true);
  
  const [dateFrom, handleFromDateChange] = React.useState(setdefaultDate("from"));
  const [dateTo, handleToDateChange] = React.useState(setdefaultDate("to"));
  const [usingDates, setUsingDates] = React.useState({checkedUsingDates: true});
  const [timezone, setTimezone] = React.useState("America/Toronto");
  const [isValidTimezone, setIsValidTimezone] = React.useState(true);
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);

  //set the default date filter to today
  function setdefaultDate(type) {
    let temp = new Date();
    if (type === "from") {
      temp.setHours(0, 0, 0, 0);
    } else if (type === "to") {
      temp.setHours(23, 59, 0, 0);
      // The datepicker UI doesn't get more specific than minutes
      // (and toRequestTimestamp function will set anything after minutes to 0 anyway)
    }
    return temp;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  //if the expression is undefined return an empty string
  //else return the expression
  function notUndefined(expression) {
    if (expression !== undefined) {
      return expression;
    }
    return "";
  }

  //if x contains one of the queries {column: "", query:""} return true
  //constraint on columns are joined by and OR
  const filterFunction = x => {
    for (let i = 0; i < columnsQueries.length; i++) {
      if (
        x[columnsQueries[i].column] !== undefined &&
        columnsQueries[i].query !== "" &&
        !x[columnsQueries[i].column]
          .toString()
          .toLowerCase()
          .includes(columnsQueries[i].query.toLowerCase())
      ) {
        return false;
      }
    }
    return true;
  };

  //update rows based on the store
  function updateRows() {
    rows = [];
    let currentSessionTransactionList = props.sessionTransactionList;

    if (currentSessionTransactionList !== undefined) {
      for (let i = 0; i < currentSessionTransactionList.length; i++) {
        const currentSessionTransaction = currentSessionTransactionList[i];
        rows.push(
          createData(
            notUndefined(currentSessionTransaction.id),
            notUndefined(currentSessionTransaction.phone),
            notUndefined(currentSessionTransaction.userMsg),
            notUndefined(currentSessionTransaction.systemResponse),
            notUndefined(apiToLocalTimezone(currentSessionTransaction.createdTimestamp, timezone)),
            notUndefined(apiToLocalTimezone(currentSessionTransaction.lastModified, timezone)),
            notUndefined(currentSessionTransaction.messageSid),
            notUndefined(currentSessionTransaction.broadlogID),
            notUndefined(parseInt(currentSessionTransaction.campaignID, 10)),
            notUndefined(currentSessionTransaction.status),
            notUndefined(currentSessionTransaction.nodeName),
            notUndefined(currentSessionTransaction.nodeId),
            notUndefined(notUndefined(currentSessionTransaction.batchId).id),
            notUndefined(currentSessionTransaction.sessionId).id
          )
        );
      }
    }
  }

  function handleNumberOfSessionTransactionsChange(e) {
    setnumberOfSessionTransactions(e.target.value);
  }

  function callbackFilter() {
    setToggle(!toggle);
  }

  async function dispatchFetchRequest() {
    if (!validateTimezone(timezone)) { return; }
    setIsLoading(true);
    let timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    await props.dispatch(
      fetchSessionTransactions(
        selectedSessionIdOrPhoneNumber,
        selectedSessionIdOrPhoneNumberType,
        selectedCampaignId,
        usingDates.checkedUsingDates ? toRequestTimestamp(dateFrom, timezone) : "",
        usingDates.checkedUsingDates ? toRequestTimestamp(dateTo, timezone) : "",
        numberOfSessionTransactions
      )
    );
    setIsLoading(false);
  }

  // Ensure scrollbar appears/table is scrollable
  const refPaperTable = React.useRef(null);
  React.useEffect(() => {
    document.body.style.overflow = "auto";
    refPaperTable.current.style.maxHeight = "calc(100vh - 196px)";
    return () => {
      document.body.style.overflow = "";
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <CircularIndeterminate isLoading={isLoading} />
      <div>
        <Paper style={{ overflow: "auto" }} ref={refPaperTable}>
          <div align="left">
            <Grid container style={{ marginTop: "20px" }} spacing={24} direction="column">
              <Grid item xs={13}>
                <SessionTransactionTableToolbar />
              </Grid>
              <Grid item xs={13} style={{ padding: "10px 40px" }}>
                <TextField
                  id="selected-sessionid-phonenumber"
                  label={selectedSessionIdOrPhoneNumberType == "sessionIdType"
                          ? "Session ID"
                          : selectedSessionIdOrPhoneNumberType == "phoneNumberType"
                            ? "Phone Number"
                            : "Please select an option"}
                  variant="filled"
                  helperText="*Required"
                  value={selectedSessionIdOrPhoneNumber}
                  onChange={event => setSelectedSessionIdOrPhoneNumber(event.target.value.trim())}
                  type="tel"
                  inputProps={{ pattern: "^[0-9]+$" }}
                  error={!isValidSessionIdOrPhoneNumber || (!!selectedSessionIdOrPhoneNumberType && !/^[0-9]+$/.test(selectedSessionIdOrPhoneNumber))}
                  style={{ padding: "0 20px 0 0" }}
                />
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="selected-sessionid-phonenumber-type"
                    name="selected-sessionid-phonenumber-type"
                    value={selectedSessionIdOrPhoneNumberType}
                    onChange={event => setSelectedSessionIdOrPhoneNumberType(event.target.value)}
                  >
                    <FormControlLabel value="sessionIdType" control={<Radio color="primary" />} label="Session ID" />
                    <FormControlLabel value="phoneNumberType" control={<Radio color="primary" />} label="Phone Number" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={13} style={{ padding: "10px 40px" }}>
                <TextField
                  id="selected-campaignid"
                  label={"Campaign ID"}
                  variant="filled"
                  helperText="Optional"
                  value={selectedCampaignId}
                  onChange={event => setSelectedCampaignId(event.target.value)}
                  type="tel"
                  inputProps={{ pattern: "^[0-9]*$" }}
                  error={!/^[0-9]*$/.test(selectedCampaignId)}
                />
              </Grid>
              <Grid item xs={13} style={{ padding: "10px 40px" }}>
                <Tooltip
                  title="Large number may take longer to query"
                  placement="right-end"
                  arrow
                >
                  <TextField
                    select
                    style={{ width: "240px" }}
                    variant="filled"
                    id="row-limit"
                    label="Number of Rows"
                    value={numberOfSessionTransactions}
                    onFocus={updateRows()}
                    onChange={handleNumberOfSessionTransactionsChange}
                    type="number"
                  >
                    {[{ value: "5",
                        label: "5" },
                      { value: "10",
                        label: "10" },
                      { value: "25",
                        label: "25" },
                      { value: "50",
                        label: "50" },
                      { value: "100",
                        label: "100" },
                      { value: "500",
                        label: "500" }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={13} style={{ padding: "10px 40px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    variant="filled"
                    style={{ padding: "0 20px 0 0" }}
                    id="From"
                    label="From"
                    allowKeyboardControl={true}
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateFrom}
                    onChange={handleFromDateChange}
                    showTodayButton
                    helperText="Optional"
                    disabled={!usingDates.checkedUsingDates}
                  />
                  <DateTimePicker
                    variant="filled"
                    style={{ padding: "0 20px 0 0" }}
                    id="To"
                    label="To"
                    ampm={false}
                    format="yyyy-MM-dd   HH:mm"
                    value={dateTo}
                    onChange={handleToDateChange}
                    showTodayButton
                    helperText="Optional"
                    disabled={!usingDates.checkedUsingDates}
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  id="timezone"
                  label="Timezone (IANA)"
                  variant="filled"
                  helperText="Please use IANA format such as 'America/Toronto'"
                  value={timezone}
                  onChange={event => {
                    setIsValidTimezone(!event.target.value || validateTimezone(event.target.value));
                    setTimezone(event.target.value);
                  }}
                  error={!isValidTimezone}
                  disabled={!usingDates.checkedUsingDates}
                />
                <Switch
                  checked={usingDates.checkedUsingDates}
                  onChange={(event) => {
                    setUsingDates({
                      ...usingDates,
                      [event.target.name]: event.target.checked
                    })
                  }}
                  color="primary"
                  name="checkedUsingDates"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              <Grid item xs={13} style={{ padding: "10px 40px" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    background: munvoOrange,
                  }}
                  onClick={() => {
                    if (!selectedSessionIdOrPhoneNumberType || !/^[0-9]+$/.test(selectedSessionIdOrPhoneNumber)) {
                      setIsValidSessionIdOrPhoneNumber(false);
                    } else {
                      setIsValidSessionIdOrPhoneNumber(true);
                      dispatchFetchRequest();
                    }
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Table aria-labelledby="tableTitle" width="100%" stickyHeader>
              <SessionTransactionTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                callbackFilter={callbackFilter}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .filter(filterFunction)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow hover key={row.name}>
                        <TableCell align="center" key="sessionTransactionId">
                          <span style={theme.tableCell80}>
                            {row.sessionTransactionId}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="phoneNumber">
                          <span style={theme.tableCell80}>
                            {row.phoneNumber}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="customerResponse">
                          <span style={theme.tableCell80}>
                            {row.customerResponse}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="serverResponse">
                          <span style={theme.tableCell120}>
                            {row.serverResponse}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="timeCreated">
                          <span style={theme.tableCell80}>
                            {row.time}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="lastModified">
                          <span style={theme.tableCell80}>
                            {row.lastModified}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="messageSid">
                          <span style={theme.tableCell80}>
                            {row.messageSid}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="broadlogId">
                          <span style={theme.tableCell80}>
                            {row.broadLogId}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="campaignId">
                        <span style={theme.tableCell80}>
                            {row.campaignId}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="status">
                          <span style={theme.tableCell80}>
                            {row.status}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="nodeName">
                          <span style={theme.tableCell80}>
                            {row.nodeName}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="nodeId">
                          <span style={theme.tableCell80}>
                            {row.nodeID}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="batchId">
                          <span style={theme.tableCell80}>
                            {row.batchID}
                          </span>
                        </TableCell>
                        <TableCell align="center" key="sessionId">
                          <span style={theme.tableCell80}>
                            {row.sessionID}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </MuiThemeProvider>
  );
};
const mapStateToProps = state => {
  return {
    sessionTransactionList: state.tables.sessionTransactionList
  };
};
export default connect(mapStateToProps)(SessionTransactionTable);
