import * as Types from './types'
import { getJwtToken } from '../../utils/generalUtils'
import { createAction } from 'redux-actions'
import { API_VERSION } from "../../utils/Constants";
import { confirmAlert } from "react-confirm-alert";

// const DefaultPostKeywordsSuccess = createAction(Types.DEFAULT_POST_KEYWORDS_SUCCESS)

const keywordsGetRequest = createAction(Types.KEYWORDS_GET_REQUEST)
const keywordsGetSuccess = createAction(Types.KEYWORDS_GET_SUCCESS)
const keywordsGetFailure = createAction(Types.KEYWORDS_GET_FAILURE)

const keywordsPostRequest = createAction(Types.KEYWORDS_POST_REQUEST)
const keywordsPostSuccess = createAction(Types.KEYWORDS_POST_SUCCESS)
const keywordsPostFailure = createAction(Types.KEYWORDS_POST_FAILURE)

const keywordsDeleteRequest = createAction(Types.KEYWORDS_DELETE_REQUEST)
const keywordsDeleteSuccess = createAction(Types.KEYWORDS_DELETE_SUCCESS)
const keywordsDeleteFailure = createAction(Types.KEYWORDS_DELETE_FAILURE)

const keywordsPutRequest = createAction(Types.KEYWORDS_PUT_REQUEST)
const keywordsPutSuccess = createAction(Types.KEYWORDS_PUT_SUCCESS)
const keywordsPutFailure = createAction(Types.KEYWORDS_PUT_FAILURE)

const keywordsInvalidateRequest = createAction(Types.KEYWORDS_INVALIDATE_REQUEST)

//TODO REFACTOR TO KEYWORDS INSTEAD OF DEFAULTKEYWORDS (EXCEPT FOR THE GET DEFAULT KEYWORDS)

export const postDefaultKeywords = (pDefaultKeywords) => async dispatch => {
  dispatch(keywordsPostRequest())
  const response = await fetch(API_VERSION+'keyword', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    },
    body: JSON.stringify(pDefaultKeywords)
  })

  if(response.status !== 200){
    dispatch(keywordsPostFailure())
    let errorMsg = "an error has occur creating the keyword"
    if(response.headers.get('errormsg') !== null){
       errorMsg = response.headers.get('errormsg')
    }
    confirmAlert({
      title: "Failed to create keyword",
      message: errorMsg,
      buttons: [
        {
          label: "OK"
        }
      ]
    });
  }else{
    const body = await response.json()
    dispatch(keywordsPostSuccess(body))  }
}

export const getKeywordsByFlowchartId = (id) => async dispatch => {
  dispatch(keywordsGetRequest())
  try {
    const response = await fetch(API_VERSION+`keyword/${id}`,{
      method:'GET',
      credentials:'same-origin',
      headers:{
        'Content-Type':'application/json',
        'Authorization': 'Bearer ' + getJwtToken()
      }
    })
    const json = await response.json()
    dispatch(keywordsGetSuccess(json))
  } catch(err) {
    dispatch(keywordsGetFailure())
    console.log(err)
  }
}


export const getDefaultKeywords = () => async dispatch => {
  dispatch(keywordsGetRequest())
  try {
    const response = await fetch(API_VERSION+'keyword/default',{
      method:'GET',
      credentials:'same-origin',
      headers:{
        'Content-Type':'application/json',
        'Authorization': 'Bearer ' + getJwtToken()
      }
    })
    const json = await response.json()
    dispatch(keywordsGetSuccess(json))
  } catch(err) {
    dispatch(keywordsGetFailure())
  }
}
export const deleteKeywords = (id) => async dispatch => {
  dispatch(keywordsDeleteRequest())
  try {
    const response = await fetch(API_VERSION+`keyword/${id}`,{
      method:'DELETE',
      credentials:'same-origin',
      headers:{'Content-Type':'application/json',
      'Authorization': 'Bearer ' + getJwtToken()}
    })
    if(response.status===200)
      dispatch(keywordsDeleteSuccess())
    else
      dispatch(keywordsDeleteFailure())
  } catch(err) {
    dispatch(keywordsDeleteFailure())
  }
}

export const modifyKeywords = (keywords,id) => async dispatch => {
  dispatch(keywordsPutRequest())
  const response = await fetch(API_VERSION+`keyword/${id}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + getJwtToken()
    },
    body: JSON.stringify(keywords)
  })

  if(response.status !== 200){
    let errorMsg = "an error has occur modifying the keyword"
    if(response.headers.get('errormsg') !== null){
       errorMsg = response.headers.get('errormsg')
    }
    confirmAlert({
      title: "Failed to modify keyword",
      message: errorMsg,
      buttons: [
        {
          label: "OK"
        }
      ]
    });
    dispatch(keywordsPutFailure())
  }else{
    dispatch(keywordsPutSuccess())
  }
}

export const invalidateFlowchartKeywords = () => async dispatch => {dispatch(keywordsInvalidateRequest())}